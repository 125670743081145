/* Constant */
import { externalConstants as EXTERNAL } from "../_constants";

/**
 * Insert JS in document
 *
 * @param {string} name   - JS external resource name, example: 'facebook', 'accountkit'
 * @param {string} target - element selector (OPTIONAL)
 * @param {string} src    - external library path (OPTIONAL)
 *
 * @return Promise
 */
const js = (name, target, src) => {
    var promise = new Promise((resolve, reject) => {
        let _newJS;
        let _tag = "script";
        let _id = name + "-jssdk";
        let _loadedJS = document.getElementById(_id);
        let _target = document.getElementsByTagName(
            target ? target : "head",
        )[0];

        if (_loadedJS) {
            if (
                _loadedJS.readyState === "loaded" ||
                _loadedJS.readyState === "complete"
            ) {
                return;
            }

            _loadedJS.parentNode.removeChild(_loadedJS);
        }

        _newJS = document.createElement(_tag);
        _newJS.id = _id;

        switch (name) {
            case "facebook":
                _newJS.src = EXTERNAL.FACEBOOK.SDK;
                break;

            default:
                _newJS.src = src ? src : "";
        }

        if (!_newJS.src) {
            return reject("inject-error:missing-src:" + name);
        }

        _target.appendChild(_newJS);
        resolve(_newJS);
    });

    return promise;
};

/* Constant object to represent Service Functions */
export const injectService = {
    js: js,
};
