/**
 * 'Cached' data
 */
let cached = {};

/**
 * Initialize Service
 */
const init = (companySettings, companyApp) => {
    cached.LOGO = companySettings.LOGO;
    cached.THEME = companyApp;
    cached.style = document.createElement("style");

    /* Put custom style element in document head */
    document.head.appendChild(cached.style);

    /**
     * Write Custom Styles to basic elements
     */

    /* Logo */
    if (companySettings.ID !== 1) {
        cached.style.sheet.insertRule(
            `.auth__header__brand {
                background-image: url(${cached.LOGO});
            }`,
        );
    }

    /* Background Gradient */
    cached.style.sheet.insertRule(`
        html,
        .auth {
            color     : ${cached.THEME.INVERSE};
            background:
                linear-gradient(
                    145deg,
                    ${cached.THEME.GRADIENT.START} 0%,
                    ${cached.THEME.GRADIENT.END} 100%
                );
        }
    `);

    /* Links */
    cached.style.sheet.insertRule(`
        a.aph,
        a.aph:not(.btn),
        .aph.btn.btn--link,
        .aph.btn.btn--link:hover,
        .aph.btn.btn--link:focus,
        .aph.btn.btn--link:active {
            color: ${cached.THEME.SECONDARY};
        }
    `);

    /* Button Primary */
    cached.style.sheet.insertRule(`
        .aph.btn--primary,
        .aph.btn--primary:hover,
        .aph.btn--primary:focus,
        .aph.btn--primary:active {
            color           : ${cached.THEME.INVERSE};
            background-color: ${cached.THEME.PRIMARY};
        }
    `);

    /* Button Bordered Primary */
    cached.style.sheet.insertRule(`
        .aph.btn--bordered.aph.btn--primary,
        .aph.btn--bordered.aph.btn--primary:hover,
        .aph.btn--bordered.aph.btn--primary:focus,
        .aph.btn--bordered.aph.btn--primary:active {
            color       : ${cached.THEME.PRIMARY};
            border-color: ${cached.THEME.PRIMARY};
        }
    `);

    /* Button Link Primary */
    cached.style.sheet.insertRule(`
        .aph.btn--link.btn--primary,
        .aph.btn--link.btn--primary:hover,
        .aph.btn--link.btn--primary:focus,
        .aph.btn--link.btn--primary:active {
            color           : ${cached.THEME.INVERSE};
            background-color: transparent;
        }
    `);

    /* Load icon */
    cached.style.sheet.insertRule(`
        .load__icon,
        .confirm__pic--unloaded {
            fill: ${cached.THEME.PRIMARY};
        }
    `);
};

/**
 * Get cached data
 */
const get = () => {
    return cached;
};

/* Constant object to represent Service Functions */
export let themeService = {
    init: init,
    get: get,
};
