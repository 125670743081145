import i18n from "../../i18n/config";

export const messagesConstants = {
    VALIDATIONS: {
        NAME: i18n.t("VALIDATIONS.NAME"),
        PHONE: i18n.t("VALIDATIONS.PHONE"),
        NATIONALITY: i18n.t("VALIDATIONS.NATIONALITY"),
        BIRTHDATE: i18n.t("VALIDATIONS.BIRTHDATE"),
        CPF: i18n.t("VALIDATIONS.CPF"),
        GENDER: i18n.t("VALIDATIONS.GENDER"),
        EMAIL: i18n.t("VALIDATIONS.EMAIL"),
        CONFIRM: i18n.t("VALIDATIONS.CONFIRM"),
        PASSWORD: i18n.t("VALIDATIONS.PASSWORD"),
        PASSWORD_CONFIRM: i18n.t("VALIDATIONS.PASSWORD_CONFIRM"),
    },
    API: {
        NOT_RESPONDING: i18n.t("VALIDATIONS.API.NOT_RESPONDING"),
        SUCCESS_MESSAGES: {
            "Authentication failed. Check e-mail and password": i18n.t(
                "VALIDATIONS.API.SUCCESS_MESSAGES.Authentication failed. Check e-mail and password"
            ),
            "The flowId is invalid or has expired": i18n.t(
                "VALIDATIONS.API.SUCCESS_MESSAGES.The flowId is invalid or has expired"
            ),
            "Invalid token": i18n.t(
                "VALIDATIONS.API.SUCCESS_MESSAGES.Invalid token"
            ),
        },
        ERROR_CODES: {
            429: i18n.t("VALIDATIONS.API.ERROR_CODES.429"),
            1001: i18n.t("VALIDATIONS.API.ERROR_CODES.1001"),
            1006: i18n.t("VALIDATIONS.API.ERROR_CODES.1006"),
            1007: i18n.t("VALIDATIONS.API.ERROR_CODES.1007"),
            1109: i18n.t("VALIDATIONS.API.ERROR_CODES.1109"),
            1011: i18n.t("VALIDATIONS.API.ERROR_CODES.1011"),
            1032: i18n.t("VALIDATIONS.API.ERROR_CODES.1032"),
            1033: i18n.t("VALIDATIONS.API.ERROR_CODES.1033"),
            1060: i18n.t("VALIDATIONS.API.ERROR_CODES.1060"),
            1061: i18n.t("VALIDATIONS.API.ERROR_CODES.1061"),
            1142: i18n.t("VALIDATIONS.API.ERROR_CODES.1142"),
            1145: i18n.t("VALIDATIONS.API.ERROR_CODES.1145"),
            1146: i18n.t("VALIDATIONS.API.ERROR_CODES.1146"),
            1171: i18n.t("VALIDATIONS.API.ERROR_CODES.1171"),
            1102: i18n.t("VALIDATIONS.API.ERROR_CODES.1102"),
            1172: i18n.t("VALIDATIONS.API.ERROR_CODES.1172"),
            1192: i18n.t("VALIDATIONS.API.ERROR_CODES.1192"),
            2005: i18n.t("VALIDATIONS.API.ERROR_CODES.2005"),
            2006: i18n.t("VALIDATIONS.API.ERROR_CODES.2006"),
            2013: i18n.t("VALIDATIONS.API.ERROR_CODES.2013"),
            2014: i18n.t("VALIDATIONS.API.ERROR_CODES.2014"),
            2020: i18n.t("VALIDATIONS.API.ERROR_CODES.2020"),
            2021: i18n.t("VALIDATIONS.API.ERROR_CODES.2021"),
            2022: i18n.t("VALIDATIONS.API.ERROR_CODES.2022"),
            2023: i18n.t("VALIDATIONS.API.ERROR_CODES.2023"),
            2036: i18n.t("VALIDATIONS.API.ERROR_CODES.2036"),
            2058: i18n.t("VALIDATIONS.API.ERROR_CODES.2058"),
            3030: i18n.t("VALIDATIONS.API.ERROR_CODES.3030"),
            5001: i18n.t("VALIDATIONS.API.ERROR_CODES.5001"),
            6020: i18n.t("VALIDATIONS.API.ERROR_CODES.6020"),
            6086: i18n.t("VALIDATIONS.API.ERROR_CODES.6086"),
            6054: i18n.t("VALIDATIONS.API.ERROR_CODES.6054"),
            6061: i18n.t("VALIDATIONS.API.ERROR_CODES.6061"),
            6063: i18n.t("VALIDATIONS.API.ERROR_CODES.6063"),
            241: i18n.t("VALIDATIONS.API.ERROR_CODES.241"),
            70: i18n.t("VALIDATIONS.API.ERROR_CODES.70"),
        },
    },
    EUNAVAILABLE: i18n.t("VALIDATIONS.EUNAVAILABLE"),
    FACEBOOK: {
        INTERNAL_ERROR_CODES: {
            10: i18n.t("VALIDATIONS.FACEBOOK.INTERNAL_ERROR_CODES.10"),
        },
    },
    INCOMPLETE: {
        CONFIRM_ON_EMAIL: i18n.t("VALIDATIONS.INCOMPLETE.CONFIRM_ON_EMAIL"),
    },
};
