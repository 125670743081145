import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import { oauthActions } from "../_actions/oauth.actions";
import {  postMessageService, settingsService } from "../_services";

const OauthCredentials = () => {
    const dispatch = useDispatch();
    const settings = settingsService.get();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(oauthActions.getCredentials(settings.openidToken));
    }, [dispatch, settings.openidToken]);

    let cancel = (event) => {
        setLoading(true);
        event.preventDefault();
        postMessageService.oauthCancelSSO();
    };

    return (
        <div className="aph p-20-ver text-center">
            <div className="aph p-10-top text-grey">
                Ocorreu um erro ao realizar o login pelo portal do Sócio
                Torcedor. Selecione seus ingressos e faça login na sequência.
            </div>
            <div className="aph m-30-top m-20-top-xs">
                <div className="row top-xs center-xs">
                    <div className="col-xxs-12 col-xs-6 first-sm last-xs">
                        <div className="aph m-10-ver-xs">
                            <button
                                className="aph btn btn--block btn--sm-text btn--primary"
                                type="button"
                                id="btnCancelSSO"
                                onClick={cancel}
                                disabled={loading}
                            >
                                Selecionar ingressos
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(OauthCredentials);
