import React, { useEffect, useState } from "react";

import moment from "moment";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Col, Row } from "aphrodite-react";
import { Styled } from "@ingresse/aphrodite";

import InputCountryCode from "./InputCountryCode";

import { userConstants as USER } from "../_constants";

function FormRegisterAndIncomplete({
    data = {},
    setCountryCode,
    formData,
    submitted,
    handleFocus,
    handleBlur,
    handleInput,
    error,
    focused,
    phoneErrors = [],
    documentErrors = [],
    birthDateErrors = [],
    checkInputErrorState = () => false,
    shortForm
}) {
    const { isFromIngresseDomain } = useSelector((state) => state.auth);
    const { t } = useTranslation();

    const [nationalities, setNationalities] = useState(null);

    const LOCALE = (() => {
        const name = "locale=";
        const cookieValue = (
            document.cookie
                .split(";")
                .map((cookie) => cookie.trim())
                .find((cookie) => cookie.startsWith(name)) || ""
        )
            .substring(name.length)
            .split("=")
            .pop();

        return cookieValue || "pt-BR";
    })().replace("-", "_");

    const APIKEY = "172f24fd2a903fc0647b61d7112ee1b9814702be";

    const isHML = window.location.href.includes("hmla") ? "hmla-" : "";

    useEffect(() => {
        fetch(
            `https://${isHML}api.ingresse.com/country?apikey=${APIKEY}&locale=${LOCALE}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                const countries = data.responseData;

                setNationalities(countries);
            })
            .catch((error) => console.error(error));
    }, [LOCALE]);

    return (
        <>
            {(phoneErrors.includes(error) || !data.phone) && (
                <div className="aph m-10-ver">
                    <Row>
                        <Col xs={4} sm={5}>
                            <InputCountryCode
                                setCountryCode={setCountryCode}
                                disabled={submitted}
                                required={USER.REQUIRED.includes("ddi")}
                                ddi={formData.ddi || ""}
                            />
                        </Col>
                        <Col xs={8} sm={7}>
                            <label className="aph form__label" htmlFor="phone">
                                {t("AUTH.LABELS.PHONE")}
                                {USER.REQUIRED.includes("phone") && (
                                    <span className="text-red"> *</span>
                                )}
                            </label>
                            <InputMask
                                className={`aph form__control ${
                                    !isFromIngresseDomain && "custom_mrt_input"
                                } input-' + (checkInputErrorState('phone') ? 'red' : 'dark-grey')`}
                                id="phone"
                                name="phone"
                                type="text"
                                required
                                value={formData.phone || ""}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInput}
                                disabled={submitted}
                                mask={
                                    formData.ddi === "55"
                                        ? "(99) 99999-9999"
                                        : ""
                                }
                            />
                        </Col>
                    </Row>
                    <div
                        className={
                            "aph form__helper text-" +
                            (focused !== "phone" ? "red" : "dark-grey")
                        }
                    >
                        {checkInputErrorState("phone") &&
                            t("VALIDATIONS.PHONE")}
                        {phoneErrors.includes(error) && (
                            <div className="text-red">{error}</div>
                        )}
                    </div>
                </div>
            )}

            {!shortForm && (documentErrors.includes(error) ||
                !data.nationality ||
                !data.document ||
                !data.document.number) && (
                <div className="aph m-10-bot">
                    <label className="aph form__label" htmlFor="nationality">
                        {t("AUTH.LABELS.NATIONALITY")}
                        {USER.REQUIRED.includes("nationality") && (
                            <span className="text-red"> *</span>
                        )}
                    </label>
                    <select
                        className={`aph form__control ${
                            !isFromIngresseDomain && "custom_mrt_select"
                        } input-' + (checkInputErrorState('nationality') ? 'red' : 'dark-grey')`}
                        id="nationality"
                        name="nationality"
                        value={formData.nationality || ""}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleInput}
                        disabled={submitted}
                    >
                        <option value="" disabled>
                            {t("AUTH.TEXT_SELECT.DEFAULT")}
                        </option>
                        {nationalities?.map(({ name, alpha3Code }) => (
                            <option value={alpha3Code}>{name}</option>
                        ))}
                    </select>
                    <div
                        className={
                            "aph form__helper text-" +
                            (focused !== "nationality" ? "red" : "dark-grey")
                        }
                    >
                        {checkInputErrorState("nationality") &&
                            t("VALIDATIONS.NATIONALITY")}
                    </div>
                </div>
            )}

            {!shortForm && (documentErrors.includes(error) ||
                !data.document ||
                !data.document.number) && (
                <div className="aph m-10-ver">
                    <label className="aph form__label" htmlFor="document">
                        {!formData.nationality
                            ? t("AUTH.LABELS.DOCUMENT")
                            : formData.nationality === "BRA"
                            ? t("AUTH.LABELS.CPF")
                            : t("AUTH.LABELS.PASSPORT")}
                        {USER.REQUIRED.includes("document") && (
                            <span className="text-red"> *</span>
                        )}
                    </label>
                    <InputMask
                        className={`aph form__control ${
                            !isFromIngresseDomain && "custom_mrt_input"
                        } input-' + (checkInputErrorState('document') ? 'red' : 'dark-grey')`}
                        id="document"
                        name="document"
                        type="text"
                        value={formData.document || ""}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleInput}
                        disabled={submitted || !formData.nationality}
                        placeholder={
                            !formData.nationality
                                ? t("AUTH.PLACEHOLDERS.DOCUMENT")
                                : null
                        }
                        mask={
                            formData.nationality === "BRA"
                                ? "999.999.999-99"
                                : null
                        }
                    />
                    <div
                        className={
                            "aph form__helper text-" +
                            (focused !== "document" ? "red" : "dark-grey")
                        }
                    >
                        {checkInputErrorState("document") &&
                            t("VALIDATIONS.CPF")}
                        {documentErrors.includes(error) && (
                            <div className="text-red">{error}</div>
                        )}
                    </div>
                </div>
            )}

            {!shortForm && (birthDateErrors.includes(error) || !data.birthdate) && (
                <div className="aph m-10-ver">
                    <label className="aph form__label" htmlFor="birthdate">
                        {t("AUTH.LABELS.BIRTHDATE")}
                        {USER.REQUIRED.includes("birthdate") && (
                            <span className="text-red"> *</span>
                        )}
                    </label>
                    <Styled
                        styles={{
                            marginBottom: "-10px",

                            ".aph-form-error": {
                                fontSize: "12px",
                                padding: "5px 0",
                            },
                        }}
                    >
                        <input
                            className={`aph form__control ${
                                !isFromIngresseDomain && "custom_mrt_input"
                            } input-' + (checkInputErrorState('birthdate') ? 'red' : 'dark-grey')`}
                            type="date"
                            id="birthdate"
                            name="birthdate"
                            max={moment()
                                .subtract(1, "days")
                                .format("YYYY-MM-DD")}
                            min="1920-01-01"
                            required
                            value={formData.birthdate || ""}
                            disabled={submitted}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={handleInput}
                            placeholder="DD/MM/AAAA"
                        />
                        <div
                            className={
                                "aph form__helper text-" +
                                (focused !== "birthdate" ? "red" : "dark-grey")
                            }
                        >
                            {checkInputErrorState("birthdate") &&
                                t("VALIDATIONS.BIRTHDATE")}
                            {birthDateErrors.includes(error) && (
                                <div className="text-red">{error}</div>
                            )}
                        </div>
                    </Styled>
                </div>
            )}
        </>
    );
}

export default FormRegisterAndIncomplete;
