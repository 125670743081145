const getCookieValue = (value) =>
    (
        document.cookie
            .split(";")
            .map((e) => e.trim())
            .find((cookie) => cookie.startsWith(`${value}=`)) || ""
    )
        .split("=")
        .pop() || "";

export default getCookieValue;
