/* Packages */
import React from "react";
import { Route } from "react-router";

/* Services */
import { postMessageService } from "../_services";

/* Function */
import renderMergedProps from "./renderMergedProps.js";

/* Public Route */
const PublicRoute = ({ component, path, ...rest }) => {
    /**
     * Propagate Route Changes
     */
    postMessageService.flow(path || "");

    return (
        <Route
            {...rest}
            path={path}
            render={(routeProps) => {
                return renderMergedProps(component, routeProps, rest);
            }}
        />
    );
};

/* Export */
export default PublicRoute;
