/* Package */
import React from "react";

/* Export SVG */
export default () => (
    <svg
        width="22px"
        height="13px"
        viewBox="0 0 22 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Ghost-user"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            transform="translate(-629.000000, -414.000000)"
            opacity="1"
        >
            <g id="Centro" transform="translate(280.000000, 150.000000)">
                <g id="Entrar" transform="translate(80.000000, 50.000000)">
                    <g id="Senha" transform="translate(0.000000, 179.000000)">
                        <g
                            id="Olho"
                            transform="translate(270.000000, 36.000000)"
                        >
                            <path
                                d="M10.0003156,11.5 C7.08835908,11.5 4.56578319,10.4218592 2.4480487,8.63186515 C1.70992811,8.00797606 1.07416995,7.33999852 0.540722462,6.67113718 C0.35322865,6.4360487 0.191573592,6.21707224 0.0558068675,6.01956135 C-0.0273674672,5.89856088 -0.0844436212,5.80984041 -0.115343354,5.7587471 L-0.27644966,5.49235471 L-0.108482602,5.23023408 C-0.0761417447,5.17976463 -0.01639915,5.0916688 0.0703725256,4.97129385 C0.212231029,4.7744991 0.380318914,4.55623233 0.574291259,4.32184469 C1.12642707,3.65466807 1.7774949,2.98826413 2.52521086,2.36574376 C4.67294732,0.577618617 7.17488811,-0.5 10.0003156,-0.5 C12.82575,-0.5 15.3275805,0.577624373 17.4751228,2.36575879 C18.2227717,2.98828274 18.8737667,3.65469045 19.4258291,4.32187093 C19.6197758,4.55626005 19.7878397,4.77452828 19.9296767,4.97132449 C20.0164354,5.09170067 20.0761687,5.1797977 20.1085046,5.23026834 L20.2764193,5.49235431 L20.1153651,5.75871115 C20.0844705,5.80980571 20.0274037,5.89852746 19.9442423,6.01952923 C19.8084972,6.21704167 19.6468661,6.43601969 19.459398,6.67110976 C18.926024,7.33997522 18.2903387,8.0079568 17.5522852,8.63184971 C15.4347447,10.4218534 12.9122791,11.5 10.0003156,11.5 Z"
                                id="Path"
                                stroke="#2D2D2D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                            <g id="Combined-Shape">
                                <path
                                    fill="#2D2D2D"
                                    d="M13.0853803,2.76381848 C13.6945051,3.49933394 14.0625,4.4551753 14.0625,5.5 C14.0625,7.82284468 12.2436568,9.70588235 10,9.70588235 C7.7563432,9.70588235 5.9375,7.82284468 5.9375,5.5 C5.9375,3.17715532 7.7563432,1.29411765 10,1.29411765 C11.0092057,1.29411765 11.9324615,1.67510058 12.6429026,2.30572388 C12.3326509,2.07621953 11.9527352,1.94117647 11.5423537,1.94117647 C10.4956933,1.94117647 9.64720743,2.81960893 9.64720743,3.90321027 C9.64720743,4.98681162 10.4956933,5.86524408 11.5423537,5.86524408 C12.5890141,5.86524408 13.4375,4.98681162 13.4375,3.90321027 C13.4375,3.47834476 13.3070607,3.08502024 13.0853803,2.76381848 Z"
                                ></path>
                                <path
                                    stroke="#2D2D2D"
                                    strokeWidth="1"
                                    d="M13.5538731,5.24015405 C13.1277697,5.91644588 12.3874783,6.36524408 11.5423537,6.36524408 C10.2155959,6.36524408 9.14720743,5.25914781 9.14720743,3.90321027 C9.14720743,3.01846757 9.60207375,2.24009478 10.2867833,1.80595936 C10.1919032,1.7980953 10.0962511,1.79411765 10,1.79411765 C8.03644066,1.79411765 6.4375,3.44949151 6.4375,5.5 C6.4375,7.55050849 8.03644066,9.20588235 10,9.20588235 C11.9635593,9.20588235 13.5625,7.55050849 13.5625,5.5 C13.5625,5.4128623 13.5596045,5.3262078 13.5538731,5.24015405 Z"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
