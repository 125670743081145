/* Packages */
import React from "react";

/* Component */
class SimpleFooterItem extends React.Component {
    render() {
        return (
            <a
                className="footer__item"
                target={this.props.target ? this.props.target : ""}
                href={this.props.href}
                title={this.props.title}
                id={"linkFooter" + this.props.id}
            >
                {this.props.text.toUpperCase()}
            </a>
        );
    }
}

/* Export Component */
export default SimpleFooterItem;
