/* Packages */
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* Constants */
import { TRUSTED, routesConstants as ROUTES } from "../_constants";

/* Services */
import {
    trustedService,
    userService,
    postMessageService,
    settingsService,
    authService,
} from "../_services";

/* Actions */
import { authActions, trustedActions, userActions } from "../_actions";

/* Components */
import Countdowm from "../_components/Countdowm/Countdown";
import DialogLimitDevices from "../Auth/components/DialogLimitDevices";

/* Filters */
import { phoneFilter } from "../_filters";

import { cookiesUtils } from "../_utils";

/* Aphrodite React */
import { toast, Button } from "aphrodite-react";
import { useTranslation } from "react-i18next";
import posthog from "posthog-js";

/* Component Itself */
function Trusted(props) {
    /**
     * Context values
     */
    const { dispatch, trusted, user, userId, history, location } = props;
    const { device, verified } = trusted || {};
    const { search } = location || {};
    const { t } = useTranslation();

    /**
     * Local values
     */
    const [code, setCode] = useState("");
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const [requested, setRequested] = useState(false);
    const [faceBankUpdateFrame, setFaceBankUpdateFrame] = useState(false);
    const updateFrame = () => {
        setFaceBankUpdateFrame(false);
    };

    useEffect(() => {
        posthog.capture("auth:otp_request_page_view");
    }, []);

    /**
     * Set code value
     *
     * @param {object} evt
     */
    function handleCode(evt) {
        const { target } = evt;
        const { value } = target;

        let _code = value.replace(/\D/g, "") + "";

        if (_code.length <= 6) {
            setCode(_code);
        }
    }

    /**
     * Redirect to route done
     */
    const done = useCallback(
        (newDevice) => {
            if (typeof user !== "object") {
                return;
            }

            const verified = newDevice?.verified || false;

            dispatch(
                userActions.setData({
                    ...user,
                    verified,
                    device: { device: newDevice, verified },
                    trusted: { device: newDevice, verified },
                    ...(cookiesUtils.isSafari() && {
                        verifiedDevice: verified,
                    }),
                })
            );
            dispatch(authActions.confirm());
            trustedService.saveAsValid();

            postMessageService.trusted(verified, newDevice || device);

            if (settingsService.get().companyLogin) {
                postMessageService.authDone(props.company, props.companies);
            }

            history.push(ROUTES.PRIVATE.DONE.concat(search || ""));
        },
        [device, dispatch, history, search, user]
    );

    /**
     * Send SMS to user's phone number
     *
     * @param {String} challenge - 'sms' | 'whatsapp'
     */
    function requestOTP(challenge = "sms") {
        challenge === 'sms' ? posthog.capture("auth:otp_code_sms_send") : posthog.capture("auth:otp_code_wpp_send")
        if ((verified && device?.verified) || waiting || !checked || !user) {
            return;
        }

        setWaiting(false);
        setRequested(false);
        setRequesting(true);

        trustedService
            .requestOTP(device, { challenge }, { challenge })
            .then(() => {
                setWaiting(true);
                setRequested(true);
                setRequesting(false);
            })
            .catch((error) => {
                setRequesting(false);
                // toast.error(error.toString());
                setFaceBankUpdateFrame(true);
            });
    }

    /**
     * Validate OTP
     */
    function validateOTP(evt) {
        evt.preventDefault();
        posthog.capture("auth:otp_validation_code_verify")
        if (loading || !user || !device) {
            return;
        }

        setLoading(true);

        trustedService
            .validateOTP(device, code)
            .then((response) => {
                const { dispatch } = props;
                const { data } = response;
                const { device } = data;

                const credentials = authService.getCredentials();

                const newData = {
                    ...data,
                    token: credentials.token,
                };

                dispatch(authActions.setCredentials(newData));
                done(device);
            })
            .catch((error) => {
                toast.error(error ? error.toString() : error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    /**
     * Check if the device was previously marked as trusted
     */
    useEffect(() => {
        if (checked || !device || !userId) {
            return;
        }

        const isTrusted = trustedService.wasValidated();

        fetch(
            `https://c4m0r56ikk.execute-api.us-east-1.amazonaws.com/prod?id=${userId}`
        )
            .then((res) => res.json())
            .then(({ response: need2Factor }) => {
                console.log("need2Factor", need2Factor)
                if (!need2Factor) {
                    dispatch(trustedActions.set(true));
                } else {
                    dispatch(trustedActions.set(isTrusted));
                }
            })
            .catch(() => {
                dispatch(trustedActions.set(isTrusted));
            })
            .finally(() => {
                setChecked(true);
            });
    }, [dispatch, checked, device, userId]);



    /**
     * Get user's data
     */
    useEffect(() => {
        if (loading || user || !userId) {
            return;
        }

        setLoading(true);

        userService
            .getData()
            .catch((error) => {
                toast.error(error);
            })
            .then((userData) => {
                if (!userData) {
                    return;
                }

                dispatch(userActions.setData(Object.assign({}, userData)));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dispatch, loading, user, userId]);

    return (
        <>
            {faceBankUpdateFrame ? (
                <DialogLimitDevices updateFrame={updateFrame} />
            ) : null}

            <div className="aph p-20-hor p-0-hor-xs">
                <div className="aph text-center">
                    <div className="aph m-30-bot m-25-bot-xs">
                        <h3 className="aph m-0 m-5-bot p-5-top text-center">
                            {t("TRUSTED.TITLE")}
                        </h3>
                    </div>
                    <div className="aph text-grey m-25-bot m-30-bot-xs">
                        {!requested ? (
                            <>
                                {t("TRUSTED.CONFIRM.DESCRIPTION")}
                                <div className="aph p-15-top">
                                    <div className="aph text-md m-0">
                                        {user
                                            ? phoneFilter.maskedUserPhone(
                                                user.phone,
                                                user.ddi
                                            )
                                            : "Carregando seus dados"}
                                    </div>
                                    <div className="aph m--5-top">
                                        <a
                                            className="aph btn btn--link text-transform-none otp__btn"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https:www.ingresse.com/me"
                                            onClick={(evt) =>
                                                !user && evt.preventDefault()
                                            }
                                        >
                                            {!user ? "..." : t("AUTH.CHOICES.CHANGE_NUMBER")}
                                        </a>
                                    </div>
                                </div>
                            </>
                        ) : (
                            t("TRUSTED.DESCRIPTION")
                        )}
                    </div>
                </div>
                {!requested ? (
                    <div className="aph m-10-bot text-center">
                        <Button
                            className="aph btn btn--secondary m-0 text-transform-none otp__btn"
                            type="button"
                            id="btnConfirmTrusted"
                            disabled={requesting || !user}
                            onClick={() => requestOTP("sms")}
                        >
                            {t("TRUSTED.CONFIRM.ACTIONS.SMS")}
                        </Button>
                        <div className="aph m-15-ver separator otp__separator">
                            {t("TRUSTED.CONFIRM.ACTIONS.OR")}
                            {/* ou */}
                        </div>
                        <Button
                            className="aph btn btn--wpp m-0 text-transform-none otp__btn"
                            type="button"
                            id="btnConfirmTrusted"
                            disabled={requesting || !user}
                            onClick={() => requestOTP("whatsapp")}
                        >
                            {t("TRUSTED.CONFIRM.ACTIONS.WPP")}
                        </Button>
                    </div>
                ) : (
                    <>
                        <div className="aph m-15-bot text-center">
                            <div
                                className="aph text-grey text-md m-0"
                                styles={{ minHeight: "30px" }}
                            >
                                {user &&
                                    phoneFilter.maskedUserPhone(
                                        user.phone,
                                        user.ddi
                                    )}
                            </div>
                        </div>
                        <form
                            className="aph form segment__content__limited m-20-bot"
                            onSubmit={validateOTP}
                        >
                            <div>
                                <div className="aph m-15-bot">
                                    <label
                                        className="aph form__label w-full text-center"
                                        htmlFor="code"
                                    >
                                        {t("TRUSTED.LABEL")}
                                    </label>
                                    <input
                                        className="aph form__control m-0 text-center otp__input"
                                        type="text"
                                        id="code"
                                        name="code"
                                        required
                                        autoFocus
                                        autoComplete="one-time-code"
                                        inputMode="decimal"
                                        pattern="\d{6}"
                                        onChange={handleCode}
                                        value={code}
                                    />
                                </div>
                                <div
                                    className="aph text-center text-grey m-10-ver"
                                    style={{ minHeight: "60px" }}
                                >
                                    {waiting ? (
                                        <div className="otp__container aph p-5-top">
                                            <div className="row center-xs middle-xs">
                                                <div className="col-xs-2">
                                                    <Countdowm
                                                        size={40}
                                                        seconds={
                                                            TRUSTED.DELAY_MS /
                                                            1000
                                                        }
                                                        onFinishCallback={() =>
                                                            setWaiting(false)
                                                        }
                                                    />
                                                </div>
                                                <div className="col-xs-10">
                                                    segundos para poder
                                                    solicitar o reenvio do
                                                    código
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div>{t("TRUSTED.SEND")}</div>
                                            <div>
                                                <Button
                                                    className="aph btn btn--link text-transform-none otp__btn"
                                                    type="button"
                                                    id="btnRequestOTP"
                                                    disabled={requesting}
                                                    onClick={() =>
                                                        setRequested(false)
                                                    }
                                                >
                                                    {t("TRUSTED.ACTIONS.AGAIN")}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="text-center">
                                <Button
                                    className="aph btn btn--primary text-transform-none otp__btn"
                                    type="submit"
                                    id="btnVerificationSubmit"
                                    disabled={code.length !== 6 || loading}
                                >
                                    {t("TRUSTED.ACTIONS.VERIFY")}
                                </Button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </>
    );
}

/**
 * Map context information as Component props
 *
 * @param {object} state - context current state
 */
function mapStateToProps(state) {
    const { data } = state.auth;
    const { userId } = data || {};

    return {
        user: state.user && state.user.data ? state.user.data : null,
        userId: userId || "",
        trusted: state.trusted,
    };
}

/* Export Component */
export default connect(mapStateToProps)(withRouter(Trusted));
