/* Constant */
import { oauthConstants as OAUTH } from "../_constants";

/* Initial State */
const initialState = {
    isActive: false,
    flowId: "",
    loggedIn: false,
    error: null,
    scopes: [],
    clientName: "",
    redirectUrl: "",
    credentials: null,
};

/* Export Reducer */
const oauth = (state = initialState, action) => {
    switch (action.type) {
        case OAUTH.SET_OAUTH_SETTINGS:
            return {
                ...state,
                ...action.payload,
            };
        case OAUTH.ACTIVATE_OAUTH:
            return {
                ...state,
                isActive: true,
            };
        case OAUTH.LOGIN_SUCCESS:
            return {
                ...state,
                error: initialState.error,
                ...action.payload,
                loggedIn: true,
            };
        case OAUTH.LOGIN_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case OAUTH.CONSENT_SUCCESS:
            return {
                ...state,
                error: initialState.error,
                ...action.payload,
            };
        case OAUTH.CONSENT_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case OAUTH.CREDENTIALS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case OAUTH.CREDENTIALS_SUCCESS:
            return {
                ...state,
                error: initialState.error,
                ...action.payload,
            };
        case OAUTH.CLEAR_CREDENTIALS:
            return {
                ...state,
                credentials: initialState.credentials,
            }
        case OAUTH.SET_USER_DATA:
            return {
                ...state,
                userData: action.payload
            }
        default:
            return state;
    }
};

export default oauth;
