/* Import URLs */
import { urlsConstants as URLS } from "./urls.constants";

/* Footer Contants */
export const footerConstants = {
    SIMPLE: {
        LINKS: [
            {
                ID: "Home",
                TEXT: "Ingresse",
                TITLE: "Ir para a Home",
                TARGET: "",
                HREF: "/",
            },
            {
                ID: "Help",
                TEXT: "Ajuda",
                TITLE: "Tire sua dúvida",
                TARGET: "_blank",
                HREF: URLS.FRESHDESK,
            },
            {
                ID: "Terms",
                TEXT: "Termos",
                TITLE: "Leia os nossos Termos de Serviço",
                TARGET: "",
                HREF: URLS.TERMS.SERVICE,
            },
            {
                ID: "Privacy",
                TEXT: "Privacidade",
                TITLE: "Leia os nossos Termos de Privacidade",
                TARGET: "",
                HREF: URLS.TERMS.PRIVACY,
            },
        ],
    },
};
