import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { LANGUAGES } from "../../../../i18n/languages";
import { useLanguage } from "../../../_hooks/useLanguage";
import { postMessageService } from "../../../_services";

import arrow from "./assets/arrow.svg";
import "./styles.css";

const isBackstage = window.location.href.includes("backstage");

const AuthLocaleSelect = () => {
    const currentLanguage = useLanguage();
    const { i18n } = useTranslation();

    const [language, setLanguage] = useState();
    const [isOpen, setIsOpen] = useState(false);

    const chooseLanguage = (currentLanguage) => {
        setLanguage(currentLanguage);
        i18n.changeLanguage(currentLanguage.code);
        setIsOpen(false);
        document.cookie = `locale=${currentLanguage.code};domain=ingresse.com;path=/`;
        postMessageService.setLocale(currentLanguage);
    };

    const state = useSelector((state) => state);

    const { auth: { isFromIngresseDomain } } = state;

    useEffect(() => {
        const languageByLocalStorage = LANGUAGES.filter(
            (l) => l.code === currentLanguage
        )[0];
        setLanguage(languageByLocalStorage);
        postMessageService.setLocale(currentLanguage);
    }, [currentLanguage]);

    return (
        <div className="choose-language-container">
            <div className="select-container">
                {isFromIngresseDomain && (
                    <div onClick={() => setIsOpen(!isOpen)}>
                        {language ? (
                            <div className="selected-option">
                                <img
                                    src={language.flag}
                                    alt={language.name}
                                    className="option-image"
                                />
                                <span className="option-label">
                                    {language.name}
                                </span>
                                <img
                                    src={arrow}
                                    alt="escolher idioma"
                                    className={`arrow-select${isOpen && "-open"}`}
                                />
                            </div>
                        ) : null}
                    </div>
                )}

                {isOpen && (
                    <div className="options">
                        {LANGUAGES.map((lang) => {
                            if (isBackstage && lang.code === "en-US") {
                                return null;
                            }
                            return (
                                <div
                                    className="option"
                                    key={lang.code}
                                    onClick={() => chooseLanguage(lang)}
                                >
                                    <img
                                        src={lang.flag}
                                        alt={lang.name}
                                        className="option-image"
                                    />
                                    <span className="option-label">
                                        {lang.name}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AuthLocaleSelect;
