import React from "react";
import { ToastsContainer } from "aphrodite-react";

import IconLoad from "../_components/svg/Load.jsx";
import FlowLink from "../_components/FlowLink.jsx";
import SimpleFooter from "../_components/SimpleFooter.jsx";

import { settingsService, classListService } from "../_services";
import { connect } from "react-redux";

import './styles.css'

class Auth extends React.Component {
    componentWillMount() {
        this.SETTINGS = settingsService.get();
        this.BRAND = this.SETTINGS.APP.BRAND;

        this.simpleLayout = this.SETTINGS.APP.SIMPLE;
    }

    componentDidMount() {
        classListService.remove(500);
    }

    render() {
        const { isFromIngresseDomain } = this.props.auth;

        return (
            <div
                className={`aph flex flex--column flex--stretch auth ${
                    !isFromIngresseDomain && "custom_mrt_background"
                }`}
            >
                {!this.simpleLayout && (
                    <div className="aph flex__item flex__item--center auth__header">
                        <div className="aph container">
                            <FlowLink
                                className={`auth__header__brand ${
                                    !isFromIngresseDomain &&
                                    "custom_mrt_hide_element"
                                }`}
                                to={this.SETTINGS.INDEX}
                            >
                                {this.BRAND}
                                {process.env.REACT_APP_ENV && (
                                    <span
                                        style={{
                                            opacity: 0.5,
                                            fontSize: "50%",
                                            padding: "0 0 8px 8px",
                                        }}
                                    >
                                        {process.env.REACT_APP_ENV}
                                    </span>
                                )}
                            </FlowLink>
                        </div>
                    </div>
                )}
                <div
                    className={`aph flex__item flex__item--${
                        this.simpleLayout ? "start" : "center"
                    } auth__content`}
                >
                    <div
                        className={`aph container xs ${
                            !isFromIngresseDomain && "custom_mrt_container_form"
                        }`}
                    >
                        <div
                            id="authContainer"
                            className={`segment segment--padded-md load load--active unset-overflow ${
                                this.simpleLayout ? " segment--no-bs" : ""
                            }`}
                        >
                            <div id="authContent" className="segment__content">
                                {this.props.content}
                            </div>
                            <IconLoad className="load__icon" />
                        </div>
                    </div>
                    <ToastsContainer
                        autoClose={2000}
                        hideProgressBar={true}
                        styles={{
                            top: "20px",
                            bottom: "auto",
                        }}
                    />
                </div>
                {!this.simpleLayout && (
                    <div className="aph flex__item flex__item--center auth__footer">
                        <SimpleFooter />
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

export default connect(mapStateToProps)(Auth);
