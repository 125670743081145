/* Packages */
import React from "react";

/* Auth Company Component */
const AuthCompany = (props) => {
    const { callback, className, disabled, item } = props;
    const { company } = item;

    return (
        <div className={`company ${className || ""}`}>
            <button
                className="company__btn"
                type="button"
                id={`company_btn_${company.id}`}
                disabled={disabled}
                onClick={() => callback(item)}
            >
                <img
                    className={
                        `company__pic` +
                        (!company || !company.logo
                            ? " company__pic--unloaded"
                            : "")
                    }
                    src={
                        company.logo
                            ? company.logo.medium
                            : require("../../assets/images/application-logo--inverse.svg")
                    }
                    alt=""
                />
                <div className="company__title">{company.name}</div>
            </button>
        </div>
    );
};

/* Export Component */
export default AuthCompany;
