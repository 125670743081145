import { useEffect, useState } from "react";

// caso isso seja usado em um iframe utilizar no IFRAME o atributo allow="geolocation"

const useUserLocation = () => {
    const [location, setLocation] = useState({
        country: null,
        error: null,
    });

    const fetchLocation = () => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;

                try {
                    const response = await fetch(
                        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                    );
                    const data = await response.json();

                    setLocation(data && data.address ? { country: data.address.country, error: null } : { country: null, error: "Não foi possível determinar o país" });
                } catch (error) {
                    setLocation({ country: null, error: "Erro ao buscar localização" });
                }
            },
            (error) => {
                setLocation({ country: null, error: error.message });
            }
        );
    };

    useEffect(() => {
        fetchLocation();

        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: "geolocation" }).then((result) => {

                result.state === "granted" ? fetchLocation() : result.state === "denied" && setLocation({ country: null, error: "Geolocalização negada pelo usuário" });

                result.onchange = () => {
                    if (result.state === "granted") {
                        fetchLocation();
                    }
                };
            });

            return;
        }
            
        console.warn("navigator.permissions.query não é suportado neste navegador.");
    }, []);

    return location;
};

export default useUserLocation;
