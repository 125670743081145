/* Packages */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import posthog from "posthog-js";

/* Helpers & Components */
import AuthPasswordValidate from "../Auth/components/AuthPasswordValidate";

/* Constants */
import {
    textsConstants as TEXTS,
    routesConstants as ROUTES,
    PASSWORD,
} from "../_constants";

/* Utilities */
import { validators } from "../_utils";

/* Actions */
import { authActions, passwordActions } from "../_actions";

/* Services */
import { passwordService as service, classListService, authService } from "../_services";
import { customerConstants } from "../_constants/customer.constants";
import { withTranslation } from "react-i18next";

/* Update Password Component */
class PasswordUpdate extends Component {
    /**
     * Constructor
     *
     * @param {object} props - React Component default props
     */
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            password: "",
            passwordConfirm: "",

            error: "",
            focused: "",

            canSubmit: false,
            display: false,
            submitted: false,

            passwordHasRequested: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateHash = this.validateHash.bind(this);
    }

    /**
     * Will Mount
     */
    componentWillMount() {
        posthog.capture("auth:reset_password_page_view")
        authService.clearCredentials();
        this.validateHash();
    }

    /**
     * Validate recover password Hash
     */
    validateHash() {
        const { dispatch, email, hash, history } = this.props;

        let companyHash = "";

        try {
            companyHash = window.location.href
                .split("&companyHash=")[1]
                .split("&")[0];
        } catch (error) {
            console.error(error);
        }

        classListService.add();

        service
            .validateHash(email, hash, companyHash)
            .then((response) => {

                const isStringValue = typeof response === "string";
                if (isStringValue) {
                    dispatch(
                        authActions.isIngresseDomain(
                            isStringValue === Object.keys(customerConstants)[0]
                        )
                    );
                }
            })
            .catch((error) => {
                dispatch(passwordActions.setError(error));
                history.push(ROUTES.PASSWORD.RECOVER);
            })
            .finally(() => {
                classListService.remove(500);
            });
    }

    /**
     * Validate
     */
    validateValue(key, value) {
        if (!key || !value) {
            return;
        }

        const invalidKey = key[0].toUpperCase() + key.slice(1);
        const { password, passwordConfirm } = this.state;
        const passwordsMatch =
            password && passwordConfirm && password === passwordConfirm;

        if (validators[key]) {
            const isInvalid = !validators[key](value);

            this.setState({
                canSubmit: !isInvalid,
                ["invalid" + invalidKey]: isInvalid,
            });
        }

        this.setState({
            canSubmit: passwordsMatch,
            invalidPasswordConfirm: !passwordsMatch,
        });
    }

    /**
     * Handle with form input change
     *
     * @param {object} evt
     */
    handleChange(evt) {
        const { target } = evt;
        const { name, value } = target;

        this.setState(
            {
                [name]: value || "",
            },
            () => {
                this.validateValue(name, value);
            }
        );
    }

    /**
     * Remove Focus attribute
     */
    handleBlur() {
        this.setState({
            focused: "",
        });
    }

    /**
     * Handle Input Focus
     * and set current input focused into state
     *
     * @param {object} evt - DOM event
     */
    handleFocus(evt) {
        if (!evt) {
            this.setState({
                focused: "",
            });

            return;
        }

        const { target } = evt;
        const { name } = target;

        this.setState({
            focused: name,
        });
    }

    /**
     * Manipulate password validation
     *
     * @param {Boolean} isInvalid
     * @param {String} password
     */
    setPassword = (isInvalid, password) => {
        this.setState(
            {
                ...this.state,
                password: password || "",
                invalidPassword: isInvalid,
            },
            () => {
                this.validateValue("password", password || "");
            }
        );
    };

    /**
     * Set password validation request to true
     *
     * @param {bool} passwordHasRequested
     */
    setPasswordHasRequested = (passwordHasRequested) => {
        this.setState({
            passwordHasRequested,
        });
    };

    /**
     * Handle with form submit
     *
     * @param {object} evt
     */
    handleSubmit(evt) {
        evt.preventDefault();
        posthog.capture("auth:reset_password_continue_click")
        if (!this.state.canSubmit) {
            return;
        }
        classListService.add();

        this.setState(
            {
                error: "",
                loading: true,
                submitted: true,
            },
            () => {
                const { email, hash } = this.props;
                const { password } = this.state;

                service
                    .update(email, hash, password)
                    .then(() => {
                        const { history, dispatch } = this.props;

                        dispatch(passwordActions.setEmail(email));
                        dispatch(passwordActions.setHash(PASSWORD.UPDATED));
                        history.push(ROUTES.PASSWORD.UPDATED);

                        classListService.remove(250);
                    })
                    .catch((error) => {
                        let errorMessage = error;

                        this.setState(
                            {
                                error: errorMessage,
                                loading: false,
                                submitted: false,
                            },
                            () => {
                                classListService.remove(250);
                            }
                        );
                    });
            }
        );
    }

    /* Render */
    render() {
        const {
            error,

            password,
            passwordConfirm,
            invalidPassword,
            invalidPasswordConfirm,
            passwordHasRequested,

            focused,
            submitted,
            canSubmit,
        } = this.state;


        return (
            <div className="segment__content__limited">
                <div className="aph text-center">
                    <div className="aph p-5-top m-5-bot">
                        <h3 className="aph text-dark-grey m-0">
                            {TEXTS.PASSWORD.UPDATE.TITLE}
                        </h3>
                    </div>
                    <div className="aph p-5-top m-5-bot">
                        {TEXTS.PASSWORD.UPDATE.DESCRIPTIONS.map(
                            (DESCRIPTION, INDEX) => (
                                <div
                                    key={INDEX}
                                    className="aph text-grey p-10-ver"
                                >
                                    {DESCRIPTION}
                                </div>
                            )
                        )}
                    </div>
                </div>
                <form
                    noValidate={true}
                    onSubmit={this.handleSubmit}
                    className="aph form"
                >
                    <div className="aph p-10-top text-left">
                        <AuthPasswordValidate
                            autoFocus
                            focused={focused}
                            password={password}
                            handleFocus={this.handleFocus}
                            submitted={this.state.submitted}
                            handleCallback={this.setPassword}
                            invalidPassword={invalidPassword}
                            setPasswordHasRequested={
                                this.setPasswordHasRequested
                            }
                            label={TEXTS.PASSWORD.UPDATE.FIELDS.PASSWORD.LABEL}
                            placeholder={
                                TEXTS.PASSWORD.UPDATE.FIELDS.PASSWORD
                                    .PLACEHOLDER
                            }
                        />
                    </div>
                    <div className="aph p-10-top text-left">
                        <label
                            className="aph form__label"
                            htmlFor="passwordConfirm"
                        >
                            {
                                TEXTS.PASSWORD.UPDATE.FIELDS.PASSWORD_CONFIRM
                                    .LABEL
                            }
                        </label>
                        <input
                            className="aph form__control"
                            type="password"
                            id="passwordConfirm"
                            name="passwordConfirm"
                            required={true}
                            disabled={
                                submitted || !password || invalidPassword
                                    ? true
                                    : false
                            }
                            value={passwordConfirm}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            placeholder={
                                TEXTS.PASSWORD.UPDATE.FIELDS.PASSWORD_CONFIRM
                                    .PLACEHOLDER
                            }
                        />
                        <div
                            className={
                                "aph form__helper text-" +
                                (focused !== "passwordConfirm"
                                    ? "red"
                                    : "dark-grey")
                            }
                        >
                            {passwordConfirm && invalidPasswordConfirm
                                ? this.props.t("VALIDATIONS.PASSWORD_CONFIRM")
                                : ""}
                        </div>
                        {error ? (
                            <div className="aph form__helper text-red">
                                {error}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="aph p-20-ver">
                        <button
                            className="aph btn btn--block btn--primary"
                            type="submit"
                            id="btnPasswordUpdateSubmit"
                            disabled={
                                !password ||
                                !passwordConfirm ||
                                invalidPasswordConfirm ||
                                submitted ||
                                !canSubmit ||
                                passwordHasRequested
                                    ? true
                                    : false
                            }
                        >
                            {TEXTS.PASSWORD.UPDATE.ACTIONS.CONTINUE}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

/* Map State to Props */
function mapStateToProps(state) {
    return {
        ...state.password,
        isFromIngresseDomain: state.auth.isFromIngresseDomain,
    };
}

/* Export Component */
export default withTranslation()(
    connect(mapStateToProps)(withRouter(PasswordUpdate))
);
