/* Packages */
import React from "react";
import { Link } from "react-router-dom";

/* Service */
import { flowService, classListService } from "../_services";
import { connect } from "react-redux";

/* Component */
class FlowLink extends React.Component {
    /**
     * Constructor
     *
     * @param {object} props - component pros
     */
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            ...this.props,
        };
        this.state.to = encodeURI(this.props.to + flowService.getSearch());

        this.fakeLoad = this.fakeLoad.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Fake Load
     */
    fakeLoad() {
        classListService.add();
        classListService.remove(800);
    }

    /**
     * Will Receive Props
     *
     * @param {object} nextProps - component pros
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            to: encodeURI(nextProps.to + flowService.getSearch()),
        });
    }

    handleClick(e) {
        const {onClick} = this.props;
        this.fakeLoad();
        if (onClick) {
            this.props.onClick(e);
        }
    }

    /* Render */
    render() {
        return (
            <Link onClick={this.handleClick} {...this.state}>
                {this.props.children}
            </Link>
        );
    }
}

/* Default Props */
FlowLink.defaultProps = {
    to: "/", // String
    className: "aph", // String
};

function mapStateToProps(state) {
    return {
        isFromIngresseDomain: state.auth.isFromIngresseDomain,
    };
}

/* Export Component */
export default connect(mapStateToProps)(FlowLink);
