/* Packages */
import React from "react";

/* Components */
import AuthCompaniesList from "./components/AuthCompaniesList";

/* Auth Login Component */
const AuthCompanies = (
    <div>
        <AuthCompaniesList />
    </div>
);

/* Export Component */
export default AuthCompanies;
