/* Packages */
import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

/* Constants */
import {
    routesConstants as ROUTES,
    userConstants as USER,
} from "../_constants";

/* Services */
import { ingresseService, flowService, settingsService } from "../_services";

/* Base Component */
import PublicRoute from "./PublicRoute.jsx";
import isFromIngresseDomain from "../_utils/isFromIngresseDomain";
import { removeAllCookies } from "../_utils/removeAllCookies.js";

/* Component */
class AuthRoute extends React.Component {
    /**
     * Constructor
     *
     * @param {object} props - component pros
     */
    constructor(props) {
        super(props);

        this.props = props;
        this.settings = settingsService.get();
        removeAllCookies();
    }

    /* Render */
    render() {
        const { auth, path, redirect, user, oauth } = this.props;
        const timestamp = flowService.getParam("timestamp");
        const signature = flowService.getParam("signature");
        const publicKey = flowService.getParam("publickey");
        const simpleRegistration = flowService.getParam("simpleRegistration");
        let destiny = flowService.getParam("returnUrl") || redirect.url;
        const credentials = auth.data || null;

        const companyId = { id: auth.data?.companyId || auth.company?.id };
        const deviceVerified = auth?.data?.device?.verified ?? false;
        const needConfirmMfa = auth?.data?.device?.mfa ?? false;
        const needRegisterMfa = (auth?.data?.device?.mfaRequired ?? false) && !needConfirmMfa;
        const dontShowTrusted = needConfirmMfa || needRegisterMfa;
        const deviceAlreadyValidateInCache = settingsService.get().APP.DEVICE_VALIDATION ?? false;
        const companyIsIngresse = companyId.id === 1;
        const needDeviceValidation = (
            !deviceVerified &&
            deviceAlreadyValidateInCache &&
            companyIsIngresse &&
            isFromIngresseDomain()
        );
        const goToChoiceLoginMethod = !credentials && path === ROUTES.AUTH.INCOMPLETE;

        const { companies, loggedNow } = auth || {};

        const isOauthActive = oauth?.isActive ?? false;
        const isOauthLogged = oauth?.loggedIn ?? false;

        // Company Logged
        if (companies) {
            return (
                <Redirect
                    props={{ companies }}
                    to={ROUTES.PRIVATE.COMPANIES + flowService.getSearch()}
                />
            );
        }

        if (goToChoiceLoginMethod) {
            return <Redirect to={ROUTES.INDEX + flowService.getSearch()} />;
        }

        // White Label custom auth settings
        if (timestamp && signature && publicKey) {
            ingresseService.api().auth.setAuth(timestamp, signature, publicKey);

            // Add 'userToken' as redirect param
            destiny +=
                (destiny.includes("?") ? "&" : "?") +
                ("usertoken=" + credentials.token);
        }

        if (
            isOauthActive ||
            path === ROUTES.OAUTH.LOGIN ||
            path === ROUTES.OAUTH.REGISTER
        ) {
            if (isOauthLogged) {
                return <Redirect to={ROUTES.OAUTH.AUTHORIZE} />;
            }

            return <PublicRoute {...this.props} />;
        }

        if (path === ROUTES.OAUTH.CREDENTIALS && this.settings.openidToken && !oauth.credentials) {
            return <PublicRoute {...this.props} />;
        }

        if (credentials?.token && credentials?.userId && path !== ROUTES.PASSWORD.UPDATE) {
            const { data } = user || {};

            // Needs update
            if (data) {
                const formValidation = simpleRegistration ? USER.SIMPLE_FORM_REQUIRED : USER.REQUIRED;
                const needsUpdate = formValidation.some((REQUIRED_PROP) => {
                    return !data[REQUIRED_PROP];
                });

                if (needsUpdate) {
                    if (path === ROUTES.AUTH.INCOMPLETE) {
                        return <PublicRoute {...this.props} />;
                    }

                    return (
                        <Redirect
                            to={
                                ROUTES.AUTH.INCOMPLETE + flowService.getSearch()
                            }
                        />
                    );
                }
            }

            if (loggedNow && destiny) {
                destiny +=
                    (destiny.includes("?") ? "&" : "?") +
                    ("usertoken=" + credentials.token);

                flowService.goTo(destiny);
            }

            // TODO - Após ajuste de device verificado trazer authToken, jogar essas duas condicionais abaixo para dentro da condicional `needDeviceValidation`

            if (needRegisterMfa) {
                return <Redirect to={ROUTES.AUTH.REGISTER_TWOFA + flowService.getSearch()} />
            }

            if (needConfirmMfa) {
                return <Redirect to={ROUTES.AUTH.TWOFA + flowService.getSearch()} />
            }

            if (needDeviceValidation) {
                if (!dontShowTrusted) {
                    return <Redirect to={ROUTES.PRIVATE.TRUSTED + flowService.getSearch()} />
                }

                return <Redirect to={ROUTES.PRIVATE.DONE + flowService.getSearch()} />
            }

            return <Redirect to={ROUTES.PRIVATE.USER_CONFIRM + flowService.getSearch()} />
        }

        // Company Login doesn't have register
        const ignoredPathsToCompany =(
            path === ROUTES.AUTH.REGISTER ||
            path === ROUTES.INDEX
        );

        const goToLogin = this.settings.companyLogin && ignoredPathsToCompany;

        if (goToLogin) {
            return <Redirect to={ROUTES.AUTH.LOGIN + flowService.getSearch()} />
        }

        return <PublicRoute {...this.props} />;
    }
}

/* Map State to Props */
function mapStateToProps(state) {
    return state;
}

/* Export Component */
export default connect(mapStateToProps)(withRouter(AuthRoute));
