/* Packages */
import React, { useEffect, useState } from "react";

/* Services */
import { useSelector } from "react-redux";
import { ingresseService } from "../_services";
import getCookieValue from "../_utils/getCookieValue";

import { useTranslation } from "react-i18next";
import useUserLocation from "../_hooks/useUserLocation";

/* Component Itself */
function InputCountryCode({ setCountryCode, disabled, required, ddi }) {
    const [loading, setLoading] = useState(false);
    const [defaultCountryCode, setDefaultCountryCode] = useState();
    const [countryCodeList, setCountryCodeList] = useState([]);
    const { country } = useUserLocation();

    const { isFromIngresseDomain } = useSelector((state) => state.auth);
    const { t } = useTranslation();

    const location = getCookieValue("location");
    const locale = getCookieValue("locale");

    useEffect(() => {
        (async () => {
            setLoading(true);
            const data = await ingresseService.api().get("/country");

            const { responseData } = data;
            if (typeof responseData !== "object" || !responseData.length) {
                return;
            }
            setCountryCodeList(responseData);
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (ddi) {
            setCountryCodeByIndex(ddi);
        } else {
            updateSelectIndex();
        }
    }, [countryCodeList, ddi]);

    useEffect(() => {
        if(countryCodeList.length && country){
            setCountryDDI()
        }
    }, [countryCodeList, country])

    const updateSelectIndex = () => {
        const isESP = locale === "es-ES" || location === "ESP";
        const defaultCountryCode = isESP ? "34" : "55";
        setCountryCodeByIndex(defaultCountryCode);
    };

    const setCountryDDI = () => {
        const countryData = countryCodeList.find(c => c.name === country);
        if (countryData) {
            setCountryCodeByIndex(countryData.callingCode);
        }
    };

    const setCountryCodeByIndex = (index) => {
        setDefaultCountryCode(index);
        setCountryCode(index);
    };
    /**
     * Render
     */
    return (
        <>
            <label htmlFor="countryCode" className="aph form__label">
                {t("AUTH.LABELS.DDI")}
                {required && <span className="text-red"> *</span>}
            </label>
            <select
                className={`aph form__control ${
                    !isFromIngresseDomain && "custom_mrt_input"
                }`}
                id="countryCode"
                onChange={(e) => setCountryCodeByIndex(e.target.value)}
                value={defaultCountryCode}
                disabled={disabled}
            >
                {countryCodeList.map((countryCode, i) => {
                    return (
                        <option key={i} value={countryCode.callingCode}>
                            +{countryCode.callingCode}{" "}
                            {defaultCountryCode === countryCode.callingCode
                                ? countryCode.alpha3Code
                                : countryCode.name}
                        </option>
                    );
                })}
                {loading
                    ? Array.from({ length: 9 }).map((a, i) => (
                          <option key={"loading" + i} disabled value={""}>
                              Carregando países...
                          </option>
                      ))
                    : null}
            </select>
        </>
    );
}

/* Export Component */
export default InputCountryCode;
