/* External Resources Constant */
export const externalConstants = {
    FACEBOOK: {
        SDK: "//connect.facebook.com/pt_BR/sdk.js",
        SCOPE: "email",
        KEYS: {
            APP_ID: "137390569695126",
        },
    },
};
