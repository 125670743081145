import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { isValid as isValidCPF } from "@fnando/cpf";

import { authActions } from "../../_actions";

import { classListService, flowService } from "../../_services";

import { phoneFilter } from "../../_filters";

import { validators } from "../../_utils";

import {
    textsConstants as TEXTS,
    messagesConstants as MESSAGES,
    routesConstants as ROUTES,
    userConstants as USER,
} from "../../_constants";

import FormRegisterAndIncomplete from "../../_components/FormRegisterAndIncomplete";

const phoneErrors = [
    MESSAGES.API.ERROR_CODES["1145"],
    MESSAGES.API.ERROR_CODES["1146"],
    MESSAGES.API.ERROR_CODES["1109"],
    MESSAGES.API.ERROR_CODES["1192"],
    "O telefone informado já está em uso.777 ",
];
const documentErrors = [
    MESSAGES.API.ERROR_CODES["1032"],
    MESSAGES.API.ERROR_CODES["1060"],
    MESSAGES.API.ERROR_CODES["1061"],
    MESSAGES.API.ERROR_CODES["6020"],
    MESSAGES.API.ERROR_CODES["6086"],
];
const birthDateErrors = [
    MESSAGES.API.ERROR_CODES["1172"],
    MESSAGES.API.ERROR_CODES["1102"],
];
const formErrors = [...phoneErrors, ...documentErrors, ...birthDateErrors];

function AuthFormIncomplete({ auth, user, dispatch }) {
    const history = useHistory();
    const { t } = useTranslation();

    const [focused, setFocused] = useState("");
    const [error, setError] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({});
    const [invalid, setInvalid] = useState({
        phone: false,
        nationality: false,
        document: false,
        birthdate: false,
        terms: false,
    });
    const canSubmit = useMemo(() => {
        const hasInvalidInfo = Object.values(invalid).some(
            (isUserInfoInvalid) => isUserInfoInvalid
        );

        const hasRequiredInfoMissing = Object.values(formData).some(
            (formValue) => !formValue
        );

        return !hasInvalidInfo && !hasRequiredInfoMissing;
    }, [formData, invalid]);

    useEffect(() => {
        if (auth.error) return;

        let initFormData = {};
        const { data } = user || {};

        USER.REQUIRED.forEach((keyData) => {
            switch (keyData) {
                case "phone":
                    if (!data.phone) {
                        initFormData.ddi = data?.ddi?.toString() || "55";
                        initFormData.phone = "";
                    }

                    break;

                case "document":
                    if (!data.document || !data.document.number) {
                        initFormData.document = "";
                    }

                    break;

                default:
                    if (!data[keyData]) {
                        initFormData[keyData] = "";
                    }
                    break;
            }
        });

        if (!Object.keys(initFormData).length) {
            history.push(ROUTES.AUTH.CONFIRM + flowService.getSearch());
            return;
        }

        setFormData((value) => {
            return Object.keys(value).length ? value : initFormData;
        });
    }, [auth.error, user, history]);

    useEffect(() => {
        if (auth?.error) {
            setError(auth.error);
            setSubmitted(false);
            classListService.remove();
            return;
        }
    }, [auth.error]);

    function handleBlur() {
        setFocused("");
    }

    function handleFocus(evt) {
        if (!evt) {
            setFocused("");
            return;
        }

        setFocused(evt.target.name);
    }

    function setCountryCode(ddi) {
        setFormData((state) => ({ ...state, ddi, phone: "" }));
    }

    const validStateValue = useCallback(
        (key, value) => {
            const _value = value || formData[key] || "";
            let isInvalid = false;

            if (validators[key]) {
                isInvalid =
                    key === "phone" && formData.ddi !== "55"
                        ? _value.length < 5
                        : !validators[key](_value);
            }

            if (
                key === "document" &&
                formData.nationality === TEXTS.AUTH.NATIONALITY.BRAZILIAN
            ) {
                isInvalid = !isValidCPF(value);
            }

            const clearDocumentInvalid =
                key === "nationality" ? { document: false } : {};

            setInvalid((currentInvalids) => ({
                ...currentInvalids,
                [key]: isInvalid,
                ...clearDocumentInvalid,
            }));
        },
        [formData]
    );

    const handleInput = useCallback(
        (evt, key, value) => {
            const target = evt && evt.target ? evt.target : {};
            const _key = key || target.name || target.id;
            const _value = value || target.value;

            const clearDocumentField =
                _key === "nationality" && "document" in formData
                    ? { document: "" }
                    : null;

            setFormData((state) => ({
                ...state,
                [_key]:
                    _key === "phone" ? phoneFilter.onlyDigits(_value) : _value,
                ...clearDocumentField,
            }));

            validStateValue(_key, _value);
        },
        [validStateValue, formData]
    );

    const handleSubmit = useCallback(
        (evt) => {
            evt.preventDefault();

            if (submitted || !canSubmit) {
                return;
            }

            setSubmitted(true);
            setError("");

            classListService.add();

            dispatch(authActions.update(formData, user?.data));
        },
        [canSubmit, formData, submitted, dispatch, user.data]
    );

    return (
        <form className="aph form" noValidate={true} onSubmit={handleSubmit}>
            <FormRegisterAndIncomplete
                data={user?.data}
                setCountryCode={setCountryCode}
                formData={formData}
                submitted={submitted}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                handleInput={handleInput}
                error={error}
                focused={focused}
                invalid={invalid}
                phoneErrors={phoneErrors}
                documentErrors={documentErrors}
                birthDateErrors={birthDateErrors}
            />

            <div className="aph m-10-ver show-xs" />
            <div className="aph m-20-top">
                <button
                    className="aph btn btn--block btn--primary"
                    id="btnRegisterSubmit"
                    type="submit"
                    disabled={submitted || !canSubmit}
                >
                    {t("AUTH.REGISTER.UPDATE.SUBMIT")}
                </button>
            </div>
            {error && !formErrors.includes(error) && (
                <div className="aph form__helper text-center text-red m-10-top">
                    {error}
                </div>
            )}
        </form>
    );
}

function mapStateToProps(state) {
    return state;
}

export default connect(mapStateToProps)(AuthFormIncomplete);
