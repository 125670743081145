import React from "react";

export default function DialogLimitDevices({ updateFrame }) {
    const errorCode = localStorage.getItem("errorCode");

    return (
        <div style={Backshadow}>
            <div style={Customodal}>
                <div onClick={() => updateFrame()} style={Deleteicon}>
                    x
                </div>
                {errorCode === "65" ? (
                    <>
                        <h3 style={Modaltitle}>
                            Você atingiu o limite de 15 <br />
                            dispositivos verificados.
                        </h3>
                        <div style={Wrapper}>
                            <p style={Content}>
                                Para adicionar um novo dispositivo, remova algum
                                que já esteja associado à sua conta Ingresse.
                            </p>
                            <p style={Content}>
                                Para remover, você precisa acessar sua conta com
                                um dispositivo já verificado.
                            </p>
                            <p style={Content}>
                                Caso tenha alguma dúvida,{" "}
                                <a
                                    style={Link}
                                    href="https://ingresse.freshdesk.com/support/tickets/new"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    fale com a Ingresse.
                                </a>{" "}
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        <h3 style={Modaltitle}>
                            O número de telefone informado <br />
                            já está ativo em outra conta.
                        </h3>
                        <div style={Wrapper}>
                            <p style={Content}>
                                Para adicionar este dispositivo, remova o número
                                que já esteja associado à sua conta Ingresse.
                            </p>
                            <p style={Content}>
                                Caso tenha alguma dúvida,{" "}
                                <a
                                    style={Link}
                                    href="https://ingresse.freshdesk.com/support/tickets/new"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    fale com a Ingresse.
                                </a>{" "}
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

/* General Dialog Styles  */
const Backshadow = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.3)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "999",
};

const Wrapper = {
    padding: "0 32px 32px 32px",
};

const Customodal = {
    maxWidth: "592px",
    minHeight: "327px",
    backgroundColor: "#fff",
    position: "relative",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 10px",
};

const Deleteicon = {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    color: "#7a8085",
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    cursor: "pointer",
    position: "absolute",
    top: "16px",
    right: "16px",
    justifyContent: "center",
};

const Content = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "150%",
    textAlign: "center",
    color: "#637381",
};

const Modaltitle = {
    paddingTop: "32px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "140%",
    textAlign: "center",
    color: "#212B36",
};

const Link = {
    textDecoration: "underline",
    cursor: "pointer",
    color: "#637381",
};
