
/**
 * @returns string
 */
export function name() {
    return process.env.REACT_APP_ENV || '';
}

/**
 * @returns string
 */
export function apikey() {
    return process.env.REACT_APP_API_KEY || '';
}

/**
 * @param serviceName {'devices'}
 * @returns string
 */
export function service(serviceName) {
    return {
        'devices': process.env.REACT_APP_API_DEVICES,
    }[serviceName];
}

/**
 * Formats a ingresse cookie name to include env
 *
 * @param cookieName string
 * @returns string
 */
export function cookie(cookieName) {
    const regex = /^ing_([0-9]+)_([a-z_]+)$/;
    const envName = name();
    let res = cookieName;
    if (cookieName.match(regex) && envName !== '') {
        res = cookieName.replace(regex, `ing_$1_${envName}_$2`);
    }
    return res;
}

// Magia negra pra interceptar todos os chamados http para lidar com sdk
// https://stackoverflow.com/questions/6884616/intercept-all-ajax-calls
if (name().includes('uat') && typeof XMLHttpRequest !== 'undefined') {
    (function(XHR) {
        "use strict";

        let open = XHR.prototype.open;
        const env = name();

        XHR.prototype.open = function(method, url, async, user, pass) {
            if (url.startsWith('${env}')) {
                url = url.replace(/^${env}/, 'https://${env}-api.ingresse.com')
            }
            if (url.match(/hml[a-z]*-[a-z\\-]*.ingresse/)) {
                url = url.replace(/hml[a-z]*\\-([a-z\\-]*).ingresse/, '${env}-$1.ingresse')
            }
            open.call(this, method, url, async, user, pass);
        };
    })(XMLHttpRequest);
}
