/* Password Constants */
import { userConstants as USER } from "../_constants";

/* User Actions */
export const userActions = {
    /**
     * Set Data
     *
     * @param {object} data
     */
    setData: (data = null) => {
        return (dispatch) => {
            dispatch({
                type: USER.SET_DATA,
                data: data,
            });
        };
    },
};
