/* Packages */
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

/* Services */
import {
    settingsService,
    facebookService,
    flowService,
    passwordService,
    redirectService,
    trustedService,
} from "./_services";

/* Routes Paths */
import { routesConstants as ROUTES } from "./_constants";

/* Framework Helpers */
import { ToastsContainer } from "aphrodite-react";

/* Routers (Components) */
import AuthRoute from "./_routers/AuthRoute";
import PrivateRoute from "./_routers/PrivateRoute";

/* Layouts (Components) */
import LayoutAuth from "./_layouts/Auth.jsx";

/* Pages / Content */
import AuthChoices from "./Auth/AuthChoices.jsx";
import AuthLogin from "./Auth/AuthLogin.jsx";
import AuthRegister from "./Auth/AuthRegister.jsx";
import AuthCompanies from "./Auth/AuthCompanies.jsx";
import AuthConfirm from "./Auth/AuthConfirm.jsx";
import AuthDone from "./Auth/AuthDone.jsx";
import AuthFacebankCallback from "./Auth/AuthFacebankCallback";
import PasswordRecover from "./Password/Recover.jsx";
import PasswordRecoverSent from "./Password/RecoverSent.jsx";
import PasswordUpdate from "./Password/Update.jsx";
import PasswordUpdated from "./Password/Updated.jsx";
import Trusted from "./Trusted/Trusted.jsx";
import { removeAllCookies } from "./_utils/removeAllCookies.js";
import AuthTwoFA from "./Auth/AuthTwoFA.jsx";
import AuthTwoFARegister from "./Auth/AuthTwoFARegister.jsx";
import OauthLogin from "./Auth/OauthLogin.jsx";
import OauthAuthorize from "./Auth/OauthAuthorize.jsx";
import OauthRegister from "./Auth/OauthRegister.jsx";
import OauthCredentials from "./Auth/OauthCredentials.jsx";

/* App Router */
class AppRouter extends React.Component {
    /* Constructor */
    constructor(props) {
        super(props);

        this.props = props;
    }

    /* Component Will Mount */
    componentWillMount() {
        const { dispatch } = this.props;
        removeAllCookies();

        /* Initialize Settings */
        dispatch(settingsService.init());

        /* Initialize Password stuffs */
        dispatch(passwordService.init());

        /* Initialize Redirect stuffs */
        dispatch(redirectService.init());

        /* Initialize Trusted stuffs */
        dispatch(trustedService.init());

        /* Initialize Facebook SDK */
        facebookService.init();
    }

    /* Render */
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    {/* AUTH ROUTES */}
                    <AuthRoute
                        path={ROUTES.INDEX}
                        component={LayoutAuth}
                        content={AuthChoices}
                    />
                    <AuthRoute
                        path={ROUTES.AUTH.LOGIN}
                        component={LayoutAuth}
                        content={AuthLogin}
                    />

                    <AuthRoute
                        path={ROUTES.AUTH.REGISTER}
                        component={LayoutAuth}
                        content={<AuthRegister />}
                    />
                    <AuthRoute
                        path={ROUTES.AUTH.INCOMPLETE}
                        component={LayoutAuth}
                        content={<AuthRegister />}
                    />
                    <AuthRoute
                        path={ROUTES.AUTH.FACEBANK}
                        component={LayoutAuth}
                        content={<AuthFacebankCallback />}
                    />
                    <Redirect
                        from={ROUTES.AUTH.CALLBACK}
                        to={ROUTES.AUTH.FACEBANK + flowService.getSearch()}
                    />

                    {/* PASSWORD ROUTES */}
                    <AuthRoute
                        path={ROUTES.PASSWORD.RECOVER}
                        exact
                        component={LayoutAuth}
                        content={<PasswordRecover />}
                    />
                    <AuthRoute
                        path={ROUTES.PASSWORD.SENT}
                        exact
                        component={LayoutAuth}
                        content={<PasswordRecoverSent />}
                    />
                    <AuthRoute
                        path={ROUTES.PASSWORD.UPDATE}
                        exact
                        component={LayoutAuth}
                        content={<PasswordUpdate />}
                    />
                    <AuthRoute
                        path={ROUTES.PASSWORD.UPDATED}
                        exact
                        component={LayoutAuth}
                        content={<PasswordUpdated />}
                    />

                    {/* OAUTH ROUTES */}
                    <AuthRoute
                        path={ROUTES.OAUTH.LOGIN}
                        component={LayoutAuth}
                        content={<OauthLogin />}
                    />
                    <PrivateRoute
                        path={ROUTES.OAUTH.AUTHORIZE}
                        component={LayoutAuth}
                        content={<OauthAuthorize />}
                    />
                    <AuthRoute
                        path={ROUTES.OAUTH.REGISTER}
                        component={LayoutAuth}
                        content={<OauthRegister />}
                    />
                    <AuthRoute
                        path={ROUTES.OAUTH.CREDENTIALS}
                        component={LayoutAuth}
                        content={<OauthCredentials />}
                    />

                    {/* PRIVATE ROUTES */}
                    <PrivateRoute
                        path={ROUTES.PRIVATE.COMPANIES}
                        component={LayoutAuth}
                        content={AuthCompanies}
                    />
                    <PrivateRoute
                        path={ROUTES.PRIVATE.USER_CONFIRM}
                        component={LayoutAuth}
                        content={AuthConfirm}
                    />
                    <PrivateRoute
                        path={ROUTES.PRIVATE.DONE}
                        component={LayoutAuth}
                        content={<AuthDone />}
                    />
                    <PrivateRoute
                        path={ROUTES.AUTH.TWOFA}
                        component={LayoutAuth}
                        content={<AuthTwoFA />}
                        exact
                    />
                    <PrivateRoute
                        path={ROUTES.AUTH.REGISTER_TWOFA}
                        component={LayoutAuth}
                        content={<AuthTwoFARegister />}
                        exact
                    />
                    <PrivateRoute
                        path={ROUTES.PRIVATE.TRUSTED}
                        component={LayoutAuth}
                        content={<Trusted />}
                    />

                    {/* OTHERWISE REDIRECT */}
                    <Redirect
                        to={
                            ROUTES.PRIVATE.USER_CONFIRM +
                            flowService.getSearch()
                        }
                    />

                    {/* TOASTS CONTAINER */}
                    <ToastsContainer
                        hideProgressBar={true}
                        autoClose={false}
                        styles={{ top: "45px", right: "45px" }}
                    />
                </Switch>
            </BrowserRouter>
        );
    }
}

/* Map State to Props/Childrens */
function mapStateToProps(state) {
    return state;
}

// the way to connect a component to redux is
// to return its connected proxy
export default connect(mapStateToProps)(AppRouter);
