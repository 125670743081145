/* Packages */
import React from "react";

/* Services */
import { settingsService } from "../_services";

/* Additional Component */
import SimpleFooterItem from "../_components/SimpleFooterItem.jsx";
import { connect } from "react-redux";

/* Export Component */
class SimpleFooter extends React.Component {
    constructor() {
        super();

        this.year = new Date().getFullYear();
    }

    componentWillMount() {
        const settings = settingsService.get();
        this.COMPANY = settings.COMPANY;
        this.APP = settings.APP;
    }

    render() {
        const { isFromIngresseDomain } = this.props.auth;
        if (!this.APP.FOOTER) {
            return (
                <div
                    className={`footer ${
                        !isFromIngresseDomain && "custom_mrt_hide_element"
                    }`}
                >
                    {this.COMPANY.FOOTER.map((item, index) => (
                        <SimpleFooterItem
                            key={index}
                            id={index}
                            target={item.TARGET}
                            href={item.HREF}
                            title={item.TITLE}
                            text={item.TEXT}
                        />
                    ))}
                    <div className="footer__copyright">
                        Copyright © {this.year} {this.COMPANY.NAME}
                    </div>
                </div>
            );
        }

        return <div className="footer">{this.APP.FOOTER}</div>;
    }
}

function mapStateToProps(state) {
    return state;
}

/* Export Component */
export default connect(mapStateToProps)(SimpleFooter);
