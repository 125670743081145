/* Packages */
import React from "react";

/* Component */
import AuthUserConfirm from "./components/AuthUserConfirm";

/* Auth User Component */
const AuthConfirm = (
    <div className="segment__content__limited">
        <AuthUserConfirm />
    </div>
);

/* Export Component */
export default AuthConfirm;
