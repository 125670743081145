/* Constant */
import { TRUSTED } from "../_constants";

/* Initial State */
const initialState = {
    device: null,
    verified: false,
};

/* Export Reducer */
const trusted = (state = initialState, action) => {
    switch (action.type) {
        case TRUSTED.SET:
            return {
                ...state,
                verified: action.data,
            };

        case TRUSTED.SET_DEVICE:
            return {
                ...state,
                device: action.data,
            };

        default:
            return state;
    }
};

/* Export Reducer */
export default trusted;
