/* Oauth Constants */
export const oauthConstants = {
    SET_OAUTH_SETTINGS: "SET_OAUTH_SETTINGS",
    ACTIVATE_OAUTH: "ACTIVATE_OAUTH",

    LOGIN_FAILURE: "OAUTH_LOGIN_FAILURE",
    LOGIN_SUCCESS: "OAUTH_LOGIN_SUCCESS",
    CONSENT_SUCCESS: "OAUTH_CONSENT_SUCCESS",
    CONSENT_FAILURE: "OAUTH_CONSENT_FAILURE",
    CREDENTIALS_FAILURE: "OAUTH_CREDENTIALS_FAILURE",
    CREDENTIALS_SUCCESS: "OAUTH_CREDENTIALS_SUCCESS",
    CLEAR_CREDENTIALS: "OAUTH_CLEAR_CREDENTIALS",
    CANCEL_SSO: "OAUTH_CANCEL_SSO",
    SET_USER_DATA: "OAUTH_SET_USER_DATA",
};
