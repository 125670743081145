/** Core Packages */
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { authService, ingresseService, trustedService } from "../_services";
import { authActions } from "../_actions";

/** Helper values */
const defaultRequestState = {
    data: undefined,
    error: undefined,
    isLoading: false,
};

/** Hook itself */
export function useSocialLogin({ provider = "facebank" }) {
    /**
     * Context values
     */
    const dispatch = useDispatch();

    /**
     * Local values
     */
    const [requestState, setRequestState] = useState(defaultRequestState);
    const wasTriggered = !!(
        requestState.data ||
        requestState.error ||
        requestState.isLoading
    );

    /**
     * Social Login API request to Ingresse servers
     *
     * @param {String} code   - The code received on the Callback from Facebank
     * @param {Object} device - The "device" identification generated by us
     */
    const login = useCallback(
        async ({ code, device }) => {
            dispatch(authActions.cleanError());
            setRequestState((currentRequestState) => ({
                ...currentRequestState,
                ...defaultRequestState,
                isLoading: true,
            }));

            try {
                const requestHeaders = {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Ingresse-Device": JSON.stringify(device || {}),
                };
                const response = await ingresseService
                    .api()
                    .post(`/login/${provider}`, { code }, null, requestHeaders);
                const { responseData: rawResponseData, responseError } =
                    response || {};
                const { data: responseData } = rawResponseData || {};
                const { userId: responseUserId, device: responseDevice } =
                    responseData || {};

                if (responseError || !responseUserId) {
                    const error = responseError || {
                        message: `Não conseguimos identificar o seu cadastro ${provider}.`,
                    };

                    dispatch(authActions.socialError(error));
                    setRequestState((currentRequestState) => ({
                        ...currentRequestState,
                        isLoading: false,
                        error,
                    }));
                } else {
                    trustedService[
                        responseDevice?.verified
                            ? "saveAsValid"
                            : "removeAsValid"
                    ](responseUserId);
                    authService.setCredentials(responseData);
                    dispatch(authActions.setCredentials(responseData));
                    dispatch(authActions.socialSuccess(responseData));
                    setRequestState((currentRequestState) => ({
                        ...currentRequestState,
                        isLoading: false,
                        data: responseData,
                    }));
                }
                window.close();
            } catch (error) {
                dispatch(authActions.socialError(error));
                setRequestState((currentRequestState) => ({
                    ...currentRequestState,
                    isLoading: false,
                    error,
                }));
            }
        },
        [dispatch, provider],
    );

    return {
        login,
        ...requestState,
        wasTriggered,
    };
}
