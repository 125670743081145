/* Auth Constants */
export const authConstants = {
    GET_CREDENTIALS: "AUTH_GET_CREDENTIALS",
    SET_CREDENTIALS: "AUTH_SET_CREDENTIALS",

    CONFIRM: "AUTH_CONFIRM",

    LOGOUT: "AUTH_LOGOUT",

    FACEBOOK_REGISTER: "AUTH_FACEBOOK_REGISTER",

    REGISTER_REQUEST: "AUTH_REGISTER_REQUEST",
    REGISTER_SUCCESS: "AUTH_REGISTER_SUCCESS",
    REGISTER_FAILURE: "AUTH_REGISTER_FAILURE",
    REGISTER_FAILURE_CLEAN: "AUTH_REGISTER_FAILURE_CLEAN",

    UPDATE_FAILURE: "AUTH_UPDATE_FAILURE",

    LOGIN_REQUEST: "AUTH_LOGIN_REQUEST",
    LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
    LOGIN_FAILURE: "AUTH_LOGIN_FAILURE",

    LOGIN_COMPANY_SUCCESS: "AUTH_COMPANY_LOGIN_SUCCESS",
    LOGIN_COMPANY_FAILURE: "AUTH_COMPANY_LOGIN_FAILURE",

    COMPANY_SELECTED: "AUTH_COMPANY_SELECTED",
    CLEAN_ERROR: "AUTH_CLEAN_ERROR",

    OUT_INGRESSE_DOMAIN: "AUTH_OUT_INGRESSE_DOMAIN",
};
