import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PASSWORD } from "../_constants";
import IconCheckList from "../_components/svg/IconCheckList";
import { useSelector } from "react-redux";

function PasswordHints({ score, delay }) {
    const hintsHeight = "293px";

    const [hintStyles, setHintStyles] = useState({
        height: hintsHeight,
        opacity: 1,
        overflow: "hidden",
        transition: `opacity ${delay}ms ease-in, height ${delay}ms ease-in`,
    });
    const { t } = useTranslation();
    const { isFromIngresseDomain } = useSelector((state) => state.auth);

    const PASSWORDS = ["TITLE", "HINT"];

    useEffect(() => {
        if (score < PASSWORD.MIN_SCORE) {
            setHintStyles({
                ...hintStyles,
                height: 'auto',
                opacity: 1,
            });

            return;
        }

        setHintStyles({
            ...hintStyles,
            height: 0,
            opacity: 0,
        });
    }, [score, hintStyles]);

    return (
        <div style={hintStyles}>
            {PASSWORDS.map((item, index) => (
                <div key={index}>
                    <ul className="aph list m-20-bot">
                        <li className="aph list__header">
                            {t(`VALIDATIONS.PASSWORD_STRENGTH.${index}.TITLE`)}
                        </li>

                        {[1, 2, 3].map((hint, i) => (
                            <li
                                className={`aph list__item ${
                                    !isFromIngresseDomain &&
                                    "custom_mrt_li_check"
                                }`}
                                key={i}
                            >
                                <IconCheckList
                                    slug={index === 0 ? "donts" : "do"}
                                />
                                {t(
                                    `VALIDATIONS.PASSWORD_STRENGTH.${index}.HINT.${i}`
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}

export default PasswordHints;
