import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "aphrodite-react";
import { withTranslation } from "react-i18next";
import posthog from "posthog-js";

import {
    messagesConstants as MESSAGES,
    routesConstants as ROUTES,
    urlsConstants as URLS,
    PASSWORD,
} from "../_constants";

import { cookiesUtils, validators } from "../_utils";

import { passwordActions } from "../_actions";

import { passwordService as service, classListService } from "../_services";

import FlowLink from "../_components/FlowLink";

const errorsWhichRedirectToRegister = [
    MESSAGES.API.ERROR_CODES["3030"],
    MESSAGES.API.ERROR_CODES["6054"],
];
class Recover extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            email: this.props.email || "",
            error: "",
            focused: "",

            canSubmit: false,
            submitted: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.emailErrors = [
            MESSAGES.API.ERROR_CODES["1001"],
            MESSAGES.API.ERROR_CODES["1006"],
            MESSAGES.API.ERROR_CODES["1007"],
            MESSAGES.API.ERROR_CODES["1142"],
            MESSAGES.API.ERROR_CODES["3030"],
            MESSAGES.API.ERROR_CODES["6063"],
        ];
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(passwordActions.setError(""));
    }

    componentDidMount() {
        const { email } = this.state;

        this.handleChange({
            target: {
                name: "email",
                value: email,
            },
        });
    }

    componentDidUpdate() {
        const { error } = this.props;

        if (!error) {
            return;
        }

        toast.error(error);
    }

    handleChange(evt) {
        const { target } = evt;
        const { name, value } = target;
        const invalidKey = name ? name[0].toUpperCase() + name.slice(1) : "";

        if (validators[name]) {
            const isInvalid = !validators[name](value);

            this.setState({
                canSubmit: !isInvalid,
                ["invalid" + invalidKey]: isInvalid,
            });
        }

        this.setState({
            [name]: value,
        });
    }

    handleBlur() {
        this.setState({
            focused: "",
        });
    }

    handleFocus(evt) {
        if (!evt) {
            this.setState({
                focused: "",
            });

            return;
        }

        const { target } = evt;
        const { name } = target;

        this.setState({
            focused: name,
        });
    }

    handleSubmit(evt) {
        evt.preventDefault();
        posthog.capture("auth:reset_password_email_send");
        if (!this.state.canSubmit) {
            return;
        }

        classListService.add();

        this.setState(
            {
                error: "",
                loading: true,
                submitted: true,
            },
            () => {
                const { email } = this.state;
                const { history, dispatch } = this.props;

                service
                    .recover(email)
                    .then(() => {
                        dispatch(passwordActions.setHash(PASSWORD.SENT));
                        history.push(
                            ROUTES.PASSWORD.SENT + history.location.search
                        );
                        sessionStorage.removeItem("remainingAttempts");
                        classListService.remove(250);
                    })
                    .catch((error) => {
                        if (errorsWhichRedirectToRegister.includes(error)) {
                            const registerRoute =
                                `${ROUTES.AUTH.REGISTER}${history.location.search}` +
                                ((history.location.search.includes("?")
                                    ? "&"
                                    : "?") +
                                    `email=${encodeURIComponent(email)}`);

                            history.push(registerRoute);
                            classListService.remove(250);
                            toast.error(
                                `Não foi possível recuperar sua senha: ${error}`,
                                { autoClose: 15000 }
                            );

                            return;
                        }

                        this.setState(
                            {
                                error: error,
                                loading: false,
                                submitted: false,
                            },
                            () => {
                                classListService.remove(250);
                            }
                        );
                    });
            }
        );
    }

    handleClick = (event) => {
        event.preventDefault();
        posthog.capture("auth:reset_password_support_click");
    
        setTimeout(() => {
            window.location.href = URLS.PASSWORD.FRESHDESK; 
        }, 300);
    };

    /* Render */
    render() {
        const {
            error,

            email,
            invalidEmail,

            focused,
            submitted,
            canSubmit,
        } = this.state;

        const { isFromIngresseDomain } = this.props;

        return (
            <div className="segment__content__limited">
                <div className="aph text-center">
                    <div className="aph p-5-top m-5-bot">
                        <h3 className="aph text-dark-grey m-0">
                            {this.props.t("PASSWORD.RECOVER.TITLE")}
                        </h3>
                    </div>
                    <div className="aph p-5-top m-5-bot">
                        <div className="aph text-grey p-10-ver">
                            {this.props.t("PASSWORD.RECOVER.DESCRIPTION_1")}
                        </div>
                        <div className="aph text-grey p-10-ver">
                            {this.props.t("PASSWORD.RECOVER.DESCRIPTION_2")}
                        </div>
                    </div>
                </div>
                <form
                    noValidate={true}
                    onSubmit={this.handleSubmit}
                    className="aph form"
                >
                    <div className="aph p-20-ver text-left">
                        <label className="aph form__label" htmlFor="email">
                            {this.props.t(
                                "PASSWORD.RECOVER.FIELDS.EMAIL.LABEL"
                            )}
                        </label>
                        <input
                            className="aph form__control"
                            type="email"
                            id="email"
                            name="email"
                            required={true}
                            disabled={submitted ? true : false}
                            value={email}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            placeholder={this.props.t(
                                "PASSWORD.RECOVER.FIELDS.EMAIL.PLACEHOLDER"
                            )}
                        />
                        <div
                            className={
                                "aph form__helper text-" +
                                (focused !== "email" ? "red" : "dark-grey")
                            }
                        >
                            {email && invalidEmail
                                ? this.props.t("VALIDATIONS.EMAIL")
                                : ""}
                        </div>
                        {error && (
                            <div className="aph form__helper text-red">
                                {error}
                            </div>
                        )}
                    </div>
                    <div>
                        <button
                            className="aph btn btn--block btn--primary"
                            type="submit"
                            id="btnRecoverSubmit"
                            disabled={submitted || !canSubmit ? true : false}
                        >
                            {this.props.t("PASSWORD.RECOVER.ACTIONS.SEND")}
                        </button>
                    </div>
                    <div className="aph text-center">
                        <div className="aph p-0-ver p-30-top">
                            {isFromIngresseDomain && (
                                <div className="aph text-grey">
                                    {this.props.t("PASSWORD.RECOVER.HELP")}
                                    <span className="aph text-blue">
                                        <a
                                            className="aph btn--block btn--link"
                                            style={{
                                                paddingLeft: 4,
                                                height: 21,
                                                display: "inline",
                                            }}
                                            id="btnRecoverHelp"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            // href={URLS.PASSWORD.FRESHDESK}
                                            onClick={this.handleClick}
                                        >
                                            {this.props.t(
                                                "PASSWORD.RECOVER.FRESHDESK"
                                            )}
                                        </a>
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="aph p-20-top p-10-bot">
                        <FlowLink
                            onClick={() => posthog.capture("auth:reset_password_email_cancel")}
                            className="aph btn btn--block btn--secondary btn--link"
                            id="linkRecoverToLogin"
                            to={ROUTES.AUTH.LOGIN}
                        >
                            {this.props.t("PASSWORD.RECOVER.ACTIONS.CANCEL")}
                        </FlowLink>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        email: state.password.email,
        error: state.password.error,
        isFromIngresseDomain: state.auth.isFromIngresseDomain,
    };
}

export default withTranslation()(connect(mapStateToProps)(withRouter(Recover)));
