/* Services Base */
import { authService } from "./";

/* Constants */
const PREFIX = "ING:";

/* Variables */
const _top = window.opener
    ? window.opener
    : window.parent
    ? window.parent
    : window;

/**
 * Generic Sender
 *
 * @param {string} title   - message title
 * @param {object} content - message content
 * @param {string} type    - message type
 *
 * @return {boolean} operation status
 */
const _generic = (title, content, type) => {
    if (!title || !content) {
        return false;
    }
    const postMessage = _top.postMessage(
        {
            action: PREFIX + title,
            category: type || "INFO",
            content: content,
        },
        "*"
    );
    return postMessage;
};

/**
 * Get Session's User Credentials
 */
const _getCredentials = () => {
    return (
        authService.getCredentials() || {
            userId: "",
            token: "",
            jwt: "",
        }
    );
};

/**
 * Auth User Confirmed
 *
 * @return {boolean} operation status
 */
const authConfirm = () => {
    const { userId, token, jwt } = _getCredentials();
    return _generic(
        "AUTH_CONFIRMED",
        {
            userId: userId,
            token: token,
            authToken: jwt,
        },
        "AUTH"
    );
};

/**
 * Auth User Logout
 *
 * @return {boolean} operation status
 */
const authLogout = () => {
    return _generic("AUTH_LOGOUT", {}, "AUTH");
};

/**
 * Login Failure
 *
 * @param {object} reason - API response error
 *
 * @return {boolean} operation status
 */
const authLoginFailure = (reason) => {
    if (!reason) {
        return false;
    }

    return _generic(
        "AUTH_LOGIN_FAIL",
        {
            reason: reason,
        },
        "AUTH"
    );
};

/**
 * Login Success
 *
 * @param {object} userData - optional
 *
 * @return {boolean} operation status
 */
const authLoginSuccess = (userData = null) => {
    const { userId, token, jwt } = _getCredentials();

    return _generic(
        "AUTH_LOGIN_SUCCESS",
        {
            userId: userId,
            token: token,
            authToken: jwt,
            data: userData,
        },
        "AUTH"
    );
};

const setLocale = (locale) => {
    return _generic("AUTH_LOCALE", locale, "AUTH");
};

/**
 * Register Failure
 *
 * @param {object} error - API response error
 *
 * @return {boolean} operation status
 */
const authRegisterFailure = (error) => {
    if (!error) {
        return false;
    }

    return _generic("AUTH_REGISTER_FAIL", error, "AUTH");
};

/**
 * Register Success
 *
 * @param {object} userData - optional
 *
 * @return {boolean} operation status
 */
const authRegisterSuccess = (userData = null) => {
    const { userId, token, jwt } = _getCredentials();

    return _generic(
        "AUTH_REGISTER_SUCCESS",
        {
            userId: userId,
            token: token,
            authToken: jwt,
            data: userData,
        },
        "AUTH"
    );
};

/**
 * Company Login Success
 *
 * @param {object} companyData
 *
 * @return {boolean} operation status
 */
const authCompanyLoginSuccess = (companyData) => {
    return _generic(
        "AUTH_COMPANY_LOGIN_SUCCESS",
        {
            company: companyData,
        },
        "AUTH"
    );
};

/**
 * Authentication Done
 *
 * @param {object}  companyData - optional
 * @param {boolean} companies   - optional
 * @param {object}  userData    - optional
 */
const authDone = (companyData, companies, userData = null) => {
    const { userId, token, jwt } = _getCredentials();
    const updatedCompanies = [{
        ...companyData,
        authToken: jwt,
        token: token,
    }];

    let _userData = {
        userId: userId,
        token: token,
        authToken: jwt,
        userData: userData,
    };

    let _additionals = {
        origin: window.location.origin,
        path: window.location.pathname,
        search: window.location.search,
    };

    if (updatedCompanies) {
        _additionals.companies = updatedCompanies;
    }

    return _generic(
        "AUTH_DONE",
        Object.assign(
            companyData ? { company: companyData } : _userData,
            _additionals
        )
    );
};

/**
 * Auth Success
 *
 * @param {object} userData
 */
const authSuccess = (userData = null) => {
    if (!userData) {
        return false;
    }
    return _generic("AUTH_DONE", userData);
};

/**
 * Password Recover Sent
 *
 * @param {string} email
 *
 * @return {boolean} operation status
 */
const passwordRecoverSent = (email) => {
    if (!email) {
        return false;
    }

    return _generic("RECOVER_SENT", email, "PASSWORD");
};

/**
 * Password Updated
 *
 * @param {string} email
 *
 * @return {boolean} operation status
 */
const passwordUpdated = (email) => {
    if (!email) {
        return false;
    }

    return _generic("UPDATED", email, "PASSWORD");
};

/**
 * Page Loaded event
 *
 * @return {boolean} operation status
 */
const pageLoaded = () => {
    return _generic("PAGE_LOADED", {
        origin: window.location.origin,
        path: window.location.pathname,
        search: window.location.search,
        width: window.innerWidth,
        height: window.innerHeight,
    });
};

/**
 * Auth User Logout
 *
 * @return {boolean} operation status
 */
const flow = (path = "") => {
    return _generic(
        `AUTH_FLOW`,
        {
            pathname: path.replace("/", ""),
        },
        "FLOW"
    );
};

/**
 * Device trusted
 *
 * @param {boolean} isTrust
 * @param {object}  device
 */
const trusted = (isTrust, device) => {
    return _generic("AUTH_TRUSTED", {
        trusted: isTrust,
        device,
    });
};

/**
 * Redirect application to support
 */
const support = () => {
    return _generic("AUTH_SUPPORT", {
        callSupport: true,
    });
};

const oauthCancelSSO = () => {
    return _generic("AUTH_CANCEL_SSO", {cancel: true}, "AUTH");
};

const oauthCredentialsFailure = () => {
    return _generic("AUTH_CREDENTIALS_FAILURE", {}, "AUTH");
};

/* Constant object to represent Service Functions */
export const postMessageService = {
    authConfirm,
    authLogout,
    authLoginFailure,
    authLoginSuccess,
    authRegisterFailure,
    authRegisterSuccess,
    authCompanyLoginSuccess,
    authDone,
    authSuccess,
    pageLoaded,
    passwordRecoverSent,
    passwordUpdated,
    flow,
    trusted,
    support,
    setLocale,
    oauthCredentialsFailure,
    oauthCancelSSO
};
