/** Core Packages */
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

/** Constants */
import { routesConstants as ROUTES } from "../_constants";

/** Hooks */
import { useSearchParams, useSocialLogin } from "../_hooks";

/** Helpers */
import { toast } from "aphrodite-react";

/** Components */
import { Icon, Styled } from "@ingresse/aphrodite";

/** Helper values */
const toastErrorConfigs = { autoClose: 10000 };

/** Component Itself */
export default function AuthFacebankCallback() {
    /**
     * Navigation helpers
     */
    const { push: routerPush, replace: routerReplace } = useHistory();
    const { pathname: currentPathname } = useLocation();
    const urlSearchParams = useSearchParams();
    const [facebankCode] = useState(urlSearchParams.get("code") || "");
    const navigate = useCallback(
        (pathname, errorMsg) => {
            urlSearchParams.delete("code");
            urlSearchParams.delete("scope");
            urlSearchParams.delete("state");

            routerPush({
                pathname,
                search: urlSearchParams.toString(),
            });

            if (errorMsg) {
                setTimeout(() => {
                    toast.error(errorMsg, toastErrorConfigs);
                }, 250);
            }
        },
        [routerPush, urlSearchParams],
    );

    /**
     * Context helpers
     */
    const { device } = useSelector((reduxState) => reduxState.trusted || {});

    /**
     * Social Login hook values
     */
    const {
        login: socialLogin,
        data: socialLoginData,
        error: socialLoginError,
        wasTriggered: socialLoginWasTriggered,
    } = useSocialLogin({ provider: "facebank" });
    const hasError = !!(!facebankCode || socialLoginError);

    /**
     * Social Login trigger
     */
    useEffect(() => {
        if (!facebankCode || !device || socialLoginWasTriggered) return;

        socialLogin({ code: facebankCode, device });

        /**
         * Clean the parameters from URL
         */
        urlSearchParams.delete("code");
        urlSearchParams.delete("scope");
        urlSearchParams.delete("state");

        routerReplace({
            pathname: currentPathname,
            search: urlSearchParams.toString(),
        });
    }, [
        currentPathname,
        facebankCode,
        device,
        socialLogin,
        socialLoginWasTriggered,
        routerReplace,
        urlSearchParams,
    ]);

    /**
     * Social Login error message
     */
    useEffect(() => {
        if (!socialLoginError) return;

        setTimeout(() => {
            navigate(
                ROUTES.AUTH.LOGIN,
                socialLoginError?.message || JSON.stringify(socialLoginError),
            );
        }, 500);
    }, [navigate, socialLoginError]);

    /**
     * Social Login success handler
     */
    useEffect(() => {
        if (!socialLoginData) return;

        navigate(window.close());
    }, [navigate, socialLoginData]);

    /**
     * Redirect to home page when there is no Facebank Code present on the URL
     */

    if (!facebankCode) {
        const errorMsg =
            "Autenticação com Facebank não pôde ser realizada " +
            "em função da ausência de sua credencial na URL.";

        navigate(ROUTES.AUTH.LOGIN, errorMsg);
    }

    return (
        <Styled
            className="segment__content__limited"
            textAlign="center"
            styles={{
                paddingTop: 40,
                paddingBottom: 40,
            }}
        >
            <Styled>
                <Icon
                    size={64}
                    color={hasError ? "error" : "helper"}
                    slug={hasError ? "times" : "loader"}
                />
            </Styled>
            <Styled
                fontWeight="500"
                padding="15px 0 0"
                textAlign="center"
                textColor={hasError ? "error" : "helper"}
            >
                {hasError
                    ? "Autenticação com Facebank falhou."
                    : "Autenticando com Facebank..."}
            </Styled>
        </Styled>
    );
}
