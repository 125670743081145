import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

/* Constants */
import {
    routesConstants as ROUTES,
    userConstants as USER,
} from "../_constants";

/* Service */
import { flowService, settingsService } from "../_services";

/* Actions */
import { authActions } from "../_actions";

/* Base Component */
import PublicRoute from "./PublicRoute.jsx";
import isFromIngresseDomain from "../_utils/isFromIngresseDomain";
import { removeAllCookies } from "../_utils/removeAllCookies.js";

/* Component */
class PrivateRoute extends React.Component {
    /**
     * Constructor
     *
     * @param {object} props - component props
     */
    constructor(props) {
        super(props);

        this.props = props;
        this.settings = settingsService.get();
        removeAllCookies();
    }

    /* Render */
    render() {
        const { auth, user, trusted, oauth } = this.props;
        const companies = auth.companies || auth.company;
        const company = auth.company;
        const waiting = auth.waiting;
        const { data } = user || {};
        const credentials = auth.data || null;
        const loggedNow = auth.loggedNow;
        const companyId = {id: auth?.data?.companyId || auth?.company?.id};
        const neeedConfirmMfa = auth?.company?.device?.mfa ?? false;
        const needRegisterMfa = (auth?.company?.device?.mfaRequired ?? false) && !neeedConfirmMfa;
        const dontShowTrusted = neeedConfirmMfa || needRegisterMfa;
        const simpleRegistration = flowService.getParam("simpleRegistration");
        const deviceVerified = (
            (auth?.data?.device?.verified ?? false) ||
            (auth?.company?.device?.verified ?? false) ||
            (trusted?.verified ?? false)
        );
        const notCompanyInCompanyRoute = (
            !companies &&
            this.path === ROUTES.PRIVATE.COMPANIES
        );
        const needDeviceValidation = (this.settings.APP.DEVICE_VALIDATION ?? false) &&
            !deviceVerified && companyId.id === 1 && isFromIngresseDomain();

        const isOauthActive = oauth?.isActive ?? false;
        const isOauthLogged = oauth?.loggedIn ?? false;
        const isOauthAuthorized = oauth?.redirectUrl ?? false;

        if (notCompanyInCompanyRoute) {
            authActions.logout();

            return <Redirect to={this.settings.INDEX + flowService.getSearch()} />
        }

        // Needs update
        if (
            data &&
            this.props.path !== ROUTES.AUTH.INCOMPLETE &&
            this.props.path !== ROUTES.PRIVATE.USER_CONFIRM
        ) {
            const formValidation = simpleRegistration ? USER.SIMPLE_FORM_REQUIRED : USER.REQUIRED;
            const needsUpdate = formValidation.some((REQUIRED_PROP) => {
                if (REQUIRED_PROP === "document") {
                    return !data.document || !data.document.number;
                }

                return !data[REQUIRED_PROP];
            });

            if (needsUpdate) {
                if (this.props.path === ROUTES.AUTH.INCOMPLETE) {
                    return <PublicRoute {...this.props} />;
                }

                return <Redirect to={ROUTES.AUTH.INCOMPLETE + flowService.getSearch()} />
            }
        }

        if (this.props.path === ROUTES.PRIVATE.DONE && !loggedNow && credentials?.token) {
            return <Redirect to={ROUTES.PRIVATE.USER_CONFIRM + flowService.getSearch()} />
        }

        // Authentication Done, after select a Company from 'companyLogin'
        if (companies && waiting) {
            if (needRegisterMfa && this.props.path !== ROUTES.AUTH.REGISTER_TWOFA) {
                return <Redirect to={ROUTES.AUTH.REGISTER_TWOFA + flowService.getSearch()} />
            }

            if (neeedConfirmMfa && this.props.path !== ROUTES.AUTH.TWOFA && !company.authToken) {
                return <Redirect to={ROUTES.AUTH.TWOFA + flowService.getSearch()} />;
            }

            if (needDeviceValidation) {
                if (!dontShowTrusted && this.props.path !== ROUTES.PRIVATE.TRUSTED) {
                    return <Redirect to={ROUTES.PRIVATE.TRUSTED + flowService.getSearch()} />
                }
            }

            if (!needDeviceValidation && this.props.path !== ROUTES.PRIVATE.DONE && company.authToken) {
                return (
                    <Redirect to={ROUTES.PRIVATE.DONE + flowService.getSearch()}/>
                );
            }

            return <PublicRoute {...this.props} />;
        }

        if (isOauthActive) {
            if (!isOauthLogged) {
                return <Redirect to={ROUTES.OAUTH.LOGIN} />;
            }

            if (isOauthLogged && isOauthAuthorized) {
                window.location.href = oauth.redirectUrl;
            }

            return <PublicRoute {...this.props} />;
        }

        if ((credentials && credentials.userId) || companies) {
            return <PublicRoute {...this.props} />;
        }

        return <Redirect to={ROUTES.AUTH.LOGIN + flowService.getSearch()} />;
    }
}

/* Map State to Props */
function mapStateToProps(state) {
    return state;
}

/* Export Component */
export default connect(mapStateToProps)(PrivateRoute);
