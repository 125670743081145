import React from "react";

import AuthDivider from "./components/AuthDivider";
import AuthFacebankButton from "./components/AuthFacebankButton";
import AuthFacebookButton from "./components/AuthFacebookButton";
import AuthFormLogin from "./components/AuthFormLogin";
import AuthLocaleSelect from "./components/AuthLocaleSelect/index";

const isBackoffice = window.location.href.includes("backoffice");

const AuthLogin = (
    <div className="segment__content__limited">
        {isBackoffice ? null : <AuthLocaleSelect />}
        <AuthFormLogin />
        <AuthDivider className="aph m-20-top m-30-bot" />
        <AuthFacebookButton className="aph m-15-top" buttonType="login" />
        <AuthFacebankButton margin="15px 0 0" />
    </div>
);

export default AuthLogin;
