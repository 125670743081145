import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { useLanguage } from "../scripts/_hooks/useLanguage";

import ptBR from "./locales/pt-BR.json";
import esES from "./locales/es-ES.json";
import enUS from "./locales/en-US.json";

const resources = {
    "pt-BR": {
        translation: ptBR,
    },
    "es-ES": {
        translation: esES,
    },
    "en-US": {
        translation: enUS,
    },
};
    
i18n.use(initReactI18next).init({
    resources,
    lng: useLanguage(),
    fallbackLng: "pt-BR",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
