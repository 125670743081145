/* Trusted Constants */
import { TRUSTED } from "../_constants";

/* trusted Actions */
export const trustedActions = {
    /**
     * Set device as trusted
     *
     * @param {boolean} isTrusted
     */
    set: (isTrusted) => {
        return (dispatch) => {
            dispatch({
                type: TRUSTED.SET,
                data: isTrusted,
            });
        };
    },

    /**
     * Set Device
     *
     * @param {object} device
     */
    setDevice: (device) => {
        return (dispatch) => {
            dispatch({
                type: TRUSTED.SET_DEVICE,
                data: device,
            });
        };
    },
};
