import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { toast, Icon, COLORS } from "aphrodite-react";
import { withRouter } from "react-router-dom";
import { authActions, userActions } from "../_actions";
/* Constants */
import {
    routesConstants as ROUTES,
    urlsConstants as URLS,
    messagesConstants as MESSAGES,
} from "../_constants";

import {
    postMessageService as postMessages,
    trustedService,
    postMessageService,
    settingsService,
} from "../_services";
import * as env from '../_utils/env';
import checkApiKeyCustomer from "../_utils/checkApiKeyCustomer";

const AuthTwoFA = (props) => {
    /**
     * Context values
     */
    const { dispatch, trusted, user, history, location, company, auth } = props;
    const { device } = trusted || {};
    const { search } = location || {};
    const [otpValue, setOtpValue] = useState('');
    const [loading, setLoading] = useState(false);
    const isFromIngresseDomain = useSelector(state => state.auth.isFromIngresseDomain);
    const userData = useSelector(state => state.user.data);
    const { t } = useTranslation();

    const toastErrorConfigs = { autoClose: 10000 };

    const handleChangeOtp = (e) => {
        const { value } = e.target;

        if (value.length <= 6) setOtpValue(value);
    }

    async function validateOTP(e) {
        e.preventDefault();
        if (!user || !device) {
            return;
        }

        setLoading(true);
        const expectedEnv = env.name() ? `${env.name()}-` : '';
        const baseUrl = 'api.ingresse.com';
        const path = '/mfa/verify'
        const queryParams = () => {
            const expectedToken = company ? company?.token : auth?.data?.token;
            let apiKey = checkApiKeyCustomer()

            if (settingsService.get().companyLogin && company?.application?.apiKey) {
                apiKey = company?.application?.apiKey;
            }

            const isMiranteBackstage = window.location.href.includes('companyId=31');

            if (isMiranteBackstage) {
                apiKey = 'tDgFYzwDkGVTxWeAgQxs73Hrs74CaNn2';
            }

            return `?apikey=${apiKey}&usertoken=${expectedToken}`;
        }

        const expectedParams = queryParams();
        const objectDevice = {...device, device_type: "web"};

        try {
            const response = await fetch(`https://${expectedEnv}${baseUrl}${path}${expectedParams}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-INGRESSE-DEVICE": JSON.stringify(objectDevice),
                    "X-INGRESSE-OTP": otpValue,
                }
            });

            const responseData = await response.json();

            if (!response.ok) throw {...responseData};

            const { responseData: data } = responseData;
            const { device } = data;
            const deviceVerified = device && device.verified ? device.verified : false;
            trustedService.saveAsValid(deviceVerified);

            dispatch(authActions.setCredentials(data.data));

            done({
                ...userData,
                authToken: data.data.authToken,
                token: data.data.token,
            });
        } catch (error) {
            const {code} = error.responseError;
            const errorMessage = MESSAGES.API.ERROR_CODES[code];

            toast.error(
                errorMessage,
                toastErrorConfigs,
            );
        } finally {
            setLoading(false);
        }
    }

    /**
      * Redirect to route done
      */
    const done = useCallback(
        (newUserData) => {
            if (typeof user !== "object") {
                return;
            }

            const verified = newUserData.device?.verified || false;

            if (settingsService.get().companyLogin) {
                dispatch(authActions.selectCompany({
                    ...company,
                    authToken: newUserData.authToken,
                    token: newUserData.token,
                }))
            }

            dispatch(
                userActions.setData({
                    ...newUserData
                })
            );
            dispatch(authActions.confirm());
            trustedService.saveAsValid(verified);

            postMessageService.trusted(verified, newUserData.device || device);
            if (settingsService.get().companyLogin) {
                postMessageService.authDone({
                    ...props.company,
                    authToken: newUserData.authToken,
                    token: newUserData.token,
                }, [{
                    ...props.company,
                    authToken: newUserData.authToken,
                    token: newUserData.token,
                }]);

            } else {
                postMessages.authSuccess(newUserData);
            }

            history.push(ROUTES.PRIVATE.DONE.concat(search || ""));
        },
        [device, dispatch, history, search, user, userData]
    );

    return (
        <form
            className="aph form"
            noValidate={true}
            onSubmit={validateOTP}
        >
            <div className="segment__content__limited">
                <div className="aph text-center">
                    <div className="aph p-5-top m-5-bot">
                        <h3 className="aph text-dark-grey m-0">
                            {t("AUTH.TWO_FA.TITLE")}
                        </h3>
                    </div>
                    <div className="aph p-5-top m-5-bot">
                        <div className="aph text-grey p-10-ver">
                            {t("AUTH.TWO_FA.DESCRIPTION")}
                        </div>
                    </div>
                </div>
                <div className="aph m-10-bot">
                    <label className="aph form__label" htmlFor="otp">
                        {t("AUTH.TWO_FA.LABEL")}
                    </label>
                    <input
                        className="aph form__control"
                        type="text"
                        id="otp"
                        disabled={loading}
                        value={otpValue}
                        onChange={handleChangeOtp}
                    />
                </div>
                <div
                    className={`aph m-20-too`}
                >
                    <button
                        className={`aph btn btn--block btn--primary ${!isFromIngresseDomain && "custom_mrt_button_submit"
                            }`}
                        type="buttton"
                        id="btnLoginSubmit"
                        disabled={otpValue.length < 6}
                    >
                        {loading ? (
                            <Icon
                                slug="loading"
                                width={20}
                                height={20}
                                color={COLORS.DARK_GREY}
                            />
                        ) : (
                            'Verificar código'
                        )}
                    </button>
                </div>
                <div className="aph text-center">
                    <div className="aph p-0-ver p-30-top">
                        {isFromIngresseDomain && (
                            <div className="aph text-grey">
                                {t("AUTH.TWO_FA.INFO")}
                                <span className="aph text-blue">
                                    <a
                                        className="aph btn--block btn--link"
                                        style={{
                                            paddingLeft: 4,
                                            height: 21,
                                            display: "inline",
                                        }}
                                        id="btnRecoverHelp"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={URLS.PASSWORD.FRESHDESK}
                                    >
                                        {t("PASSWORD.RECOVER.FRESHDESK")}
                                    </a>
                                </span>

                            </div>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};
/**
 * Map context information as Component props
 *
 * @param {object} state - context current state
 */
function mapStateToProps(state) {
    const { data } = state.auth;
    const { userId } = data || {};

    return {
        user: state.user && state.user.data ? state.user.data : null,
        userId: userId || "",
        trusted: state.trusted,
        company: state.auth.company,
        auth: state.auth,
    };
}
export default connect(mapStateToProps)(withRouter(AuthTwoFA));
