/* Package Helpers */
import Fingerprint2 from "fingerprintjs2";

/* Services */
import { ingresseService } from "./";

/* Actions */
import { trustedActions } from "../_actions";

/* Filters */
import { messagesFilter } from "../_filters";

/* Constants */
import { TRUSTED } from "../_constants";

/* Post Messages */
import { postMessageService } from "../_services";

import { cookiesUtils } from "../_utils";
import * as env from '../_utils/env';

/**
 * Save device settings.
 * A cookie registered to make easier to check.
 *
 * @param {object} device
 */
const setDevice = (device) => {
    const cookieName = TRUSTED.PREFIX.concat("device");
    const registered = {
        ...device,
        device_address: JSON.stringify({
            latitude: "-25.4950304",
            longitude: "-49.2231688",
        }),
    };

    cookiesUtils.set(cookieName, JSON.stringify(registered), 365, true);

    return registered;
};

/**
 * Check if was previously saved the device settings
 */
const getDevice = () => {
    const cookieName = TRUSTED.PREFIX.concat("device");

    const cookieValue = cookiesUtils.get(cookieName);

    if (cookieValue) {
        return JSON.parse(cookieValue);
    }

    return null;
};

/**
 * Initialize Trusted Service
 */
const init = () => {
    return (dispatch) => {
        /**
         * Generate Browser Fingerprint Callback
         */
        function generateFingerprint() {
            Fingerprint2.getPromise().then((components) => {
                const deviceSettings = components.map((component) => {
                    return component.value;
                });
                const existentDevice = getDevice();

                if (existentDevice && existentDevice.id) {
                    return dispatch(trustedActions.setDevice(existentDevice));
                }

                const cachedDevice = setDevice({
                    id: Fingerprint2.x64hash128(deviceSettings.join(""), 31),
                    name: (
                        window.navigator.platform +
                        " " +
                        window.navigator.vendor +
                        " " +
                        window.navigator.userAgent
                    ).replace(/[;/.,]/g, ""),
                });

                dispatch(trustedActions.setDevice(cachedDevice));
            });
        }

        if (window.requestIdleCallback) {
            window.requestIdleCallback(generateFingerprint, { timeout: 600 });

            return;
        }

        setTimeout(generateFingerprint, 500);
    };
};

/**
 * Get Session's User ID
 *
 * @returns {string} Session's User ID
 */
const _getUserID = () => {
    const credentials = ingresseService.api()?.getCredentials();
    return credentials?.userId || "";
};

/**
 * Save as validated trusted device.
 * A cookie registered to make easier to check.
 */
const saveAsValid = (userId = "") => {
    const cookieName = TRUSTED.PREFIX.concat(_getUserID() || userId);

    cookiesUtils.set(cookieName, true, 365, true);
};

/**
 * Remove as validated trusted device.
 * A cookie unregistered to make easier to check.
 */
const removeAsValid = (userId = "") => {
    const cookieName = TRUSTED.PREFIX.concat(_getUserID() || userId);

    cookiesUtils.remove(cookieName);
};

/**
 * Check if was previously validated as trusted device
 */
const wasValidated = () => {
    const cookieName = TRUSTED.PREFIX.concat(_getUserID());
    return !!cookiesUtils.get(cookieName);
};

/**
 * Send a verification code, through SMS,
 * to validate the current device as trusted
 *
 * @param {object} device - device settings, generated by `init`
 * @param {object} body
 *
 * @returns {Promise}
 */
const requestOTP = (device = null, body = {}, query = {}) => {
    return new Promise((resolve, reject) => {
        if (!device) {
            return reject("TRUSTED:SEND_SMS:MISSING_PARAMS");
        }

        const api = ingresseService.api();
        const { headers, ...rest } = query;

        api.post("/two-factor", body, rest, headers)
            .catch((error) => {
                const { responseError } = error || {};
                const { code } = responseError || {};

                if (code === 2057) {
                    return resolve();
                }

                if (error.message === "API rate limit exceeded") {
                    error = {
                        ...error,
                        responseError: {
                            code: 429,
                        },
                    };
                }

                messagesFilter.api.success(error).catch(reject).then(resolve);
            })
            .then((response) => {
                const { responseError } = response || {};
                const { code } = responseError || {};

                localStorage.setItem("errorCode", code);

                if (code === 1192) {
                    postMessageService.support();
                }

                messagesFilter.api
                    .success(response)
                    .catch(reject)
                    .then(resolve);
            });
    });
};

/**
 * Verify the OTP, received through SMS,
 * to validate the current device as trusted
 *
 * @param {object} device - browser hash, generated by `init`
 * @param {string} otp    - one time password, received through SMS
 *
 * @returns {Promise}
 */
const validateOTP = (device = null, otp = "", body = {}, query = {}) => {
    /* Get to cookies the jwt to use on method GET */
    let jwtCookies = window.Cookies.get(env.cookie("ing_1_jwt"));

    const objectDevice = { ...device, device_type: "web" };

    const newHeaders = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-INGRESSE-DEVICE": JSON.stringify(objectDevice),
        Authorization: `Bearer ${jwtCookies}`,
        "X-INGRESSE-OTP": otp,
    };

    const { headers, ...rest } = query;

    return new Promise((resolve, reject) => {
        if (!device || !otp) {
            return reject("TRUSTED:VALIDATE:MISSING_PARAMS");
        }

        const api = ingresseService.api();

        api.post("/two-factor", body, rest, newHeaders)
            .catch((error) => {
                messagesFilter.api.success(error).catch(reject).then(resolve);
            })
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .catch(reject)
                    .then((responseFiltered) => {
                        if (!responseFiltered) {
                            return reject();
                        }
                        const { device } = responseFiltered.data;
                        const deviceVerified =
                            device && device.verified ? device.verified : false;

                        saveAsValid(deviceVerified);
                        resolve(responseFiltered);
                    });
            });
    });
};

/*
const reloadDevice = (device = null, body = {}, query = {}) => {
    const jwtCookies = window.Cookies.get(env.cookie("ing_1_jwt"));
    const url = `${env.service('devices')}/api/users/${_getUserID()}/devices/device-id`;

    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwtCookies,
        },
    });
}
*/

/* Exporting Service methods */
export const trustedService = {
    init,
    saveAsValid,
    removeAsValid,
    wasValidated,
    requestOTP,
    validateOTP,
    // reloadDevice,
};
