/* Packages */
import React, { useEffect, useRef, useState } from "react";

/* Constants */
import { PASSWORD } from "../../_constants";

/* Framework Helpers */
import { COLORS, Icon, ProgressBar } from "aphrodite-react";
import PasswordHints from "../../_components/PasswordHints";

/* Icons */
import IconEye from "../../_components/svg/EyeOpened.jsx";
import IconEyeActive from "../../_components/svg/EyeOpenedActive.jsx";

/* Services */
import { useSelector } from "react-redux";
import { passwordService } from "../../_services";
import { useTranslation } from "react-i18next";
import { cookiesUtils } from "../../_utils/cookies.utils.js";

/* Component Itself */
function AuthPasswordValidate(props) {
    const {
        label,
        autoFocus,
        submitted,
        placeholder,
        showProgressbar,
        invalidPassword,
        setPasswordHasRequested,
    } = props;

    const { t } = useTranslation();
    const labelsPassword = t("AUTH.LABELS.PASSWORD");
    const [password, setPassword] = useState(props.password || "");
    const [passwordType, setPasswordType] = useState("password");

    const [score, setScore] = useState(-1);

    const [barSize, setBarSize] = useState("");
    const [barColor, setBarColor] = useState("");
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(null);
    const [promises, setPromises] = useState([]);

    const { isFromIngresseDomain } = useSelector((state) => state.auth);

    const passwordRef = useRef(null);

    /**
     * Toggle input password element type
     */
    const togglePasswordType = () => {
        setPasswordType(passwordType !== "password" ? "password" : "text");
    };

    /**
     * Handle with changes
     *
     * @param {object} evt
     */
    const validatePassword = (evt) => {
        const { target } = evt;
        const { value } = target;

        clearTimeout(timer);
        setPassword(value);

        setTimer(
            setTimeout(() => {
                setLoading(true);
                setPasswordHasRequested(true);
                promises.push(passwordService.passwordStrength(value));

                Promise.all(promises)
                    .then((results) => {
                        const strenghtResponse = results.slice(-1).pop();

                        if (strenghtResponse.password !== value) {
                            return;
                        }

                        setScore(strenghtResponse.score);
                        validateBarColor(
                            strenghtResponse.score,
                            strenghtResponse.password
                        );

                        if (strenghtResponse.score < PASSWORD.MIN_SCORE) {
                            return;
                        }

                        setPasswordHasRequested(false);
                    })
                    .catch(() => {
                        setScore(-1);
                        validateBarColor(-1, value);
                    })
                    .finally(() => {
                        setLoading(false);
                        setPromises([]);
                    });
            }, 100)
        );
    };

    /**
     * Validate progressbar color on password changes
     *
     * @param {number} score
     */
    const validateBarColor = (updatedScore, updatedPassword) => {
        const { handleCallback } = props;
        const _valid = typeof updatedScore === "number";
        const _score = _valid ? updatedScore : 0;

        switch (_score) {
            case -1:
                setBarSize("");
                handleCallback(true, updatedPassword);

                break;

            case 0:
            case 1:
                setBarSize("50px");
                setBarColor(COLORS.ERROR);
                handleCallback(true, updatedPassword);

                break;

            case 2:
                setBarSize("150px");
                setBarColor(COLORS.ORANGE);
                handleCallback(false, updatedPassword);

                break;

            case 3:
            case 4:
                setBarSize("100%");
                setBarColor(COLORS.SUCCESS);
                handleCallback(false, updatedPassword);

                break;

            default:
                setBarSize("30%");
                setBarColor(COLORS.ORANGE_RED);
                handleCallback(true, updatedPassword);
        }
    };

    return (
        <>
            <label className="aph form__label" htmlFor="password">
                {labelsPassword} <span className="text-red">*</span>
            </label>
            <div className="aph form__control-with-icon">
                <input
                    ref={passwordRef}
                    style={{ borderRadius: "4px 4px 2px 2px" }}
                    className={`aph form__control ${
                        !isFromIngresseDomain && "custom_mrt_input"
                    }`}
                    type={passwordType}
                    id="password"
                    name="password"
                    autoFocus={autoFocus}
                    required={true}
                    disabled={submitted}
                    onChange={validatePassword}
                    value={password}
                    placeholder={placeholder}
                    autoComplete={passwordType === "text" ? "off" : ""}
                />
                <button
                    className="aph form__control-with-icon__button"
                    type="button"
                    id="btnPasswordToggle"
                    onClick={togglePasswordType}
                >
                    {loading ? (
                        <Icon
                            slug="loading"
                            width={20}
                            height={20}
                            color={COLORS.DARK_GREY}
                        />
                    ) : passwordType === "password" ? (
                        <IconEye />
                    ) : (
                        <IconEyeActive />
                    )}
                </button>
                {showProgressbar && (
                    <ProgressBar
                        wrapperStyles={{
                            marginTop: "-2px",
                            borderRadius: "0 0 2px 2px",
                        }}
                        styles={{
                            borderRadius: "0 0 2px 2px",
                            transition: "none",
                        }}
                        color={barColor}
                        size={barSize}
                        height={2}
                        width={460}
                    />
                )}
            </div>
            <div
                className={
                    "aph m-10-top text-" +
                    (password && invalidPassword && score < PASSWORD.MIN_SCORE
                        ? "red"
                        : "dark-grey")
                }
                style={{ fontSize: "16px" }}
            >
                {t("VALIDATIONS.PASSWORD")}
            </div>
            <PasswordHints delay={550} score={score} />
        </>
    );
}

AuthPasswordValidate.defaultProps = {
    autoFocus: false,
    submitted: false,
    placeholder: "",
    showProgressbar: true,
    invalidPassword: false,

    setPasswordHasRequested: () => null,
};

export default AuthPasswordValidate;
