/* Services */
import {
    authService,
    postMessageService,
    settingsService,
    trackersService,
    userService,
} from "../_services";

/* Actions */
import { oauthActions, userActions } from "./";

/* Constants */
import { authConstants as AUTH } from "../_constants";

/**
 * Login Success
 *
 * @param {object} data - user data
 */
const successLogin = (data) => {
    postMessageService.authLoginSuccess();

    return {
        type: AUTH.LOGIN_SUCCESS,
        data: data,
    };
};

const isIngresseDomain = (isIngresse) => {
    return {
        type: AUTH.OUT_INGRESSE_DOMAIN,
        payload: isIngresse,
    };
};

/**
 * Register or Update Failure
 */
const resetRegisterError = () => {
    return {
        type: AUTH.REGISTER_FAILURE_CLEAN,
        error: "",
    };
};

/**
 * Login
 *
 * @param {string} email
 * @param {string} password
 * @param {object} device
 * @param {boolean} rememberMe - optional
 * @param {object} optionalData - optional
 *
 * @return {function} dispatch
 */
const login = (email, password, device, rememberMe, optionalData = {}) => {
    /**
     * Login Failure
     *
     * @param {object} error
     */
    const failure = (error) => {
        postMessageService.authLoginFailure();

        return {
            type: AUTH.LOGIN_FAILURE,
            error: error,
        };
    };

    return (dispatch) => {
        authService
            .login(email, password, device, settingsService.get().companyLogin)
            .then(
                (response) => {
                    if (response.status && response.data["0"]) {
                        const { userId, token } = response.data["0"];
                        userService
                            .getById(userId, token)
                            .then((userResponse) => {
                                const user = userResponse;
                                if (user) {
                                    const resolved = Object.assign(user);

                                    dispatch(successLogin(resolved));
                                    dispatch(userActions.setData(resolved));
                                }
                            });

                        dispatch({
                            type: AUTH.LOGIN_COMPANY_SUCCESS,
                            companies: response.data,
                            rememberMe: rememberMe ? true : false,
                        });
                        return;
                    }

                    userService.getData().then(
                        (userData) => {
                            const resolved = Object.assign(
                                {},
                                response.data,
                                userData,
                                optionalData
                            );

                            if (optionalData.isPasswordReset) {
                                trackersService.password.reset(userData.id);
                            }

                            dispatch(successLogin(resolved));

                            dispatch(userActions.setData(resolved));
                        },
                        (error) => {
                            dispatch(failure(error));
                        }
                    );
                },
                (error) => {
                    dispatch(failure(error));
                }
            );
    };
};

/**
 * Facebook login
 *
 * @param {object} userData
 *
 * @return {function} dispatch
 */
const fbLogin = (userData) => {
    return (dispatch) => {
        if (!userData || typeof userData !== "object") {
            dispatch({
                type: AUTH.LOGIN_FAILURE,
                error: "auth:login-failure:no-user-data",
            });

            return;
        }

        dispatch({
            type: AUTH.LOGIN_SUCCESS,
            data: userData,
        });
    };
};

/**
 * Facebook Register
 *
 * @param {object} userData
 *
 * @return {function} dispatch
 */
const fbRegister = (userData) => {
    return (dispatch) => {
        dispatch({
            type: AUTH.FACEBOOK_REGISTER,
            data: userData,
        });
    };
};

/**
 * Logout
 *
 * @return {function} dispatch
 */
const logout = () => {
    authService.logout();
    userService.clearData();
    postMessageService.authLogout();

    return (dispatch) => {
        dispatch(oauthActions.clearCredentials());
        dispatch({
            type: AUTH.LOGOUT,
        });
    };
};

/**
 * Register
 *
 * @param {object}  registerData
 * @param {object}  device
 * @param {boolean} shouldLogIn
 *
 * @return {function} dispatch
 */
const register = (registerData, device, shouldLogIn = true) => {
    /**
     * Dispatch
     */
    return (dispatch) => {
        dispatch(resetRegisterError());
        authService
            .register({
                ...registerData,
                token: registerData.inactiveToken,
            })
            .then(
                (response) => {
                    const { type } = response.data || {};
                    const toContext = Object.assign(
                        {},
                        response.data,
                        registerData
                    );

                    const newContext = toContext.document
                        ? Object.assign({}, toContext, {
                              document: { number: toContext.document },
                              password: null,
                          })
                        : toContext;

                    if (
                        shouldLogIn &&
                        registerData.email &&
                        registerData.password &&
                        type !== "incomplete"
                    ) {
                        setTimeout(() => {
                            if (settingsService.get().flowId) {
                                dispatch(
                                    oauthActions.login(
                                        registerData.email,
                                        registerData.password,
                                        settingsService.get().flowId
                                    )
                                );
                            } else {
                                dispatch(
                                    login(
                                        registerData.email,
                                        registerData.password,
                                        device,
                                        true,
                                        newContext
                                    )
                                );
                            }
                        }, 500);
                    } else {
                        dispatch(userActions.setData(newContext));
                    }

                    postMessageService.authRegisterSuccess();
                },
                (error) => {
                    dispatch({ type: AUTH.REGISTER_FAILURE, error });
                }
            );
    };
};

/**
 * Register
 *
 * @param {object}  registerData
 * @param {object}  device
 * @param {boolean} shouldLogIn
 *
 * @return {function} dispatch
 */
const update = (updateData, userData) => {
    /**
     * Dispatch
     */
    return (dispatch) => {
        dispatch(resetRegisterError());
        authService.update(userData.id, updateData).then(
            (response) => {
                const toContext = Object.assign(
                    {},
                    userData,
                    response.data,
                    updateData
                );

                dispatch(successLogin(toContext));

                const newContext = toContext.document
                    ? Object.assign({}, toContext, {
                          document: { number: toContext.document },
                      })
                    : toContext;

                dispatch(userActions.setData(newContext));
            },
            (error) => {
                dispatch({ type: AUTH.UPDATE_FAILURE, error });
            }
        );
    };
};

/**
 * Get Credentials
 *
 * @return {object} credentials
 */
const getCredentials = () => {
    return (dispatch) => {
        const credentials = authService.getCredentials();

        dispatch({
            type: AUTH.GET_CREDENTIALS,
            credentials,
        });
    };
};

/**
 * Set Credentials
 *
 * @param {object} credentials
 *
 * @return {object} credentials
 */
const setCredentials = (credentials) => {
    authService.setCredentials(credentials);

    return (dispatch) => {
        dispatch({
            type: AUTH.SET_CREDENTIALS,
            credentials,
        });
    };
};

/**
 * Select Company
 *
 * @param {object} company
 * @param {array}  companies - optional
 *
 * @return {function} dispatch
 */
const selectCompany = (company, companies = []) => {
    postMessageService.authCompanyLoginSuccess(company);
    // postMessageService.authDone(company, companies);

    return (dispatch) => {
        dispatch({
            type: AUTH.COMPANY_SELECTED,
            company: company,
        });
    };
};

/**
 * Confirm
 *
 * @return {function} dispatch
 */
const confirm = () => {
    postMessageService.authConfirm();

    return (dispatch) => {
        dispatch({
            type: AUTH.CONFIRM,
        });
    };
};

/**
 * Social Login Success
 *
 * @param {object} data - user data
 */
const socialSuccess = (data) => {
    postMessageService.authLoginSuccess();

    return {
        type: AUTH.LOGIN_SUCCESS,
        data,
    };
};

/**
 * Social Login Error
 *
 * @param {object} error - login error
 */
const socialError = (error) => {
    postMessageService.authLoginFailure();

    return {
        type: AUTH.LOGIN_FAILURE,
        error,
    };
};

/**
 * Clean error
 */
const cleanError = () => {
    return (dispatch) => {
        dispatch({
            type: AUTH.CLEAN_ERROR,
        });
    };
};

/* Export Redux Actions */
export const authActions = {
    login,
    fbLogin,
    fbRegister,
    logout,
    register,
    update,
    confirm,
    getCredentials,
    isIngresseDomain,
    setCredentials,
    selectCompany,
    socialSuccess,
    socialError,
    cleanError,
    successLogin,
};
