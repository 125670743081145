import { customerConstants } from "../_constants/customer.constants";
import checkApiKeyCustomer from "./checkApiKeyCustomer";

function isFromIngresseDomain() {
    const apiKeyMirante = "tDgFYzwDkGVTxWeAgQxs73Hrs74CaNn2";
    const apiKey = checkApiKeyCustomer();
    localStorage.setItem("companyHash", JSON.stringify(""));
    if (apiKey === apiKeyMirante) return false;

    if (!window.location?.ancestorOrigins) return true;
    const origins = Object.values(window.location.ancestorOrigins);
    if (origins.length === 0) return true;

    return origins.some((origin) => {
        const verify = !(
            origin.includes("nextsales") || origin.includes("backstagemirante")
        );
        if (!verify)
            localStorage.setItem(
                "companyHash",
                JSON.stringify(Object.keys(customerConstants)[0])
            );
        return verify;
    });
}

export default isFromIngresseDomain;
