import React from "react";

import { Styled } from "@ingresse/aphrodite";

import ingresseHorizontalLogo from "../../../assets/images/logo-ingresse.png";
import { useTranslation } from "react-i18next";

export default function DialogFacebank({ updateFrame }) {
    const { t } = useTranslation();
    return (
        <div style={Backshadow}>
            <div style={Customodal}>
                <div onClick={() => updateFrame()} style={Deleteicon}>
                    x
                </div>
                <Styled
                    as="img"
                    alt="INGRESSE"
                    src={ingresseHorizontalLogo}
                    styles={{
                        height: 50,
                        margin: "-12.5px -6px -12.5px 6px",
                    }}
                />

                <p style={Content}>
                    {t("MODAL.FACEBANK_DESCRIPTION")}
                    <br />
                    <br />
                    <button onClick={() => updateFrame()} style={Actionbutton}>
                        {t("MODAL.FACEBANK_BUTTON")}
                    </button>
                </p>
            </div>
        </div>
    );
}

const Backshadow = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.3)",
    justifyContent: "center",
    alignItems: "center",
};

const Customodal = {
    width: "300px",
    height: "260px",
    backgroundColor: "#fff",
    position: "relative",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
};

const Deleteicon = {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    backgroundColor: "#f8f8f8",
    color: "#7a8085",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
    position: "absolute",
    top: "-10px",
    right: "-10px",
    justifyContent: "center",
};

const Content = {
    fontSize: "15px",
    color: "#3a5795",
    textAlign: "center",
    margin: "25px",
};

const Actionbutton = {
    fontWeight: 400,
    color: "#f5fafc",
    backgroundColor: "#fca311",
    padding: "10px 60px",
    border: "none",
    borderRadius: "20px",
};
