import Cookies from "js-cookie";

import { ingresseService, settingsService } from "../_services";

/**
 * Concat cookie name
 *
 * @param {string} cname - Cookie name
 *
 * @return {string}
 */
const _concatName = (cname) => {
    const _api = ingresseService.api();

    let companyId = _api?.cookie?.companyId || null;

    if (!companyId) {
        const SETTINGS = settingsService.get();
        const { COMPANY = {} } = SETTINGS || {};
        companyId = COMPANY.ID;
    }

    return ["ing", "_", companyId, "_", cname || ""].join("");
};

const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

const set = (name, value, days, secure = false) => {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const domain = document.location.hostname.includes(".ingresse.com")
        ? ".ingresse.com"
        : document.location.hostname;

    Cookies.set(_concatName(name), value, {
        expires: date,
        domain,
        path: "/",
        secure,
        sameSite: "None",
    });

    if (isSafari()) {
        localStorage.setItem(_concatName(name), JSON.stringify(value));
    }
};

const get = (name) => {
    const getCookie = Cookies.get(_concatName(name));

    if (getCookie) return getCookie;

    if (isSafari()) {
        const getData = localStorage.getItem(_concatName(name));
        return getData
            ? JSON.parse(getData)?.content || JSON.parse(getData)
            : null;
    }

    return null;
};

const remove = (name) => {
    const domain = document.location.hostname.includes(".ingresse.com")
        ? ".ingresse.com"
        : document.location.hostname;

    Cookies.remove(_concatName(name), {
        path: "/",
        domain,
    });

    localStorage.removeItem(_concatName(name));
};

/* Export as object with functions */
export const cookiesUtils = {
    set,
    get,
    remove,
    isSafari,
};
