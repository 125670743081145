import { ingresseService, trackersService } from "./";

/* Filter */
import { messagesFilter } from "../_filters";

/**
 * Login switch
 *
 * @param {string} email
 * @param {string} password
 * @param {string} flowId
 * @param {boolean} companyLogin - optional
 *
 * @return {Promise}
 */
const login = (email, password, flowId) => {
    return new Promise((resolve, reject) => {
        if (!email || !password || !flowId) {
            return reject("auth:login-no-email-password-or-flowId");
        }

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
        };

        ingresseService
            .api()
            .post("oauth/authorize", { email, password, flowId }, null, headers)
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .then((responseFiltered) => {
                        resolve(responseFiltered);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(messagesFilter.api.errorGeneric(error));
            });
        return;
    });
};

const consent = (flowId) => {
    return new Promise((resolve, reject) => {
        if (!flowId) {
            return reject("auth:consent-no-flowId");
        }

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
        };

        ingresseService
            .api()
            .post("oauth/authorize", { flowId, consent: true }, null, headers)
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .then((responseFiltered) => {
                        resolve(responseFiltered);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(messagesFilter.api.errorGeneric(error));
            });
    });
};

const getCredentials = (openidToken) => {
    return new Promise((resolve, reject) => {
        if (!openidToken) {
            return reject("auth:consent-no-openidToken");
        }

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
        };

        ingresseService
            .api()
            .post("oauth/sso", { openidToken }, null, headers)
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .then((responseFiltered) => {
                        resolve(responseFiltered);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(messagesFilter.api.errorGeneric(error));
            });
    });
};

const getUserData = (flowId) => {
    return new Promise((resolve, reject) => {
        if (!flowId) {
            return reject("auth:consent-no-flowId");
        }

        ingresseService
            .api()
            .get(`oauth/flow/${flowId}`)
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .then((responseFiltered) => {
                        resolve(responseFiltered);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(messagesFilter.api.errorGeneric(error));
            });
    });
};


/* Constant object to represent Service Functions */
export const oauthService = {
    login,
    consent,
    getCredentials,
    getUserData,
};
