/* Modules */
import moment from "moment";

/* Constant */
import { authConstants as AUTH } from "../_constants";

/* Service */
import { authService, userService } from "../_services";
import isFromIngresseDomain from "../_utils/isFromIngresseDomain";

/* Values */
let credentials = authService.getCredentials();
const dateFormat = "YYYY-MM-DD[ ]HH:mm:ss";
const initialDate = moment().format(dateFormat);
const initialState = {
    loggedIn: false,
    loggedNow: false,
    waiting: false,
    company: null,
    companies: null,
    data: null,
    error: null,
    isFromIngresseDomain: isFromIngresseDomain(),
};

/* Export Reducer */
const auth = (state = initialState, action) => {
    credentials = authService.getCredentials();

    switch (action.type) {
        case AUTH.OUT_INGRESSE_DOMAIN:
            return {
                ...state,
                isFromIngresseDomain: action.payload,
            };

        case AUTH.COMPANY_SELECTED:
            return {
                ...state,
                waiting: true,
                company: action.company,
            };

        case AUTH.CONFIRM:
            return {
                ...state,
                error: initialState.error,
                loggedNow: initialDate !== moment().format(dateFormat),
                waiting: true,
            };

        case AUTH.LOGIN_COMPANY_SUCCESS:
            return {
                ...state,
                error: initialState.error,
                loggedIn: false,
                loggedNow: initialDate !== moment().format(dateFormat),
                companies: action.companies,
                rememberMe: action.rememberMe,
            };

        case AUTH.LOGIN_SUCCESS:
            return {
                ...state,
                error: initialState.error,
                loggedIn: true,
                loggedNow: initialDate !== moment().format(dateFormat),
                data: {
                    ...credentials,
                    ...action.credentials,
                    ...action.data,
                },
            };

        case AUTH.LOGIN_FAILURE:
            return {
                ...initialState,
                error: action.error,
            };

        case AUTH.REGISTER_FAILURE:
        case AUTH.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case AUTH.REGISTER_FAILURE_CLEAN:
            return {
                ...state,
                error: initialState.error,
            };

        case AUTH.FACEBOOK_REGISTER:
            return {
                ...state,
                facebook: action.data,
            };

        case AUTH.REGISTER_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                data: {
                    ...credentials,
                    ...action.credentials,
                    ...action.data,
                },
                companies: action.companies,
            };

        case AUTH.GET_CREDENTIALS:
            return {
                ...state,
                loggedIn: credentials ? true : false,
                data: credentials || null,
            };

        case AUTH.SET_CREDENTIALS:
            return {
                ...state,
                data: action.credentials,
            };

        case AUTH.LOGOUT:
            userService.clearData();

            return {
                ...initialState,
            };

        case AUTH.CLEAN_ERROR:
            return {
                ...state,

                error: null,
            };

        default:
            return state;
    }
};

export default auth;
