import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import posthog from "posthog-js";

import { toast } from "aphrodite-react";

import {
    facebookService,
    settingsService,
    classListService,
    flowService,
} from "../../_services";

import {
    messagesConstants as MSGS,
    routesConstants as ROUTES,
} from "../../_constants";

import { authActions, userActions } from "../../_actions";

class AuthFacebookButton extends React.Component {
    constructor(props) {
        super(props);

        this.settings = settingsService.get();

        this.props = props;
        this.state = {
            submitted: false,
            facebookAllowed: this.settings.APP.FACEBOOK ? true : false,
        };

        this.login = this.login.bind(this);
    }
    componentDidMount() {
        facebookService.init();
    }

    goAhead() {
        let destiny =
            flowService.getParam("returnUrl") || flowService.getParam("after");

        const { auth, history } = this.props;

        if (destiny) {
            if (auth && auth.token) {
                // Add 'userToken' as redirect param
                destiny +=
                    (destiny.includes("?") ? "&" : "?") +
                    ("usertoken=" + auth.token);
            }

            flowService.goTo(destiny);

            return;
        }

        history.push(ROUTES.PRIVATE.DONE + flowService.getSearch());
    }

    login() {
        posthog.capture("auth:auth_login_facebook_click");
        if (this.state.submitted) {
            return;
        }

        const { dispatch, trusted } = this.props;
        const { device } = trusted || {};

        this.setState(
            {
                connected: false,
                submitted: true,
            },
            () => {
                classListService.add();
                dispatch(userActions.setData(null));
            }
        );

        facebookService
            .login(device)
            .then((userResponse) => {
                dispatch(userActions.setData(userResponse));
                dispatch(authActions.fbLogin(userResponse));

                this.goAhead();
            })
            .catch((error) => {
                this.setState(
                    {
                        connected: false,
                        submitted: false,
                    },
                    () => {
                        if (error && error.code === -1) {
                            return;
                        }

                        const ERROR_MSG =
                            "O acesso com Facebook falhou. Tente novamente.";

                        if (error && error.code === 0) {
                            return toast.error(ERROR_MSG);
                        }

                        let errorMsg = MSGS.FACEBOOK.INTERNAL_ERROR_CODES[
                            error.code
                        ]
                            ? MSGS.FACEBOOK.INTERNAL_ERROR_CODES[error.code]
                            : ERROR_MSG;

                        toast.error(errorMsg);
                    }
                );
            })
            .finally(() => {
                setTimeout(() => {
                    classListService.remove();
                }, 500);
            });
    }

    getButtonText(buttonType) {
        switch (buttonType) {
            case "login":
                return this.props.t("AUTH.CHOICES.FACEBOOK.LOGIN");
            case "register":
                return this.props.t("AUTH.CHOICES.FACEBOOK.REGISTER");
            default:
                return "";
        }
    }

    render() {
        const { facebookAllowed, submitted, isIngresse } = this.state;
        const { connected, className, buttonType } = this.props;

        if (!facebookAllowed) {
            return null;
        }

        return (
            <div className={className || ""}>
                <button
                    className="aph btn btn--block btn--facebook"
                    type="button"
                    id={`btnAuthFacebook-${buttonType}`}
                    disabled={submitted || connected}
                    onClick={this.login}
                    style={!isIngresse && { fontSize: "0.8rem" }}
                >
                    <i className="icon icon--facebook margin-10-right"></i>
                    {connected ? "CONECTADO" : this.getButtonText(buttonType)}
                </button>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { data } = state.user;

    return {
        user: data || null,
        connected: data && data.fbUserId ? true : false,
        trusted: state.trusted,
    };
}

export default withTranslation()(
    connect(mapStateToProps)(withRouter(AuthFacebookButton))
);
