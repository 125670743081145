/* Packages */
import React from "react";
import { Styled } from "@ingresse/aphrodite";

export function Divider({ children, styles = {}, ...rest }) {
    return (
        <Styled
            {...rest}
            styles={{
                display: "flex",
                whiteSpace: "nowrap",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                color: "#999999",

                "&:before, &:after": {
                    content: '" "',
                    position: "relative",
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#DEDEDE",
                },

                ...styles,
            }}
        >
            <Styled as="span" padding="0 16px">
                {children}
            </Styled>
        </Styled>
    );
}
