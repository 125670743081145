/* Password Constants */
export const PASSWORD = {
    SET_EMAIL: "PASSWORD:SET_EMAIL",
    SET_HASH: "PASSWORD:SET_HASH",
    SET_ERROR: "PASSWORD:SET_ERROR",

    SENT: "PASSWORD:RECOVER_SENT",
    UPDATED: "PASSWORD:UPDATED",
    TRACK: "PASSWORD:TRACK",

    MIN_SCORE: 2,
    PASSWORD_STRENGTH: [
        {
            title: "Evite",
            hint: [
                "Palavras completas (Ex. tomate)",
                "Números sequenciais (Ex. 3456)",
                "Nomes ou datas de aniversário",
            ],
        },
        {
            title: "Use",
            hint: [
                "Letras maiúsculas e minúsculas",
                "Pelo menos um número",
                "Pelo menos um caractere especial",
            ],
        },
    ],
};
