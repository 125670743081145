import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { P, H3, colors } from "aphrodite-react";

import {
    routesConstants as ROUTES,
    textsConstants as TEXTS,
} from "../_constants";

import { authActions, userActions } from "../_actions";

import AuthFormRegister from "./components/AuthFormRegister";
import AuthFormIncomplete from "./components/AuthFormIncomplete";
import FlowLink from "../_components/FlowLink";
import AuthLocaleSelect from "./components/AuthLocaleSelect";

const AuthRegister = (props) => {
    const { t } = useTranslation();
    const { isUpdating, dispatch, isFromIngresseDomain } = props;

    const logout = () => {
        dispatch(authActions.logout());
        dispatch(userActions.setData());
    };

    const headerText = isUpdating
        ? t("AUTH.REGISTER.UPDATE.HEADER")
        : t("AUTH.REGISTER.HEADER");

    return (
        <div>
            <AuthLocaleSelect />
            <div className="aph m-5-bot">
                <H3
                    center
                    bold
                    margin="5px 0 5px"
                    style={{ color: isFromIngresseDomain ? "black" : "white" }}
                >
                    {isFromIngresseDomain
                        ? headerText
                        : headerText.replace("Ingresse", "Backstage Mirante")}
                </H3>
                {isUpdating && (
                    <P
                        styles={{ color: colors.get("mercury", "original") }}
                        center
                        margin="20px 0px"
                    >
                        {t("AUTH.REGISTER.UPDATE.SUBTITLE")}
                    </P>
                )}
                <P
                    styles={{ color: colors.get("mercury", "original") }}
                    center
                    margin="20px 0px 10px"
                >
                    {t("AUTH.REGISTER.INFO_1")}
                    <span className="text-red">*</span>
                    {t("AUTH.REGISTER.INFO_2")}
                </P>
            </div>
            <div
                className={`aph m-15-top segment__content__limited ${
                    isUpdating ? "m-20-bot" : "m-35-bot"
                }`}
            >
                {isUpdating ? <AuthFormIncomplete /> : <AuthFormRegister />}
            </div>
            {isUpdating ? null : (
                <div className="aph m-35-top m--5-bot text-center">
                    <h4 className="aph text-grey m-0">
                        {t("AUTH.REGISTER.ALREADY")}
                    </h4>
                    <FlowLink
                        className={`aph ${
                            !isFromIngresseDomain && "custom_mrt_text_link"
                        } btn btn--block btn--link`}
                        id="linkRegisterToLogin"
                        to={ROUTES.AUTH.LOGIN}
                        onClick={logout}
                    >
                        {isFromIngresseDomain
                            ? t("AUTH.CHOICES.LOGIN")
                            : TEXTS.AUTH.CHOICES.LOGIN.replace(
                                  "INGRESSE",
                                  "MIRANTE"
                              )}
                    </FlowLink>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    const {
        auth: { isFromIngresseDomain },
        user: { data },
    } = state;

    return {
        isUpdating: data && data.id ? true : false,
        isFromIngresseDomain,
    };
};

export default connect(mapStateToProps)(AuthRegister);
