/* Packages */
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { useLanguage } from "./_hooks/useLanguage";
import { PostHogProvider } from 'posthog-js/react';


// Internacionalization
import "../i18n/config";

/* Styles */
import "aphrodite-ui/dist/assets/aphrodite.css";
import "aphrodite-ui/dist/assets/aphrodite-font-family.css";
import "../assets/scss/app.scss";

/* App Router */
import AppRouter from "./router.jsx";

/* Redux Reducers */
import Reducers from "./_reducers/index.js";

/* Store Middlewares */
const middlewares = [thunkMiddleware];

/* URL Search Params API */
require("url-search-params-polyfill");

/* Development Logger */
if (process.env.NODE_ENV === "development") {
    const { logger } = require("redux-logger");

    middlewares.push(logger);
}

const currentLanguage = useLanguage();
const name = 'locale=';
const isBackstage = window.location.href.includes("backstage");
let   cookieValue = (document.cookie.split(';')
        .map(cookie => cookie.trim())
        .find(cookie => cookie.startsWith(name)) || '')
        .substring(name.length)
        .split('=')
        .pop();

if (isBackstage && cookieValue === "en-US") {
    document.cookie = `locale=${currentLanguage};domain=ingresse.com;path=/`;
}

/* Enhancers */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* Redux Store */
const store = createStore(
    Reducers,
    composeEnhancers(applyMiddleware(...middlewares))
);

const options = {
    api_host : process.env.REACT_APP_POSTHOG_HOST
};

/* App Render */
render(
    <Provider store={store}>
        <PostHogProvider apiKey={process.env.REACT_APP_POSTHOG_KEY} options={options}>
            <AppRouter />
        </PostHogProvider>
    </Provider>,
    document.getElementById("root")
);
