/* Service Bases */
import { ingresseService, trackersService, flowService } from "./";

/* Constants */
import { messagesConstants as MSGS } from "../_constants";

/* Filter */
import { messagesFilter } from "../_filters";

/* Utilities */
import { validators } from "../_utils";

/* Actions */
import { passwordActions } from "../_actions";
import { customerConstants } from "../_constants/customer.constants";

/**
 * Initializer
 */
const init = () => {
    return (dispatch) => {
        let email = flowService.getParam("email") || "";
        let hash = flowService.getParam("hash") || "";

        if (email && !validators.email(email)) {
            email = "";
        }

        dispatch(passwordActions.setEmail(email.toLowerCase()));
        dispatch(passwordActions.setHash(hash));
    };
};

/**
 * Request Password Recover
 *
 * @param {string} email
 *
 * @return promise;
 */
const recover = (email) => {
    return new Promise((resolve, reject) => {
        if (!email) {
            return reject("password:recover-no-email");
        }

        trackersService.password.recovery(email);

        const api = ingresseService.api();
        const companyHash = JSON.parse(localStorage.getItem("companyHash"));

        api.recoverPassword({ email, companyHash })
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .then((responseFiltered) => {
                        resolve(responseFiltered);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Request Password Strength
 *
 * @param {string} password
 *
 * @return promise;
 */
const passwordStrength = (password) => {
    return new Promise((resolve, reject) => {
        if (!password) {
            return reject("password:password-required");
        }

        if (password.length < 8) {
            return resolve({
                password: password,
                score: 1,
            });
        }

        const api = ingresseService.api();

        api.post("/password", { password })
            .catch(reject)
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .catch(reject)
                    .then((responseFiltered) => {
                        const { score } = responseFiltered || {};

                        resolve({
                            password: password,
                            score: score ? score.password : 0,
                        });
                    });
            });
    });
};

/**
 * Password Update
 *
 * @param {string} email
 * @param {string} hash
 * @param {string} password
 *
 * @return promise;
 */
const update = (email, hash, password) => {
    return new Promise((resolve, reject) => {
        if (!email || !hash || !password) {
            return reject("password:update-invalid-data");
        }

        const api = ingresseService.api();

        api.updatePassword({ email, hash, password })
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .then((responseFiltered) => {
                        resolve(responseFiltered);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Validate Password Recover Hash
 *
 * @param {string} email
 * @param {string} hash
 *
 * @return promise;
 */
const validateHash = (email, hash, companyHash) => {
    return new Promise((resolve, reject) => {
        if (!email || !hash) {
            return reject(MSGS.API.ERROR_CODES["2036"]);
        }

        if (companyHash && companyHash === Object.keys(customerConstants)[0]) {
            resolve(companyHash);
            return;
        }

        const api = ingresseService.api();

        api.validateHash({ email, hash })
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .then((responseFiltered) => {
                        resolve(responseFiltered);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/* Exporting Service */
export const passwordService = {
    init,
    recover,
    update,
    validateHash,
    passwordStrength,
};
