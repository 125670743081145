/* Service SDK Base */
import {
    request as requestSdk,
    credentials as sdkCredentials,
} from "@ingresse/sdk";
import date from "dayjs";
import decodeJWT from "jwt-decode";

import { ingresseService, trackersService, trustedService } from "./";

import { cookiesUtils } from "../_utils";

/* Filter */
import { messagesFilter } from "../_filters";

/* Get user logged credentials */
const getCredentials = () => {
    const isSafari = () => {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    };

    const _api = ingresseService.api();

    if (!_api) {
        return null;
    }
    let _credentials = _api.getCredentials() || {};
    let safariCredentials =
    JSON.parse(localStorage.getItem("credentials-safari")) || {};

    if (isSafari() && Object.keys(safariCredentials).length) {
        _api.auth.setToken(safariCredentials.token);
        return safariCredentials;
    }

    if (!_credentials || !Object.keys(_credentials).length) {
        return null;
    }

    _api.auth.setToken(_credentials.token);

    return _credentials;
};

/**
 * Set user credentials
 *
 * @param {object} newCredentials
 */
const setCredentials = (newCredentials) => {
    const isSafari = () => {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    };

    const _api = ingresseService.api();
    const { userId, token, authToken, device } = newCredentials || {};
    if (!_api || !userId || !token) {
        return;
    }

    clearCredentials(_api);
    _api.auth.setToken(token);
    _api.saveCredentials(userId, token, authToken);

    if (isSafari()) {
        localStorage.setItem("credentials-safari", JSON.stringify(newCredentials));
    }

    if (authToken) {
        cookiesUtils.set("userId", userId, 5, true);
        cookiesUtils.set("token", token, 5, true);
        cookiesUtils.set("jwt", authToken, 5, true);
    }

    if (device?.verified) {
        trustedService.saveAsValid(userId);
    }
};

/* Clear user logged credentials */
const clearCredentials = (api = null) => {
    const _api = api || ingresseService.api();

    if (!_api) {
        return;
    }

    _api.auth.setToken("");
    trustedService.removeAsValid();
    _api.clearCredentials();

    cookiesUtils.remove("userId");
    cookiesUtils.remove("token");
    cookiesUtils.remove("jwt");
    localStorage.removeItem("credentials-safari");
};

/**
 * Regular Login
 *
 * @param {string}   email
 * @param {string}   password
 * @param {function} resolve     - promise resolve
 * @param {function} reject      - promise reject
 * @param {object}   companyData - optional
 *
 * @return {Promise}
 */
const _login = (email, password, headers, resolve, reject) => {
    ingresseService
        .api()
        .post("/login", { email, password }, null, headers)
        .then((response) => {
            if (response.responseData.remainingAttempts >= 0) {
                sessionStorage.setItem(
                    "remainingAttempts",
                    response.responseData.remainingAttempts
                );
            } else {
                sessionStorage.removeItem("remainingAttempts");
            }
            messagesFilter.api
                .success(response)
                .then((responseFiltered) => {
                    const { userId } = responseFiltered.data;

                    trackersService.auth.login(email, userId);

                    setCredentials(responseFiltered.data);
                    resolve(responseFiltered);
                })
                .catch((error) => {
                    trackersService.auth.loginError(email, null);
                    reject(error);
                });
        })
        .catch((error) => {
            reject(messagesFilter.api.errorGeneric(error));
        });
};
/**
 * Company Login
 *
 * @param {string} email
 * @param {string} password
 *
 * @return {Promise}
 */
const _companyLogin = (email, password, headers, resolve, reject) => {
    ingresseService
        .api()
        .post("/company-login", { email, password }, null, headers)
        .then((response) => {
            if (response?.responseError?.fields?.remainingAttempts >= 0) {
                sessionStorage.setItem(
                    "remainingAttempts",
                    response?.responseError?.fields?.remainingAttempts
                );
            } else {
                sessionStorage.removeItem("remainingAttempts");
            }
            messagesFilter.api
                .success(response)
                .then((responseFiltered) => {
                    const { userId, device } = responseFiltered.data;

                    trackersService.auth.login(email, userId);

                    setCredentials(responseFiltered.data[0]);

                    if (device?.verified) {
                        trustedService.saveAsValid(userId);
                    } else {
                        trustedService.removeAsValid(userId);
                    }

                    resolve(responseFiltered);
                })
                .catch((error) => {
                    trackersService.auth.loginError(email, null);
                    reject(error);
                });
        })
        .catch((error) => {
            reject(messagesFilter.api.errorGeneric(error));
        });
};

/**
 * Login switch
 *
 * @param {string} email
 * @param {string} password
 * @param {object} device
 * @param {boolean} companyLogin - optional
 *
 * @return {Promise}
 */
const login = (email, password, device, companyLogin) => {
    return new Promise((resolve, reject) => {
        if (!email || !password) {
            return reject("auth:login-no-email-or-password");
        }

        const objectDevice = { ...device, device_type: "web" };

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Ingresse-Device": JSON.stringify(objectDevice),
        };

        if (!companyLogin) {
            _login(email, password, headers, resolve, reject);
            return;
        }

        _companyLogin(email, password, headers, resolve, reject);
    });
};

/**
 * Logout
 */
const logout = () => {
    clearCredentials();
};

/**
 * Formatted form data
 *
 * @param {object}   userData
 *
 * @return {object}
 */
const _getDataRegisterOrUpdate = (userData) => {
    let formData = {};

    for (let key in userData) {
        let value = userData[key];

        if (key === "document" || (typeof value !== "boolean" && !value)) {
            continue;
        }

        if (key === "name") {
            const [name, ...restName] = value.split(" ");
            const lastName = restName.join(" ");
            formData[key] = name;
            formData.lastName = lastName;
        } else {
            formData[key] = value;
        }
    }

    if (userData.document) {
        const type = userData.nationality === "BRA" ? 1 : 2;
        let userDocument =
            type === 1
                ? userData.document.replaceAll(".", "").replaceAll("-", "")
                : userData.document;
        formData.identity = {
            id: userDocument,
            type,
        };
    }

    return formData;
};

/**
 * Register
 *
 * @param {object} userData - data
 */
const register = (userData) => {
    return new Promise((resolve, reject) => {
        if (!userData || typeof userData !== "object") {
            return reject("auth:register-invalid-data");
        }

        const formData = _getDataRegisterOrUpdate(userData);

        requestSdk("/users", {
            method: "POST",
            body: formData,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(messagesFilter.api.errorGeneric(error));
            });

        return true;
    });
};

/**
 * Update
 *
 * @param {object} userData - data
 */
const update = (userId, updateData) => {
    return new Promise((resolve, reject) => {
        const _credentials = getCredentials() || {};
        const { token } = _credentials || {};

        if (!updateData || typeof updateData !== "object") {
            return reject("auth:register-invalid-data");
        }

        const formData = _getDataRegisterOrUpdate(updateData);

        requestSdk(`/users/${userId}`, {
            method: "PUT",
            body: formData,
            query: { usertoken: token },
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(messagesFilter.api.errorGeneric(error));
            });

        return true;
    });
};

/**
 * Facebook login
 */
const fbLogin = (userData, device) => {
    return new Promise((resolve, reject) => {
        if (!userData || typeof userData !== "object") {
            return reject("auth:fblogin-data-poorly-formatted");
        }

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Ingresse-Device": JSON.stringify(device),
        };

        ingresseService
            .api()
            .post("/login/facebook", userData, null, headers)
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .then((responseFiltered) => {
                        const { userId, device } = responseFiltered.data;

                        if (device?.verified) {
                            trustedService.saveAsValid(userId);
                        } else {
                            trustedService.removeAsValid(userId);
                        }
                        setCredentials(responseFiltered.data);
                        resolve(responseFiltered);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(messagesFilter.api.errorGeneric(error));
            });
    });
};
/**
 * Validates User JWT
 *
 * @returns {Promise}
 */
const validateJWT = (newDevice = null) => {
    return new Promise((resolve, reject) => {
        const credentials = getCredentials();
        const { authToken, jwt: jwtInLegacyKey, token: usertoken } = credentials || {};

        const jwt = authToken || jwtInLegacyKey;

        const decodedJWT = jwt ? decodeJWT(jwt) : null;

        function success(overrideCredentials) {
            sdkCredentials.set(overrideCredentials);
            setCredentials(overrideCredentials);
            resolve(overrideCredentials);
        }

        if (!usertoken) {
            return reject({
                message: '"authService.validateJWT" error: Missing usertoken.',
            });
        }

        if (!decodedJWT) {
            return reject({
                message: '"authService.validateJWT" error: Missing jwtToken.',
            });
        }

        if (!decodedJWT.device) {
            return reject({
                message:
                    '"authService.validateJWT" error: Missing device in jwtToken.',
            });
        }

        const expiresAt =
            decodedJWT && decodedJWT.exp
                ? date(decodedJWT.exp * 1000).utc()
                : "";
        const now = date.utc();
        const stillValid = !!(expiresAt && now.isBefore(expiresAt));
        const newCredentials = {
            ...(credentials || {}),
            jwt,
            token: usertoken,
            authToken: jwt,
        };

        if (
            stillValid &&
            decodedJWT.device.id
        ) {
            return success(newCredentials);
        }

        const crueiroCookie = window.Cookies.get("ingresse-device-team");

        const cruzeiroApp = crueiroCookie ? JSON.parse(crueiroCookie) : "";

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Ingresse-Device": JSON.stringify(newDevice),
            ...(cruzeiroApp
                ? {
                      "X-Ingresse-Device-Team": true,
                  }
                : null),
        };

        requestSdk("/login/renew-token", {
            method: "GET",
            query: { usertoken },
            headers,
        })
            .then((responseCredentials) => {
                const { authToken, device } = responseCredentials || {};

                if (!authToken) {
                    return reject({
                        code: -1,
                        message: "Auth: Token renew failed",
                    });
                }

                if (device?.verified) {
                    trustedService.saveAsValid(device?.verified);
                } else {
                    trustedService.removeAsValid();
                }

                const overrideCredentials = {
                    ...newCredentials,
                    jwt: authToken,
                };

                success(overrideCredentials);
            })
            .catch(reject);
    });
};

/* Constant object to represent Service Functions */
export const authService = {
    getCredentials,
    setCredentials,
    clearCredentials,
    fbLogin,
    login,
    logout,
    register,
    update,
    validateJWT,
};
