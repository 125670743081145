/* Package */
import React from "react";

function IconCheckList(props) {
    const svgStyles = {
        display: "inline-block",
        lineHeight: 0,
        verticalAlign: "middle",
        marginRight: "3px",
    };

    if (props.slug && props.slug === "do") {
        return (
            <span style={{ ...svgStyles }}>
                <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 20 20"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g
                        id="Icon-/-20-/-Dos-/-Mercury"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <polyline
                            id="Path"
                            stroke="#60C659"
                            strokeWidth="2.7"
                            points="5.1 9.33333333 8.97096774 14 15.1 6"
                        ></polyline>
                    </g>
                </svg>
            </span>
        );
    }

    if (props.slug && props.slug === "donts") {
        return (
            <span style={{ ...svgStyles }}>
                <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 20 20"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g
                        id="Icon-/-20-/-Donts-/-Ruby"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <g
                            id="Fechar"
                            transform="translate(6.000000, 6.000000)"
                            stroke="#EF3C3E"
                            strokeWidth="2.7"
                        >
                            <path
                                d="M-4.4408921e-16,8 L8,-6.66133815e-15 L-4.4408921e-16,8 Z"
                                id="Path-2"
                                transform="translate(4.000000, 4.000000) rotate(-90.000000) translate(-4.000000, -4.000000) "
                            ></path>
                            <path
                                d="M0,8 L8,0 L0,8 Z"
                                id="Path-2-Copy"
                                transform="translate(4.000000, 4.000000) rotate(-180.000000) translate(-4.000000, -4.000000) "
                            ></path>
                        </g>
                    </g>
                </svg>
            </span>
        );
    }
}

export default IconCheckList;
