import React from "react";

import {
    routesConstants as ROUTES,
    textsConstants as TEXTS,
} from "../_constants";

import FlowLink from "../_components/FlowLink.jsx";
import AuthDivider from "./components/AuthDivider";
import AuthFacebankButton from "./components/AuthFacebankButton";
import AuthFacebookButton from "./components/AuthFacebookButton";

const AuthChoices = (
    <div className="segment__content__limited">
        <div className="aph m-15-bot">
            <FlowLink
                className="aph btn btn--block btn--bordered btn--primary"
                to={ROUTES.AUTH.LOGIN}
                id="linkAuthLogin"
            >
                {TEXTS.AUTH.CHOICES.LOGIN}
            </FlowLink>
        </div>
        <div className="aph m-15-top">
            <FlowLink
                className="aph btn btn--block btn--bordered btn--primary"
                to={ROUTES.AUTH.REGISTER}
                id="linkAuthRegister"
            >
                {TEXTS.AUTH.CHOICES.REGISTER}
            </FlowLink>
        </div>
        <AuthDivider margin="25px 0" />
        <AuthFacebookButton className="aph m-15-top" buttonType="login" />
        <AuthFacebankButton margin="15px 0 0" />
    </div>
);

export default AuthChoices;
