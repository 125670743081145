/* Packages */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* Constants */
import {
    PASSWORD,
    routesConstants as ROUTES,
    textsConstants as TEXTS,
} from "../_constants";

/* Actions */
import { passwordActions } from "../_actions";

/* Services */
import { postMessageService } from "../_services";

/* Helper Components */
import FlowLink from "../_components/FlowLink";
import { withTranslation } from "react-i18next";

/* Recover Sent Component */
class RecoverSent extends Component {
    /**
     * Constructor
     *
     * @param {object} props - React Component default props
     */
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            display: false,
        };

        this.handleClose = this.handleClose.bind(this);
    }

    /**
     * Will Mount
     */
    componentWillMount() {
        const { history, hash } = this.props;
        const isVisible = hash && hash === PASSWORD.SENT ? true : false;

        this.setState(
            {
                display: isVisible,
            },
            () => {
                if (isVisible) {
                    return;
                }

                history.push(ROUTES.PASSWORD.RECOVER);
            }
        );
    }

    /**
     * Handle with Close action
     */
    handleClose() {
        const { dispatch, email } = this.props;

        dispatch(passwordActions.setHash(""));
        postMessageService.passwordRecoverSent(email);
    }

    /* Render */
    render() {
        const { display } = this.state;

        if (!display) {
            return null;
        }

        const icons = {
            iCloud: require("../../assets/icons/icon--icloud--120.png"),
            gmail: require("../../assets/icons/icon--gmail--120.png"),
            outlook: require("../../assets/icons/icon--outlook.svg"),
            yahooMail: require("../../assets/icons/icon--yahoo-mail--120.png"),
        };

        return (
            <div>
                <div className="segment__content__limited">
                    <div className="aph text-center">
                        <div className="aph p-5-top m-5-bot">
                            <h3 className="aph text-dark-grey m-0">
                                {this.props.t("PASSWORD.SENT.TITLE")}
                            </h3>
                        </div>
                        <div className="aph p-5-top m-5-bot">
                            <div className="aph text-grey p-10-ver">
                                {this.props.t("PASSWORD.SENT.DESCRIPTION_1")}
                            </div>
                            <div className="aph text-grey p-10-ver">
                                {this.props.t("PASSWORD.SENT.DESCRIPTION_2")}
                            </div>
                            <div className="aph text-grey p-10-ver">
                                {this.props.t("PASSWORD.SENT.DESCRIPTION_3")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aph p-20-ver text-center providers">
                    {TEXTS.PASSWORD.SENT.PROVIDERS.map((PROVIDER, INDEX) => (
                        // eslint-disable-next-line
                        <a
                            key={INDEX}
                            id={`linkRecoverSentProvider_${PROVIDER.ID}`}
                            className={`providers__item ${PROVIDER.ID}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={PROVIDER.URL}
                            style={{
                                backgroundImage: `url(${icons[PROVIDER.ID]})`,
                            }}
                        />
                    ))}
                </div>
                <div className="segment__content__limited">
                    <div className="aph p-20-top p-10-bot">
                        <FlowLink
                            className="aph btn btn--block btn--primary btn--link"
                            id="linkRecoverSentToLogin"
                            onClick={this.handleClose}
                            to={ROUTES.AUTH.LOGIN}
                        >
                            {this.props.t("PASSWORD.SENT.ACTIONS.CLOSE")}
                        </FlowLink>
                    </div>
                </div>
            </div>
        );
    }
}

/* Map State to Props */
function mapStateToProps(state) {
    return state.password;
}

/* Export Component */
export default withTranslation()(
    connect(mapStateToProps)(withRouter(RecoverSent))
);
