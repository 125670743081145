import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import { oauthActions } from "../_actions/oauth.actions";

const OauthAuthorize = () => {
    const dispatch = useDispatch();
    const oauth = useSelector((state) => state.oauth);
    const auth = useSelector((state) => state.auth);

    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = (evt) => {
        evt.preventDefault();

        if (submitted) {
            return;
        }

        setSubmitted(true);
        dispatch(oauthActions.consent(oauth.flowId));
    };

    useEffect(() => {
        if (auth.error) {
            setError(auth.error);
            setSubmitted(false);
        } else {
            setError("");
        }
    }, [auth.error]);

    if (!oauth) {
        return <div>Carregando...</div>;
    }

    return (
        <div className="aph oauth__modal">
            <div className="aph oauth__modal__header">
                <p className="aph">Compartilhando dados com</p>
                {!!oauth.clientName && (
                    <h3 className="aph oauth__modal__header__name">
                        {oauth.clientName}
                    </h3>
                )}
            </div>

            {oauth.scopes.includes("profile") && (
                <p>
                    Para prosseguir, este serviço solicita que você compartilhe
                    seus dados registrados na Ingresse, como nome, e-mail e
                    ingressos. Ao permitir, você estará autorizando a Ingresse a
                    compartilhá-los.
                </p>
            )}
            <p>
                Confira a{" "}
                <a href="https://sobre.ingresse.com/termos-de-privacidade">
                    Politica de Privacidade
                </a>{" "}
                e os{" "}
                <a href="https://sobre.ingresse.com/termos-de-servico">
                    Termos de Uso
                </a>{" "}
                para mais informações sobre como seus dados poderão ser
                utilizados.
            </p>
            <button
                className={`aph btn btn--block btn--primary`}
                type="button"
                onClick={handleSubmit}
                disabled={submitted}
            >
                PERMITIR COMPARTILHAMENTO
            </button>
        </div>
    );
};

export default withTranslation()(OauthAuthorize);
