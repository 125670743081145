/* Packages */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* Constants */
import {
    routesConstants as ROUTES,
    textsConstants as TEXTS,
    PASSWORD,
} from "../_constants";

/* Actions */
import { passwordActions } from "../_actions";

/* Services */
import { postMessageService } from "../_services";

/* Helper Components */
import FlowLink from "../_components/FlowLink";

/* Recover Sent Component */
class PasswordUpdated extends Component {
    /**
     * Constructor
     *
     * @param {object} props - React Component default props
     */
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            display: false,
        };

        this.handleContinue = this.handleContinue.bind(this);
    }

    /**
     * Will Mount
     */
    componentWillMount() {
        const { email, hash, history } = this.props;
        const isVisible = hash && hash === PASSWORD.UPDATED ? true : false;

        this.setState(
            {
                display: isVisible,
            },
            () => {
                if (isVisible) {
                    return postMessageService.passwordUpdated(email);
                }

                history.push(ROUTES.AUTH.LOGIN);
            },
        );
    }

    /**
     * Handle with Close action
     */
    handleContinue() {
        const { dispatch, isFromIngresseDomain } = this.props;

        if (!isFromIngresseDomain) {
            window.location.href = `https://backstagemirante.com/`;
            return;
        }

        dispatch(passwordActions.setHash(PASSWORD.TRACK));
    }

    /* Render */
    render() {
        const { display } = this.state;
        const {isFromIngresseDomain} = this.props;

        if (!display) {
            return null;
        }

        return (
            <div className="segment__content__limited">
                <div className="aph text-center">
                    <div className="aph p-5-top m-5-bot">
                        <h3 className="aph text-dark-grey m-0">
                            {TEXTS.PASSWORD.UPDATED.TITLE}
                        </h3>
                    </div>
                    <div className="aph p-5-top m-5-bot">
                        {TEXTS.PASSWORD.UPDATED.DESCRIPTIONS.map(
                            (DESCRIPTION, INDEX) => (
                                <div
                                    key={INDEX}
                                    className="aph text-grey p-10-ver"
                                >
                                    {DESCRIPTION}
                                </div>
                            ),
                        )}
                    </div>
                    <div className="aph p-10-ver">
                        <FlowLink
                            className="aph btn btn--block btn--primary btn--bordered"
                            id="linkPasswordUpdatedToLogin"
                            onClick={this.handleContinue}
                            to={!isFromIngresseDomain ? "#" : ROUTES.AUTH.LOGIN}
                        >
                            {TEXTS.PASSWORD.UPDATED.ACTIONS.CONTINUE}
                        </FlowLink>
                    </div>
                </div>
            </div>
        );
    }
}

/* Map State to Props */
function mapStateToProps(state) {
    return {
        ...state.password,
        isFromIngresseDomain: state.auth.isFromIngresseDomain,
    }
}

/* Export Component */
export default connect(mapStateToProps)(withRouter(PasswordUpdated));
