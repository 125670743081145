/* Service Bases */
import { flowService } from "./";

/* Actions */
import { redirectActions } from "../_actions";

/**
 * Initializer
 */
const init = () => {
    return (dispatch) => {
        let _url =
            flowService.getParam("return") ||
            flowService.getParam("returnUrl") ||
            flowService.getParam("redirect") ||
            flowService.getParam("redirectUrl") ||
            "";

        _url = encodeURIComponent(_url.toLowerCase());

        dispatch(redirectActions.setUrl(_url));
    };
};

/* Exporting Service */
export const redirectService = {
    init,
};
