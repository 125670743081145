import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Styled } from "@ingresse/aphrodite";
import posthog from "posthog-js";

import DialogFacebank from "./DialogFacebank";
import { settingsService } from "../../_services";
import facebankLogo from "../../../assets/icons/icon--facebank.png";

const facebankNonceRandomString = Math.random().toString(36);
const facebankCliendId =
    process.env.REACT_APP_FACEBANK_CLIENT_ID || "Ingresse_z2t9mC";
const facebankState =
    process.env.REACT_APP_FACEBANK_STATE || "Facebank_Integration_Test";
const facebankURL = [
    "https://account.facebank.com/oauth2/auth",
    `?client_id=${facebankCliendId}`,
    "&redirect_uri=:facebankRedirectURI",
    "&scope=openid profile offline email",
    "&response_type=code",
    "&response_mode=query",
    `&state=${facebankState}`,
    `&nonce=${facebankNonceRandomString}`,
    "&allowSignUp=false",
].join("");

export default function AuthFacebankButton({ disabled, styles = {}, ...rest }) {
    const { t } = useTranslation();
    const settings = settingsService.get();
    const facebankRedirectURI = encodeURIComponent(
        `${window.location.origin}/facebank`
    );

    const [faceBankUpdateFrame, setFaceBankUpdateFrame] = useState(false);

    const { isFromIngresseDomain } = useSelector((state) => state.auth);

    const redirectView = (link) => {
        posthog.capture("auth:auth_login_facebank_click");
        window.open(`${link}`, "_blank");
        setFaceBankUpdateFrame(true);
    };

    const updateFrame = () => {
        setFaceBankUpdateFrame(false);
        window.location.reload(true);
    };

    if (!settings?.APP?.FACEBANK) {
        return null;
    }

    return (
        <>
            <Button
                block
                link
                color="#3C57A6"
                className={`${
                    !isFromIngresseDomain && "custom_mrt_hide_element"
                }`}
                styles={{
                    border: "1px solid currentColor",
                    padding: "9px 0",
                    ...styles,
                }}
                {...rest}
                onClick={() =>
                    redirectView(
                        facebankURL.replace(
                            ":facebankRedirectURI",
                            facebankRedirectURI
                        )
                    )
                }
            >
                {!faceBankUpdateFrame ? t("AUTH.CHOICES.FACEBANK") : null}
                <Styled
                    as="img"
                    alt="FACEBANK"
                    src={facebankLogo}
                    styles={{
                        height: 35,
                        margin: "-12.5px -6px -12.5px 6px",
                    }}
                />
            </Button>
            {faceBankUpdateFrame && (
                <DialogFacebank
                    setFaceBankUpdateFrame={setFaceBankUpdateFrame}
                    updateFrame={updateFrame}
                />
            )}
        </>
    );
}
