/* Texts Constants */
export const textsConstants = {
    AUTH: {
        CHOICES: {
            FACEBOOK: {
                LOGIN: "ENTRAR COM O FACEBOOK",
                REGISTER: "CADASTRAR COM FACEBOOK",
            },
            LOGIN: "ENTRAR COM A CONTA INGRESSE",
            REGISTER: "CRIAR CONTA",
        },
        NATIONALITY: {
            BRAZILIAN: "BR",
            ABROAD: "UN",
        },
        GENDER: {
            FEMALE_CISGENDER: "FC",
            FEMALE_TRANSGENDER: "FT",
            MALE_CISGENDER: "MC",
            MALE_TRANSGENDER: "MT",
            NON_BINARY: "NB",
            OTHER: "O",
        },
        TEXT_SELECT: {
            DEFAULT: "selecione",
            NATIONALITY: {
                BRAZILIAN: "Brasileiro",
                ABROAD: "Estrangeiro",
            },
            GENDER: {
                FEMALE_CISGENDER: "Mulher Cisgênero",
                FEMALE_TRANSGENDER: "Mulher Transgênero",
                MALE_CISGENDER: "Homem Cisgênero",
                MALE_TRANSGENDER: "Homem Transgênero",
                NON_BINARY: "Não-binário",
                OTHER: "Outro",
            },
        },
        PLACEHOLDERS: {
            DOCUMENT: "Selecione a nacionalidade primeiro",
        },
        LABELS: {
            NAME: "NOME",
            PHONE: "TELEFONE",
            NATIONALITY: "NACIONALIDADE",
            DOCUMENT: "DOCUMENTO",
            CPF: "CPF",
            PASSPORT: "PASSAPORTE OU ID",
            BIRTHDATE: "DATA DE NASCIMENTO",
            GENDER: "IDENTIDADE DE GÊNERO",
            EMAIL: "E-MAIL",
            CONFIRM: "CONFIRMAR E-MAIL",
            PASSWORD: "SENHA",
            NEWSLETTER: "Assinar newsletter",
        },
        TOOLTIP: {
            TITLE: "Cisgênero",
        },
        LOGIN: {
            RECOVER: {
                TEXT: "ESQUECI MINHA SENHA",
                TITLE: "Receba um link para redefinição de senha em seu e-mail",
            },
            REMEMBER_ME: "Lembrar de mim",
            SUBMIT: "ENTRAR",
        },
        REGISTER: {
            HEADER: "Crie sua conta Ingresse",
            ALREADY: "Já possui uma conta?",
            SUBMIT: "CADASTRAR",
            TERMS: {
                DESCRIPTION: "Declaro que li e concordo com os ",
                CONCAT: " e ",
                SERVICE: "Termos de Serviço",
                PRIVACY: "Política de Privacidade",
            },
            UPDATE: {
                HEADER: "Completar cadastro",
                SUBMIT: "CONTINUAR",
                SUBTITLE:
                    "Falta pouco! Preencha as informações a seguir para acessar sua conta.",
                ALREADY: "Não quer prosseguir?",
                LOGOUT: "Entrar com outra conta",
                TERMS: {
                    DESCRIPTION: "Ao clicar em salvar você aceita os nossos ",
                },
            },
        },
        USER: {
            TITLE: "ESTE É VOCÊ?",
            NO_LOGOUT: "NÃO, TROCAR",
            YES_CONTINUE: "SIM, CONTINUAR",
        },
        COMPANIES: {
            TITLE: "QUAL CONTA DESEJA ACESSAR?",
            BACK: "VOLTAR",
        },
        DONE: {
            TITLE: "AUTENTICADO",
            TEXT: "Aguardando redirecionamento...",
        },
    },
    PASSWORD: {
        RECOVER: {
            TITLE: "Esqueci minha senha",
            DESCRIPTIONS: [
                "Digite abaixo o e-mail utilizado no site para receber o link e redefinir sua senha.",
                "Ele permanecerá ativo durante 4 horas.",
            ],
            HELP: ["Em caso de problemas para recuperar a senha,"],
            FRESHDESK: ["fale com a Ingresse."],
            FIELDS: {
                EMAIL: {
                    LABEL: "E-MAIL",
                    PLACEHOLDER: "Digite seu e-mail aqui",
                },
            },
            ACTIONS: {
                SEND: "ENVIAR",
                CANCEL: "CANCELAR",
            },
        },
        SENT: {
            TITLE: "E-mail enviado",
            DESCRIPTIONS: [
                "Em instantes você receberá um e-mail com as instruções para alterar sua senha!",
                "Lembre-se de que ele permanecerá válido durante 4 horas.",
                "Verifique seu e-mail.",
            ],
            PROVIDERS: [
                {
                    ID: "iCloud",
                    TITLE: "Acesse seu e-mail no iCloud",
                    URL: "https://www.icloud.com/",
                },
                {
                    ID: "gmail",
                    TITLE: "Acesse seu e-mail Google",
                    URL: "https://mail.google.com/",
                },
                {
                    ID: "outlook",
                    TITLE: "Acesse seu e-mail no Outlook",
                    URL: "https://outlook.com/",
                },
                {
                    ID: "yahooMail",
                    TITLE: "Acesse seu Yahoo Mail",
                    URL: "https://outlook.com/",
                },
            ],
            ACTIONS: {
                CLOSE: "FECHAR",
            },
        },
        UPDATE: {
            TITLE: "Redefinir Senha",
            DESCRIPTIONS: [
                "Digite abaixo a nova senha desejada.",
                "Ela deve conter no mínimo 8 caracteres, letras e números.",
            ],
            FIELDS: {
                PASSWORD: {
                    LABEL: "NOVA SENHA",
                    PLACEHOLDER: "Digite a senha",
                },
                PASSWORD_CONFIRM: {
                    LABEL: "CONFIRMAR SENHA",
                    PLACEHOLDER: "Digite a senha novamente",
                },
            },
            ACTIONS: {
                CONTINUE: "CONTINUAR",
            },
        },
        UPDATED: {
            TITLE: "Senha Redefinida!",
            DESCRIPTIONS: [
                "Sua senha foi atualizada.",
                "Clique no botão abaixo para entrar na sua conta e continuar navegando.",
            ],
            ACTIONS: {
                CONTINUE: "ACESSAR SUA CONTA",
            },
        },
    },
    TRUSTED: {
        TITLE: "Verificar Dispositivo",
        DESCRIPTION:
            "Digite o código enviado ao seu número para confirmar sua identidade.",
        LABEL: "CÓDIGO",
        SEND: "Não recebeu o código?",
        ACTIONS: {
            VERIFY: "Verificar",
            AGAIN: "Tentar novamente",
        },
        CONFIRM: {
            DESCRIPTION:
                "Para verificar seu dispositivo é necessário digitar o código que enviaremos para seu número: ",
            ACTIONS: {
                SMS: "Receber código via SMS",
                WPP: "Receber código via WhatsApp",
            },
        },
    },
};
