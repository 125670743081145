import moment from "moment";

/* Regular Expressions */
const _name = /\w{2}\s\w{2}/;
const _phone = /\(?[1-9]{2}\)? ?[9]{1}[0-9]{4}-?[0-9]{4}$/;
const _email =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const _password = /^(?=.*[A-Za-z])(?=.*\d)[\w\W]{8,100}$/;

/**
 * Name RegEx Filter
 *
 * @param {string} value
 *
 * @return {boolean}
 */
const name = (value) => {
    return _name.test(value);
};

/**
 * Brazilian Phone RegEx Filter
 *
 * @param {string} value
 *
 * @return {boolean}
 */
const phone = (value) => {
    return _phone.test(value);
};

/**
 * E-mail RegEx Filter
 *
 * @param {string} value
 *
 * @return {boolean}
 */
const email = (value) => {
    return _email.test(value);
};

/**
 * Password RegEx Filter
 *
 * @param {string} value
 *
 * @return {boolean}
 */
const password = (value) => {
    return _password.test(value);
};

/**
 *
 * @param {String} value
 * @param {String} [mask]
 */
const birthdate = (value, mask = "") => {
    var minDate = moment("1920-01-01");
    var isValidDate;

    const cleaned = (value || "").replace(/\D/gi, "");
    if (!cleaned) {
        return true;
    }

    const date = moment(value, mask);
    const now = moment();

    if (date.isValid()) {
        if (date.isBefore(minDate)) {
            isValidDate = false;
        } else if (date.isSameOrBefore(now)) {
            isValidDate = true;
        }
    }
    return isValidDate;
};

/* Export as object with functions */
export const validators = {
    name,
    phone,
    email,
    password,
    birthdate,
};
