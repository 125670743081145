import { cookiesUtils } from "./cookies.utils";

export const removeAllCookies = () => {
    if(window.location.href.includes('password/update')) {
        cookiesUtils.remove("userId");
        cookiesUtils.remove("token");
        cookiesUtils.remove("jwt");
        cookiesUtils.remove("trusted_device");
        localStorage.clear();
    }
};
