import React from "react";
import { settingsService } from "../../_services";
import { Divider } from "../../_components";
import { useTranslation } from "react-i18next";

export default function AuthDivider(props) {
    const { t } = useTranslation();
    const settings = settingsService.get();
    const hasSocialButtons = !!(
        settings?.APP?.FACEBANK || settings?.APP?.FACEBOOK
    );

    if (!hasSocialButtons) {
        return null;
    }

    return <Divider {...props}>{t("AUTH.DIVIDER")}</Divider>;
}
