/* Routes Constants */
export const routesConstants = {
    INDEX: "/choices",
    NOT_FOUND: "/404",
    AUTH: {
        LOGIN: "/login",
        TWOFA: "/two-factory",
        REGISTER_TWOFA: "/register-two-factory",
        REGISTER: "/register",
        INCOMPLETE: "/incomplete",
        CALLBACK: "/callback",
        FACEBANK: "/facebank",
    },
    PASSWORD: {
        RECOVER: "/password/recover",
        SENT: "/password/recover/sent",
        UPDATE: "/password/update",
        UPDATED: "/password/updated",
    },
    PRIVATE: {
        USER_CONFIRM: "/confirm",
        COMPANIES: "/companies",
        DONE: "/done",
        TRUSTED: "/trusted",
    },
    OAUTH: {
        LOGIN: "/oauth/login",
        AUTHORIZE: "/oauth/authorize",
        REGISTER: "/oauth/register",
        CREDENTIALS: "/oauth/credentials",
    },
};
