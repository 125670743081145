/* App Constants */
export const appConstants = {
    NAME: "Ingresse",
    LOGO: {
        REGULAR: "/assets/images/application-logo.svg",
        INVERSE: "/assets/images/application-logo--inverse.svg",
    },
    KEYS: {
        LEGITI: {
            PROD: "live_eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwcmluY2lwYWxJZCI6ImluZ3Jlc3NlIn0.YzFSwk_thY7oCVoS1BLjDJkEgEBg7FwKmCBT15YOebE",
            HML: "sandbox_eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwcmluY2lwYWxJZCI6ImluZ3Jlc3NlIn0.YzFSwk_thY7oCVoS1BLjDJkEgEBg7FwKmCBT15YOebE",
        },
        INGRESSE: {
            PROD: "172f24fd2a903fc0647b61d7112ee1b9814702be",
            HML: "e9424e72263bcab5d37ecb04e05505cf91d67639",
        },
    },
};
