/* Routes Constant */
import { routesConstants as ROUTES } from "../_constants";

/**
 * Get path
 *
 * @return {string} pathname
 */
const getPath = () => {
    return window.location.pathname;
};

/**
 * Get search string
 *
 * @return {string}
 */
const getSearch = () => {
    return window.location.search;
};

/**
 * Get search params
 *
 * @return {object} URLSearchParams instance
 */
const getParams = () => {
    const search = getSearch();
    const params = new URLSearchParams(search);

    return params;
};

/**
 * Get specific search param
 *
 * @param {string} paramName
 *
 * @return {string} paramValue
 */
const getParam = (paramName) => {
    const search = getSearch();
    const params = new URLSearchParams(search);
    const paramValue = params.get(encodeURIComponent(paramName));

    return paramValue;
};

/**
 * Get flow data
 *
 * @return {object} flow data
 */
const get = () => {
    const path = getPath();
    const search = getSearch();

    return {
        path,
        search,
    };
};

/**
 * Go to
 *
 * @param {string} path
 * @param {string} params
 */
const goTo = (path, params) => {
    window.location.replace(
        decodeURIComponent((path || ROUTES.INDEX) + (params ? params : "")),
    );
};

/* Constant object to represent Service Functions */
export const flowService = {
    goTo,
    get,
    getPath,
    getParam,
    getParams,
    getSearch,
};
