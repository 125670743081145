export function useLanguage() {
    const DEFAULT_LANGUAGE = "pt-BR";
    const name = 'locale=';
    const isBackstage = window.location.href.includes("backstage");
    let cookieValue = (document.cookie.split(';')
        .map(cookie => cookie.trim())
        .find(cookie => cookie.startsWith(name)) || '')
        .substring(name.length)
        .split('=')
        .pop();

    if (isBackstage && cookieValue === 'en-US') {
        cookieValue = DEFAULT_LANGUAGE;
    }
    
    return cookieValue || DEFAULT_LANGUAGE;
}
