import BRflag from "./assets/brazil.svg";
import ESflag from "./assets/spain.svg";
import USflag from "./assets/united-states.svg";

export const LANGUAGES = [
    {
        code: "pt-BR",
        flag: BRflag,
        name: "Português (PT)",
    },
    {
        code: "es-ES",
        flag: ESflag,
        name: "Español (ES)",
    },
    {
        code: "en-US",
        flag: USflag,
        name: "English (EN)",
    },
];
