/* User Constants */
export const userConstants = {
    FILTERS:
        "id,type,email,name,lastname,document,verified,fbUserId,ddi,phone,birthdate,picture,pictures,newsletter,city,state,street,district,zip,number,complement",

    /* Required Info */
    REQUIRED: [
        "name",
        "ddi",
        "phone",
        "nationality",
        "document",
        "birthdate",
        "email",
    ],
    SIMPLE_FORM_REQUIRED: [
        "name",
        "ddi",
        "phone",
        "email",
    ],
    REQUIRED_MSG:
        "Antes de continuar, é necessário preencher algumas informações obrigatórias para o seu perfil.",

    /* Actions */
    SET_DATA: "USER:SET_DATA",
    SET_INCOMPLETE: "USER:SET_INCOMPLETE",
};
