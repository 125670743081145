/* Constants */
import {
    companiesConstants as COMPANIES,
    routesConstants as ROUTES,
} from "../_constants";

/* Services */
import { flowService, ingresseService, themeService } from "./";

/**
 * 'Cached' data
 */
let cached = {};

/**
 * Initialize Service
 */
const init = () => {
    return (dispatch) => {
        cached = {};
        const urlParams = flowService.getParams();

        urlParams.forEach((paramValue, paramKey) => {
            switch (paramKey.toLowerCase()) {
                case "companyid":
                    cached.companyId = paramValue;
                    break;
                case "app":
                    cached.app = paramValue;
                    break;
                default:
                    cached[paramKey] = paramValue;
                    break;
            }
        });

        /* Set company based on 'companyId' */
        if (cached.companyId) {
            Object.values(COMPANIES).forEach((COMPANY) => {
                if (COMPANY.ID === parseInt(cached.companyId, 10)) {
                    cached.COMPANY = COMPANY;
                }
            });
        }

        /* Set default company */
        if (!cached.COMPANY) {
            cached.COMPANY = COMPANIES["1"];
        }

        /* Set default theme */
        cached.APP = cached.COMPANY.APPS.DEFAULT;

        /* Check for optional app */
        if (cached.app) {
            cached.APP =
                cached.COMPANY.APPS[cached.app.toUpperCase()] ||
                cached.COMPANY.APPS.DEFAULT;
        }

        /* Set App Index Route */
        cached.INDEX = ROUTES.INDEX;

        if (
            cached.APP.LOGIN === "company" ||
            (cached.companyLogin && cached.companyLogin === "true")
        ) {
            cached.companyLogin = true;
            cached.INDEX = ROUTES.AUTH.LOGIN;
        }

        /* Initialize Theme */
        themeService.init(cached.COMPANY, cached.APP);

        /* Initialize Ingresse's SDK */
        dispatch(ingresseService.init(cached));
    };
};

/**
 * Get Cached Settings
 */
const get = () => {
    return cached;
};

/* Constant object to represent Service Functions */
export let settingsService = {
    cached: cached,
    init: init,
    get: get,
};
