/* Service */
import { authService, injectService, userService, settingsService } from "./";

/* Constant */
import { externalConstants as EXTERNAL } from "../_constants";

/**
 * Async Initialize Facebook SDK
 */
const asyncInit = () => {
    window.fbAsyncInit = () => {
        window.FB.init({
            appId: EXTERNAL.FACEBOOK.KEYS.APP_ID,
            status: true,
            cookie: true,
            xfbml: true,
            version: "v5.0",
        });

        window.FB.AppEvents.logPageView();
    };
};

/**
 * Facebook SDK is loaded?
 */
const isLoaded = () => {
    if (window.FB) {
        return true;
    }

    init();

    return false;
};

/**
 * Initialize Facebook SDK
 */
const init = () => {
    const settings = settingsService.get();

    if (settings.APP.FACEBOOK) {
        asyncInit();

        return injectService.js("facebook");
    }

    return false;
};

/**
 * Get Login Status
 *
 * @return Promise
 */
const getLoginStatus = () => {
    return new Promise((resolve, reject) => {
        if (isLoaded()) {
            window.FB.getLoginStatus((statusResponse) => {
                resolve(statusResponse);
            });

            return;
        }

        reject("facebook-error:sdk-not-loaded");
    });
};

/**
 * Get user data
 *
 * @param {function} resolve   - Inherit promise resolve
 * @param {function} reject    - Inherit promise reject
 * @param {string} accessToken - Facebook Access Token
 * @param {object} device      - Object of the device info
 */
const getData = (resolve, reject, accessToken, device) => {
    window.FB.api("/me?fields=email,name", (fbResponse) => {
        if (!fbResponse || !fbResponse.email) {
            reject({
                code: 10,
                type: "facebook-error:login-invalid-response",
                data: {
                    ...fbResponse,
                    accessToken: accessToken,
                },
            });

            return;
        }

        authService
            .fbLogin(
                {
                    email: fbResponse.email,
                    fbToken: accessToken,
                    fbUserId: fbResponse.id,
                },
                device,
            )
            .then((loginResponse) => {
                if (!loginResponse || !loginResponse.data) {
                    return reject({
                        code: 11,
                        type: "facebook-error:login-invalid-response",
                        data: {
                            ...fbResponse,
                            accessToken: accessToken,
                        },
                    });
                }

                const { userId, token, authToken } = loginResponse.data;

                userService
                    .getById(userId, token)
                    .then((userResponse) => {
                        resolve({
                            fbUserId: fbResponse.id,
                            fbToken: accessToken,
                            jwt: authToken,
                            userId,
                            token,
                            authToken,
                            ...userResponse,
                        });
                    })
                    .catch((error) => {
                        reject({
                            code: 13,
                            type: "facebook-error:login-ingresse-api-get-data-error",
                            error: error,
                            data: {
                                ...fbResponse,
                                accessToken: accessToken,
                            },
                        });
                    });
            })
            .catch((error) => {
                reject({
                    code: 12,
                    type: "facebook-error:login-ingresse-api-error",
                    error: error,
                    data: {
                        ...fbResponse,
                        accessToken: accessToken,
                    },
                });
            });
    });
};

/**
 * Effective Facebook Login
 *
 * @param {function} resolve - Inherit promise resolve
 * @param {function} reject  - Inherit promise reject
 * @param {object} device    - Object of the device info
 * @param {object} params    - Login params to FB SDK
 */
const rootLogin = (resolve, reject, device, params = {}) => {
    window.FB.login(
        (response) => {
            const { status: loginStatus, authResponse: loginAuthResponse } =
                response || {};

            if (!loginStatus) {
                reject({
                    code: 10,
                    type: "facebook-error:login-invalid-reponse",
                });

                return;
            }

            switch (loginStatus) {
                case "connected":
                    const { accessToken: loginAccessToken } =
                        loginAuthResponse || {};

                    getData(resolve, reject, loginAccessToken, device);

                    break;

                case "not_authorized":
                    reject({
                        code: 30,
                        type: "facebook-error:not-authorized",
                    });

                    break;

                default:
                    reject({
                        code: -1,
                        type: "facebook-error:login-non-treated-status",
                    });
            }
        },
        {
            scope: EXTERNAL.FACEBOOK.SCOPE,
            ...(params || {}),
        },
    );
};

/**
 * Check Facebook granted Permissions
 *
 * @param {function} resolve   - Inherit promise resolve
 * @param {function} reject    - Inherit promise reject
 * @param {string} accessToken - Facebook Access Token
 * @param {object} device      - Object of the device info
 */
const checkPermissions = (resolve, reject, accessToken, device) => {
    window.FB.api("/me/permissions", (fbResponse) => {
        const { data: fbPermissions } = fbResponse || {};

        const needRerequest = fbPermissions.some(
            (permission) => permission.status !== "granted",
        );

        if (needRerequest) {
            rootLogin(resolve, reject, device, {
                auth_type: "rerequest",
            });

            return;
        }

        getData(resolve, reject, accessToken, device);
    });
};

/**
 * Do the login
 *
 * @param {object} device      - Object of the device info
 *
 * @return Promise
 */
const login = (device) => {
    return new Promise((resolve, reject) => {
        if (isLoaded()) {
            window.FB.getLoginStatus((statusResponse) => {
                const {
                    status: currentStatus,
                    authResponse: statusAuthResponse,
                } = statusResponse || {};
                const { accessToken: statusAccessToken } =
                    statusAuthResponse || {};

                if (statusAccessToken && currentStatus === "connected") {
                    checkPermissions(
                        resolve,
                        reject,
                        statusAccessToken,
                        device,
                    );

                    return;
                }

                rootLogin(resolve, reject, device, {
                    auth_type: "rerequest",
                });
            });

            return;
        }

        reject({
            code: 0,
            type: "facebook:not-loaded",
        });
    });
};

/* Constant object to represent Service Functions */
export const facebookService = {
    init: init,
    isLoaded: isLoaded,
    getLoginStatus: getLoginStatus,
    login: login,
};
