/* Constant */
import { userConstants as USER } from "../_constants";

/* Initial State */
const initialState = {
    data: null,
};

/* Export Reducer */
const user = (state = initialState, action) => {
    switch (action.type) {
        case USER.SET_DATA:
            return {
                ...state,
                data: action.data,
            };

        default:
            return state;
    }
};

/* Export Reducer */
export default user;
