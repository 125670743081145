import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";

import AuthFormLogin from "./components/AuthFormLogin";
import AuthLocaleSelect from "./components/AuthLocaleSelect/index";

import { settingsService } from "../_services";
import { oauthActions } from "../_actions/oauth.actions";

const OauthLogin = () => {
    const dispatch = useDispatch();
    const settings = settingsService.get();

    useEffect(() => {
        dispatch(
            oauthActions.setOauthSettings({
                flowId: settings.flowId,
            })
        );
        dispatch(oauthActions.activateOauth());
    }, [dispatch, settings.flowId]);

    return (
        <div className="segment__content__limited">
            <AuthLocaleSelect />
            <AuthFormLogin />
        </div>
    );
};

export default withTranslation()(OauthLogin);
