/* Constant */
import { REDIRECT } from "../_constants";

/* Initial State */
const initialState = {
    url: "",
};

/* Export Reducer */
const redirect = (state = initialState, action) => {
    switch (action.type) {
        case REDIRECT.SET_URL:
            return {
                ...state,
                url: action.data,
            };

        default:
            return state;
    }
};

/* Export Reducer */
export default redirect;
