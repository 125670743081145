/* Packages */
import { combineReducers } from "redux";

/* Reducers */
import auth from "./auth.reducer.js";
import password from "./password.reducer.js";
import redirect from "./redirect.reducer.js";
import user from "./user.reducer.js";
import trusted from "./trusted.reducer.js";
import oauth from "./oauth.reducer.js";

/* Export Combined Reducers */
export default combineReducers({
    auth,
    password,
    redirect,
    user,
    trusted,
    oauth,
});
