/* Password Constants */
import { PASSWORD } from "../_constants";

/* Password Actions */
export const passwordActions = {
    /**
     * Set e-mail
     *
     * @param {string} email
     */
    setEmail: (email) => {
        return (dispatch) => {
            dispatch({
                type: PASSWORD.SET_EMAIL,
                data: email,
            });
        };
    },

    /**
     * Set hash
     *
     * @param {string} hash
     */
    setHash: (hash) => {
        return (dispatch) => {
            dispatch({
                type: PASSWORD.SET_HASH,
                data: hash,
            });
        };
    },

    /**
     * Set error
     *
     * @param {string} error
     */
    setError: (error) => {
        return (dispatch) => {
            dispatch({
                type: PASSWORD.SET_ERROR,
                data: error,
            });
        };
    },
};
