import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { toast } from "aphrodite-react";
import { withTranslation } from "react-i18next";
import posthog from "posthog-js";

import IconLoad from "../../_components/svg/Load.jsx";

import { authActions, userActions } from "../../_actions";

import {
    authService,
    classListService,
    flowService,
    postMessageService,
    settingsService,
    userService,
} from "../../_services";

import { routesConstants as ROUTES } from "../../_constants";
import { Avatar } from "@ingresse/aphrodite";
import isFromIngresseDomain from "../../_utils/isFromIngresseDomain";

class AuthUserConfirm extends React.Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            picture: "/assets/images/loader.svg",
            user: {
                photo: "",
                fullName: "",
                email: "",
            },
        };

        this.unmounted = false;
        this.logout = this.logout.bind(this);
        this.goAhead = this.goAhead.bind(this);
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    componentWillMount() {
        const { dispatch, user, auth, history } = this.props;

        if (!auth.token) {
            authService.logout();
            dispatch(userActions.setData(null));

            setTimeout(() => {
                history.push(ROUTES.AUTH.LOGIN + flowService.getSearch());
            }, 100);

            return;
        }

        userService
            .getData()
            .then((userData) => {
                if (!userData || this.unmounted) {
                    return;
                }

                this.setState(
                    {
                        user: userData,
                    },
                    () => {
                        dispatch(userActions.setData({ ...userData, ...user }));
                    }
                );
            })
            .catch(() => {
                this.logout();
            });
    }

    logout() {
        const { dispatch, history } = this.props;
        posthog.capture("auth:auth_change_profile_click");
        dispatch(authActions.logout());
        dispatch(userActions.setData(null));

        setTimeout(() => {
            history.push(ROUTES.AUTH.LOGIN + flowService.getSearch());
        }, 100);
    }

    goAhead() {
        const { auth, dispatch, loading, trusted } = this.props;
        const { device } = trusted || {};
        const { user } = this.state;
        let destiny = flowService.getParam("returnUrl") || flowService.getParam("after");
        
        posthog.capture("auth:auth_login_yes_continue_click");
        if (loading) return;

        classListService.add();
        authService
            .validateJWT(device)
            .then((responseCredentials) => {
                const {
                    jwt,
                    token,
                    usertoken: rawToken,
                } = responseCredentials || {};
                const usertoken = token || rawToken;
                const credentials = {
                    ...auth,
                    jwt,
                    usertoken,
                };

                dispatch(authActions.setCredentials({
                    ...credentials,
                    authToken: credentials.jwt,
                }));
                postMessageService.authConfirm();
                dispatch(authActions.confirm());

                const addUserTokenInDestiny = destiny && usertoken;

                if (addUserTokenInDestiny) {
                    destiny +=
                        (destiny.includes("?") ? "&" : "?") +
                        ("usertoken=" + usertoken);
                }

                if (destiny) {
                    flowService.gotTo(destiny);
                    return;
                }

                const deviceAlreadyValidateInCache = settingsService.get().APP.DEVICE_VALIDATION ?? false;
                const companyIsIngresse = user?.companyId === 1;
                const needAuthentication = (
                    !jwt &&
                    deviceAlreadyValidateInCache &&
                    companyIsIngresse &&
                    isFromIngresseDomain()
                )

                this.setState({
                    ...this.state,
                    confirmed: true,
                    needAuthentication: needAuthentication,
                });

            })
            .catch((error) => {
                console.error("Auth confirm error:\n", error);
                toast.error(
                    "Não foi possível prosseguir com sua autenticação. " +
                    "Tente ENTRAR novamente.",
                    {
                        autoClose: 10000,
                    }
                );

                setTimeout(() => {
                    this.logout();
                }, 2500);
            })
            .finally(() => {
                classListService.remove(500);
            });
    }

    render() {
        const { loading, isFromIngresseDomain, auth } = this.props;
        const { needAuthentication, confirmed } = this.state;

        const needConfirmMfa = auth?.data?.device?.mfa ?? false;
        const needRegisterMfa = (auth?.data?.device?.mfaRequired ?? false) && !needConfirmMfa;
        const goToDone = !needAuthentication && confirmed;
        const switchAuthenticate = needAuthentication && confirmed;
        const dontShowTrusted = needConfirmMfa || needRegisterMfa;

        if (confirmed) {
            if (needRegisterMfa) {
                return <Redirect to={ROUTES.AUTH.REGISTER_TWOFA + flowService.getSearch()} />
            }

            if (needConfirmMfa) {
                return <Redirect to={ROUTES.AUTH.TWOFA + flowService.getSearch()} />
            }
        }

        if (switchAuthenticate) {
            if (!dontShowTrusted) {
                return <Redirect to={ROUTES.PRIVATE.TRUSTED + flowService.getSearch()} />
            }
        }

        if (goToDone) {
            return <Redirect to={ROUTES.PRIVATE.DONE + flowService.getSearch()} />
        }

        return (
            <div className="confirm">
                <div>
                    <h3 className="aph text-center m-0 m-5-bot p-5-top">
                        {this.props.t("AUTH.USER.TITLE")}
                    </h3>
                </div>
                <div className={"aph m-15-top m-20-bot"}>
                    {!this.state.user || !this.state.user.photo ? (
                        <IconLoad className="confirm__pic confirm__pic--unloaded" />
                    ) : (
                        <Avatar
                            className="confirm__pic"
                            src={this.state.user.photo}
                            alt="Foto"
                            loading="lazy"
                            name={this.state.user.fullName}
                            size={180}
                            styles={{
                                display: "block",
                                margin: "30px auto 0",
                            }}
                        />
                    )}
                </div>
                <div
                    style={{ color: !isFromIngresseDomain && "#9DA6AD" }}
                    className={
                        "confirm__name text-center" +
                        (this.state.user && this.state.user.name
                            ? ""
                            : " lazy lazy--30")
                    }
                >
                    {this.state.user.fullName}
                </div>
                <div
                    className={
                        "aph m-30-bot text-center text-grey" +
                        (this.state.user && this.state.user.email
                            ? ""
                            : " lazy lazy--20")
                    }
                >
                    {this.state.user.email}
                </div>
                <div className="aph m-30-top m-20-top-xs">
                    <div className="row top-xs center-xs">
                        <div className="col-xxs-12 col-xs-6 first-sm last-xs">
                            <div className="aph m-10-ver-xs">
                                <button
                                    className="aph btn btn--block btn--sm-text btn--bordered btn--primary"
                                    type="button"
                                    id="btnUserLogout"
                                    onClick={this.logout}
                                >
                                    {this.props.t("AUTH.USER.NO_LOGOUT")}
                                </button>
                            </div>
                        </div>
                        <div className="col-xxs-12 col-xs-6">
                            <div className="aph m-10-ver-xs">
                                <button
                                    className="aph btn btn--block btn--sm-text btn--primary"
                                    type="button"
                                    id="btnUserContinue"
                                    disabled={loading}
                                    onClick={this.goAhead}
                                >
                                    {this.props.t("AUTH.USER.YES_CONTINUE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { auth, user, trusted } = state;
    const { data } = user;
    const loading = !data ? true : false;

    return {
        auth: auth ? auth.data : null,
        user: data || null,
        isFromIngresseDomain: state.auth.isFromIngresseDomain,
        trusted,
        loading,
    };
}

export default withTranslation()(
    connect(mapStateToProps)(withRouter(AuthUserConfirm))
);
