import { useWindowSize } from '@ingresse/aphrodite';
import { toast, colors, COLORS, P, Icon, H3 } from 'aphrodite-react'
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import checkApiKeyCustomer from '../_utils/checkApiKeyCustomer';
import * as env from '../_utils/env';
import QRCode from 'qrcode';
import { authActions } from '../_actions';
import { settingsService } from '../_services';
import copyIcon from '../../assets/icons/copy.svg';

const AuthTwoFARegister = () => {
    const [uriValue, setUriValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [otpValue, setOtpValue] = useState('');
    const [errMessage, setErrMessage] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);

    const auth = useSelector((state) => state.auth);
    const { t } = useTranslation();
    const { md, lg, xl } = useWindowSize();
    const dispatch = useDispatch();

    const showQrCode = md || lg || xl;

    const { isFromIngresseDomain, companies, data, company } = auth;

    const toastErrorConfigs = { autoClose: 10000 };

    let apiKey = checkApiKeyCustomer();

    if (settingsService.get().companyLogin && company?.application?.apiKey) {
        apiKey = company?.application?.apiKey;
    }

    const isMiranteBackstage = window.location.href.includes('companyId=31');

    if (isMiranteBackstage) {
        apiKey = 'tDgFYzwDkGVTxWeAgQxs73Hrs74CaNn2';
    }

    const expectedEnv = env.name() ? `${env.name()}-` : '';
    const baseUrl = 'api.ingresse.com';

    const queryParams = () => {
        const expectedToken = companies ? company?.token : data?.token;

        return `?apikey=${apiKey}&usertoken=${expectedToken}`
    }

    const bearerToken = () => {
        const expectedJwt = companies ? company?.jwt : data?.jwt;

        return `Bearer ${expectedJwt}`;
    }

    const expectedParams = queryParams();
    const expectedBearerToken = bearerToken();

    const handleChangeOtpValue = (e) => {
        const {value} = e.target;

        setErrMessage('');
        if (value.length <= 6) setOtpValue(value);
    }

    const fetcher = async (path, body = {}) => {
        return fetch(`https://${expectedEnv}${baseUrl}${path}${expectedParams}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: expectedBearerToken,
            }
        });
    }

    const confirmTwoFa = async (e) => {
        e.preventDefault();
        setIsFetching(true);
        try {
            const response = await fetcher('/mfa/confirm', {otpCode: otpValue});
            const data = await response.json();

            if (!response.ok) throw {...data};

            if (!data.responseData.isValid) setErrMessage(t('AUTH.REGISTER_TWO_FA.ERR_MESSAGE'));
            else {
                setTimeout(() => {
                    toast.info(t('AUTH.REGISTER_TWO_FA.SUCCESS_REGISTER'), toastErrorConfigs);
                }, 250);

                setTimeout(() => {
                    dispatch(authActions.logout());
                }, 5000)
            }
        } catch (error) {
            const errorMessage = t('AUTH.REGISTER_TWO_FA.ERR_CONFIRM_REQUEST');
            setTimeout(() => {
                toast.error(errorMessage, toastErrorConfigs)
            }, 250)
        } finally {
            setIsFetching(false);
        }
    }

    const getUri = async () => {
        try {
            const response = await fetcher('/mfa/enable');
            const data = await response.json();

            if (!data || !data.responseData || !data.responseData.urlQrCode) {
                dispatch(authActions.logout());
                return;
            }

            setUriValue(data.responseData.urlQrCode);
        } catch (error) {
            setTimeout(() => {
                toast.error(t('AUTH.REGISTER_TW_FA.ERR_GET_REQUEST'), toastErrorConfigs)
            }, 250)
        } finally {
            setLoading(false);
        }
    }

    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(uriValue.split('secret=')[1]);
        setCopySuccess(true);

        setTimeout(() => {
            setCopySuccess(false);
        }, 3000);
    }

    useEffect(() => {
        getUri();
    }, []);

    useEffect(() => {
        if (uriValue) {
            QRCode.toCanvas(document.getElementById('qr-code'), uriValue, {});
        }
    }, [uriValue, loading, showQrCode]);

    console.log(uriValue);

    return (
        <div className="segment__content__limited">
            <div className="aph">
                <H3
                    center
                    bold
                    margin="5px 0 10px"
                    style={{ color: isFromIngresseDomain ? "black" : "white" }}
                >
                    {t('AUTH.REGISTER_TWO_FA.TITLE')}
                </H3>
                {showQrCode && (
                    <div className='aph m-10-bot text-center'>
                        {loading && (
                            <Icon
                                slug="loading"
                                width={20}
                                height={20}
                                color={COLORS.DARK_GREY}
                            />
                        )}
                        {!loading &&(
                            <canvas id='qr-code'>
                            </canvas>
                        )}
                        <form
                            className='aph form'
                            noValidate={true}
                            onSubmit={confirmTwoFa}
                        >
                            <div className='aph text-right'>
                                <label className='aph form__label' htmlFor="otp">
                                    {t('AUTH.REGISTER_TWO_FA.LABEL')}
                                </label>
                                <input
                                    id="otp"
                                    type="text"
                                    className={`aph ${
                                        !isFromIngresseDomain && "custom_mrt_input"
                                    } form__control input-' + (checkInputErrorState('name') ? 'red' : 'dark-grey')`}
                                    value={otpValue}
                                    disabled={isFetching || loading}
                                    onChange={handleChangeOtpValue}
                                />
                                <div className='aph form__helper text-red'>
                                    {errMessage}
                                </div>
                                <button
                                    className='aph m-5-top btn btn--block btn--primary'
                                    type="submit"
                                    disabled={isFetching || loading || otpValue.length < 6}
                                >
                                    {t('AUTH.REGISTER_TWO_FA.CONFIRM_BTN')}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                <ul className='aph list m-20-bot'>
                    <li
                        className='aph list__header'
                        style={{ color: colors.get("mercury", "original") }}
                        >
                        {t("AUTH.REGISTER_TWO_FA.DESCRIPTION")}
                    </li>
                    <ol
                        className='aph list'
                        style={{ listStyle: 'bullet' }}
                    >
                        <li
                            className='aph list__item'
                            style={{ color: colors.get("mercury", "original") }}
                        >
                            {t("AUTH.REGISTER_TWO_FA.INFO_STEPS.1")}
                        </li>
                        <ul
                            className='aph list m-5-bot m-5-top p-20-left'
                            style={{ listStyle: 'circle' }}
                        >
                            <li
                                className='aph list__item'
                                style={{ color: colors.get("mercury", "original") }}
                            >
                                <a
                                    className='aph btn--link' href='https://onelink.to/kvpwj2'
                                    target="_blank"
                                    pointer-event="none"
                                >
                                    Google Authenticator
                                </a>
                            </li>
                            <li
                                className='aph list__item'
                                style={{ color: colors.get("mercury", "original") }}
                            >
                                <a
                                    className='aph btn--link' href='https://onelink.to/cesyuc'
                                    target="_blank"
                                    pointer-event="none"
                                >
                                    Microsoft Authenticator
                                </a>
                            </li>
                            <li
                                className='aph list__item'
                                style={{ color: colors.get("mercury", "original") }}
                            >
                                <a
                                    className='aph btn--link' href='https://onelink.to/fpxaja'
                                    target="_blank"
                                    pointer-event="none"
                                >
                                    Authy
                                </a>
                            </li>
                        </ul>
                        <li
                            className='aph list__item'
                            style={{ color: colors.get("mercury", "original") }}
                        >
                            {!showQrCode ? (
                                <>
                                    <a
                                        className='aph btn--link' href={uriValue}
                                        target="_blank"
                                        pointer-event="none"
                                    >
                                        {t('AUTH.REGISTER_TWO_FA.INFO_STEPS.MOBILE.2.LINK')}
                                    </a> {t('AUTH.REGISTER_TWO_FA.INFO_STEPS.MOBILE.2.TEXT')}
                                    <br/><br/>
                                    <span>Caso tenha problemas com o link cadastre manualmente</span>
                                    <div className='mfa__uri-input'>
                                        <input
                                            type="text"
                                            value={uriValue.split('secret=')[1]}
                                        />
                                        {copySuccess ? <span>Copiado!</span> : (
                                            <button type="button" onClick={handleCopyClick}>
                                                <img src={copyIcon} alt="copiar!" />
                                            </button>
                                        )}
                                    </div>
                                    <br/>
                                </>
                            ) : (
                                t("AUTH.REGISTER_TWO_FA.INFO_STEPS.2")
                            )}
                        </li>
                        <li
                            className='aph list__item'
                            style={{ color: colors.get("mercury", "original") }}
                        >
                            {t("AUTH.REGISTER_TWO_FA.INFO_STEPS.3")}
                        </li>
                        <li
                            className='aph list__item'
                            style={{ color: colors.get("mercury", "original") }}
                        >
                            {t("AUTH.REGISTER_TWO_FA.INFO_STEPS.4")}
                        </li>
                        <li
                            className='aph list__item'
                            style={{color: colors.get("mercury", "original") }}
                        >
                            {t("AUTH.REGISTER_TWO_FA.INFO_STEPS.5")}
                        </li>
                    </ol>
                </ul>
                {!showQrCode && (
                    <div className='aph m-10-bot text-center'>
                        <form
                            className='aph form'
                            noValidate={true}
                            onSubmit={confirmTwoFa}
                        >
                            <div className='aph text-right'>
                                <label className='aph form__label' htmlFor="otp">
                                    {t('AUTH.REGISTER_TWO_FA.LABEL')}
                                </label>
                                <input
                                    id="otp"
                                    type="text"
                                    className={`aph ${
                                        !isFromIngresseDomain && "custom_mrt_input"
                                    } form__control input-' + (checkInputErrorState('name') ? 'red' : 'dark-grey')`}
                                    value={otpValue}
                                    disabled={isFetching || loading}
                                    onChange={handleChangeOtpValue}
                                />
                                <div className='aph form__helper text-red'>
                                    {errMessage}
                                </div>
                                <button
                                    className='aph m-5-top btn btn--block btn--primary'
                                    type="submit"
                                    disabled={isFetching || loading || otpValue.length < 6}
                                >
                                    {t('AUTH.REGISTER_TWO_FA.CONFIRM_BTN')}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AuthTwoFARegister;
