/* Constant */
import { messagesConstants as MESSAGES } from "../_constants";

/**
 * Ingresse API messages filters
 */
const api = {
    /**
     * Ingresse API Success
     *
     * @param {object} response
     *
     * @return {Promise} message
     */
    success: (response) => {
        return new Promise((resolve, reject) => {
            const { responseData, responseError } = response || {};
            const { message, status } = responseData || {};
            const { code } = responseError || {};

            if (!responseData && !code) {
                return reject(
                    MESSAGES.API.SUCCESS_MESSAGES[responseError[0]] ||
                        message ||
                        MESSAGES.API.NOT_RESPONDING
                );
            }

            if (code) {
                return reject(
                    MESSAGES.API.ERROR_CODES[code] ||
                        MESSAGES.API.NOT_RESPONDING,
                );
            }

            if (typeof status === "boolean" && !status) {
                if (typeof message === "object" && message.length) {
                    let error = "";

                    message.map((errorMsg) => {
                        error += errorMsg + " ";

                        return true;
                    });

                    return reject(error);
                }

                return reject(
                    MESSAGES.API.SUCCESS_MESSAGES[message] ||
                        MESSAGES.API.NOT_RESPONDING,
                );
            }

            return resolve(responseData);
        });
    },

    /**
     * Generic error message
     */
    errorGeneric: (optError) => {
        const { code } = optError || {};
        const optMsg = MESSAGES.API.ERROR_CODES[code] || MESSAGES[code];

        return optMsg || MESSAGES.API.NOT_RESPONDING;
    },
};

/* Export Filter as Object with Functions */
export const messagesFilter = {
    api: api,
};
