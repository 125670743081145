/* Packages */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

/* Services */
import {
    flowService as flow,
    postMessageService as postMessages,
    settingsService,
    settingsService as settings,
} from "../_services";
import { useTranslation } from "react-i18next";

/* Auth Ended Component */
function AuthDone(props) {
    /**
     * Component properties
     */
    const { userData, wasValidated } = props;

    const oauth = useSelector((state) => state.oauth);
    const isOauthLogin = oauth.credentials != null;

    /**
     * Local values
     */
    let redirectTimer = null;
    const [msgSent, setMsgSent] = useState(false);
    const { t } = useTranslation();

    /**
     * Redirect to the Company site after a while
     */
    function redirectWatch() {
        // const isSafari = /^((?!chrome|android).)*safari/i.test(
        //     navigator.userAgent,
        // );

        // if (isSafari) {
        //     const tokenStorage = localStorage.getItem("ing_1_token") || "";
        //     const redirectParams = !tokenStorage ? "" : `?usertoken=${tokenStorage}`;

        //     if (tokenStorage) {
        //         redirectTimer = setTimeout(() => {
        //             flow.goTo(window.location.href, redirectParams);
        //         }, 5000);
        //     }
        //     return;
        // }

        const SETTINGS = settings.get();
        const { COMPANY, app } = SETTINGS || {};
        const { URL } = COMPANY || {};
        const { usertoken } = props || {};
        const redirectParams = !usertoken ? "" : `?usertoken=${usertoken}`;

        if (
            app ||
            !URL ||
            flow.getParam("returnUrl") ||
            process.env.NODE_ENV === "development"
        ) {
            return;
        }

        redirectTimer = setTimeout(() => {
            flow.goTo(URL, redirectParams);
        }, 5000);
    }

    /**
     * Mount
     */
    useEffect(() => {
        redirectWatch();

        /**
         * Unmount
         */
        return () => {
            clearTimeout(redirectTimer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Did Update
     */
    useEffect(() => {
        if (!wasValidated || msgSent) {
            return;
        }
        setMsgSent(true);
        if (settingsService.get().companyLogin) {
            postMessages.authDone(props.company, props.companies);
        } else {
            postMessages.authSuccess({...userData, isOauthLogin: isOauthLogin});
        }
    }, [msgSent, userData, wasValidated]);

    /**
     * Render
     */
    return (
        <div className="aph p-20-ver text-center">
            <h3 className="aph text-center m-0">{t("AUTH.DONE.TITLE")}</h3>
            <div className="aph p-10-top text-grey">{t("AUTH.DONE.TEXT")}</div>
        </div>
    );
}

/**
 * Map application context state to Component props
 *
 * @param {object} state - context state
 *
 * @return {object}
 */
function mapStateToProps(state = {}) {
    const { auth, user } = state;
    const { data: userData } = user || {};
    const { data: authCredentials, company, companies } = auth || {};
    const { token: usertoken } = authCredentials || {};
    const wasValidated = authCredentials && userData ? true : false;

    return {
        wasValidated,
        usertoken,
        companies,
        company,
        userData: !wasValidated
            ? null
            : {
                  ...authCredentials,
                  ...userData,
              },
    };
}

/* Export Component */
export default connect(mapStateToProps)(AuthDone);
