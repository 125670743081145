/**
 * Mask User Phone Number
 *
 * @param {string} phone
 * @param {string} ddi
 *
 * @return {string} maskedPhone
 */
function maskedUserPhone(phone, ddi) {
    const prefix = "+" + ddi + " ";

    if (ddi === "55") {
        const dddPhone = phone.substring(0, 2);
        const phoneNumber = phone.substring(7);
        const maskedPhone = `(${dddPhone}) XXXXX ${phoneNumber}`;

        return prefix + maskedPhone;
    }

    const diff = phone.length - 4;
    const lastFour = phone.substring(diff);

    return prefix + "X".repeat(diff) + lastFour;
}

/**
 * Only digits
 *
 * @param {string} phone
 *
 * @return {string}
 */
function onlyDigits(phone) {
    return ((phone || "") + "").replace(/[^\d]/g, "");
}

/* Exporting */
export const phoneFilter = {
    maskedUserPhone,
    onlyDigits,
};
