import React from "react";
import { connect } from "react-redux";
import posthog from "posthog-js";

import { withTranslation } from "react-i18next";

import { authActions, passwordActions } from "../../_actions";

import { Link } from "react-router-dom";
import { PASSWORD, routesConstants as ROUTES } from "../../_constants";

import { validators } from "../../_utils";

import { classListService, settingsService } from "../../_services";

import FlowLink from "../../_components/FlowLink";

import IconEye from "../../_components/svg/EyeOpened.jsx";
import IconEyeActive from "../../_components/svg/EyeOpenedActive.jsx";
import { oauthActions } from "../../_actions/oauth.actions.js";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

class AuthFormLogin extends React.Component {
    constructor(props) {
        super(props);
        
        this.settings = settingsService.get();
        this.COMPANY = this.settings.COMPANY;
        this.IS_COMPANY_LOGIN = this.settings.companyLogin;

        this.props = props;
        this.state = {
            email: this.props.password.email,
            password: "",
            passwordType: "password",
            error: "",
            erroremail: false,
            submitted: false,
            success: false,
            rememberMe: false,
        };

        this.canBeSubmitted = this.canBeSubmitted.bind(this);
        this.validStateValue = this.validStateValue.bind(this);
        this.togglePasswordType = this.togglePasswordType.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRecover = this.handleRecover.bind(this);
    }
    

    componentWillReceiveProps(nextProps) {
        this.setState((previousState) => ({
            ...previousState,
            error: "",
        }));

        // Case of error
        if (nextProps.auth.hasOwnProperty("error")) {
            this.setState((previousState) => ({
                ...previousState,
                error: nextProps.auth.error,
                submitted: false,
            }));

            classListService.remove();
        }

        if (
            nextProps.oauth.isActive &&
            nextProps.oauth.hasOwnProperty("error")
        ) {
            this.setState((previousState) => ({
                ...previousState,
                error: nextProps.oauth.error,
                submitted: false,
            }));

            classListService.remove();
        }
    }

    canBeSubmitted() {
        let { email, password, erroremail } = this.state;

        return email && !erroremail && password;
    }

    validStateValue(key, value) {
        const _value = value || this.state[key];

        if (validators[key]) {
            const isInvalid = !validators[key](_value);

            this.setState((previousState) => ({
                ...previousState,
                ["error" + key]: isInvalid,
            }));

            return isInvalid;
        }

        return _value && _value !== "";
    }

    togglePasswordType() {
        const type = this.state.passwordType;

        this.setState((previousState) => ({
            ...previousState,
            passwordType: type !== "password" ? "password" : "text",
        }));
    }

    handleInput(evt) {
        const name = evt.target.name;
        const value = evt.target.value;

        this.setState(
            {
                [name]: value,
            },
            () => {
                this.validStateValue(name, value);

                if (name === "email" && !value) {
                    const { dispatch } = this.props;

                    dispatch(passwordActions.setEmail(""));
                }
            }
        );
    }

    handleSubmit(evt) {
        evt.preventDefault();
        posthog.capture('auth:auth_login_click')

        if (this.state.submitted || !this.canBeSubmitted()) {
            return;
        }

        classListService.add();

        this.setState((previousState) => ({
            ...previousState,
            submitted: true,
            passwordType: "password",
        }));
        const { dispatch, trusted } = this.props;
        const { hash } = this.props.password;
        const { email, password, rememberMe } = this.state;
        const { device } = trusted || {};
        const isPasswordReset = hash === PASSWORD.TRACK;
        const isOauthActive = this.props.oauth.isActive;

        dispatch(passwordActions.setHash(""));

        if (isOauthActive) {
            dispatch(
                oauthActions.login(email, password, this.props.oauth.flowId)
            );
            return;
        }
        dispatch(
            authActions.login(email, password, device, rememberMe, {
                isPasswordReset,
            })
        )
    }

    handleRecover() {
        const { dispatch } = this.props;
        const { email, invalidEmail } = this.state;
        posthog.capture('auth:auth_forgot_password_click');
        classListService.add();
        classListService.remove(500);

        if (!email || invalidEmail) {
            return;
        }

        dispatch(passwordActions.setEmail(email));
    }

    render() {
        const canSubmit = this.canBeSubmitted();
        const isBackoffice = window.location.href.includes("backoffice");
        const { isFromIngresseDomain } = this.props.auth;

        const attempts = sessionStorage.getItem("remainingAttempts") !== null ? sessionStorage.getItem("remainingAttempts") : 3;

        return (
            <form
                className="aph form"
                noValidate={true}
                onSubmit={this.handleSubmit}
            >
                <div className="aph m-10-bot">
                    <label className="aph form__label" htmlFor="email">
                        {isBackoffice
                            ? "E-MAIL"
                            : this.props.t("AUTH.LABELS.EMAIL")}
                    </label>
                    <input
                        className={`aph form__control ${
                            !isFromIngresseDomain && "custom_mrt_input"
                        }`}
                        type="email"
                        id="email"
                        name="email"
                        required={true}
                        disabled={!!this.state.submitted}
                        value={this.state.email}
                        onChange={this.handleInput}
                    />
                    {this.state.erroremail && (
                        <div className="wrapper-wrong-email">
                            <span>
                                {this.props.t("VALIDATIONS.EMAIL")}
                            </span>
                        </div>
                    )}
                </div>
                <div className="aph m-10-top m-20-bot">
                    <label className="aph form__label" htmlFor="password">
                        {isBackoffice
                            ? "SENHA"
                            : this.props.t("AUTH.LABELS.PASSWORD")}
                    </label>
                    <div className="aph form__control-with-icon">
                        <input
                            className={`aph form__control ${
                                !isFromIngresseDomain && "custom_mrt_input"
                            }`}
                            type={this.state.passwordType}
                            id="password"
                            name="password"
                            required={true}
                            disabled={!!this.state.submitted}
                            value={this.state.password}
                            onChange={this.handleInput}
                        />
                        <button
                            className={`aph form__control-with-icon__button`}
                            type="button"
                            id="btnPasswordToggle"
                            disabled={!!this.state.submitted}
                            onClick={this.togglePasswordType}
                            >
                            {this.state.passwordType === "password" ? (
                                <IconEye />
                            ) : (
                                <IconEyeActive />
                            )}
                        </button>
                    </div>
                            {(this.state.error) && (
                            <div className="aph form__helper text-red wrapper-attempts">
                                <div className="wrapper-msg-alert">
                                    <strong>
                                        {this.props.t("VALIDATIONS.INCORRECT_PASSWORD")}
                                        <span className="msg-attempts">
                                            {this.props.t("VALIDATIONS.ATTEMPTS_LEFT").replace('<@attempts>', attempts)}
                                        </span>
                                    </strong>
                                </div>
                                <div className="wrapper-msg-info">
                                    <span className="msg-info-text">
                                    {this.props.t("VALIDATIONS.AFTER_3_ATTEMPTS_RESET_PASSWORD")}
                                        <Link to="/password/recover" className="recovery-link">
                                        {this.props.t("VALIDATIONS.CLICK_HERE")}
                                        </Link>
                                        <span>
                                        {this.props.t("VALIDATIONS.TO_CHANGE_IT_NOW")}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        )}
                    {!this.IS_COMPANY_LOGIN && !this.state.error && (
                        <div className="aph form__helper">
                            <div className="text-right text-bold">
                                <FlowLink
                                    className={`aph ${
                                        !isFromIngresseDomain &&
                                        "custom_mrt_text_link"
                                    }`}
                                    to={ROUTES.PASSWORD.RECOVER}
                                    title={this.props.t(
                                        "AUTH.LOGIN.RECOVER.TITLE"
                                    )}
                                    onClick={this.handleRecover}
                                    id="linkPasswordRecover"
                                >
                                    {this.props.t("AUTH.LOGIN.RECOVER.TEXT")}
                                </FlowLink>
                            </div>
                        </div>
                    )}
                </div>
                <div className="aph m-30-ver show-xs" />
                <div
                    className={`aph m-20-too ${
                        this.IS_COMPANY_LOGIN ? "p-10-ver" : ""
                    }`}
                >
                    <button
                        className={`aph btn btn--block btn--primary ${
                            !isFromIngresseDomain && "custom_mrt_button_submit"
                        }`}
                        type="submit"
                        id="btnLoginSubmit"
                        disabled={!!(this.state.submitted || !canSubmit || (this.state.error && attempts <= 0))}
                    >
                        {this.props.t("AUTH.LOGIN.SUBMIT")}
                    </button>
                </div>
                {!this.IS_COMPANY_LOGIN && (
                    <div className="aph m-15-top p-10-bot">
                        <FlowLink
                            onClick={() => {posthog.capture('auth:auth_create_account_click')}}
                            className={`aph btn btn--block btn--bordered btn--primary ${
                                !isFromIngresseDomain &&
                                "custom_mrt_button_register"
                            }`}
                            id="linkLoginToRegister"
                            to={
                                this.props.oauth.flowId
                                    ? ROUTES.OAUTH.REGISTER
                                    : ROUTES.AUTH.REGISTER
                            }
                        >
                            {this.props.t("AUTH.CHOICES.REGISTER")}
                        </FlowLink>
                    </div>
                )}
            </form>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

export default withTranslation()(connect(mapStateToProps)(AuthFormLogin));
