import { isValid as isValidCPF } from "@fnando/cpf";

/* Services */
import { authService, ingresseService, settingsService, flowService } from "./";

/* Filter */
import { messagesFilter } from "../_filters";

/* Constant */
import { userConstants as USER } from "../_constants";

/* Values */
var data = null;

/**
 * Set user data
 *
 * @param {object} userData (optional)
 *
 * @return {object} user data or null
 */
const setData = (userData) => {
    if (userData && typeof userData === "object") {
        const apiKey = ingresseService.api().auth.getApiKey();
        const apiUrl = ingresseService.api().settings.url;
        const pictureIngresse = userData.pictures
            ? userData.pictures.find(({ type }) => type === "large")
            : null;
        userData.photo = pictureIngresse
            ? pictureIngresse.link
            : `${apiUrl}/user/${userData.id}/picture/?apikey=${apiKey}`;
        userData.fullName = userData.name.concat(" ", userData.lastname || "");
        userData.birthdate = userData.birthdate || "";

        if (userData.phone) {
            userData.ddi = userData.phone.ddi;
            userData.phone = userData.phone.number;
        }
    }

    return (data = userData || null);
};

/**
 * Clear user data and do logout
 */
const clearData = () => {
    const api = ingresseService.api();

    // Preserve Ingresse Session
    if (api.cookie.companyId === 1 && settingsService.get().companyLogin) {
        api.cookie.companyId = 9999;
        return;
    }

    data = null;

    authService.logout();
};

/**
 * Get user data
 *
 * @param {boolean} forceRequest
 *
 * @return {Promise}
 */
const getData = (forceRequest) => {
    return new Promise((resolve, reject) => {
        const api = ingresseService.api();
        const credentials = authService.getCredentials();

        if (
            !credentials ||
            !credentials.hasOwnProperty("userId") ||
            !credentials.hasOwnProperty("token") ||
            !credentials.userId ||
            !credentials.token
        ) {
            setData(null);
            resolve(null);
            return;
        }


        const _userId = parseInt(credentials.userId, 10);
        
        if (!forceRequest && data && data.id === _userId && data.name) {
            resolve(data);
            return;
        }

        api.getUserById(_userId, { usertoken: true })
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .then((responseFiltered) => {
                        const getDocumentUrl =
                            flowService.getParam("document") &&
                                isValidCPF(flowService.getParam("document"))
                                ? flowService.getParam("document")
                                : null;

                        if (!responseFiltered?.document && getDocumentUrl) {
                            authService
                                .register({
                                    id: _userId,
                                    document: getDocumentUrl,
                                })
                                .then(
                                    () => {
                                        const getUserData = Object.assign(
                                            {},
                                            responseFiltered,
                                            { document: getDocumentUrl },
                                        );

                                        resolve(setData(getUserData));
                                    },
                                    () => {
                                        resolve(setData(responseFiltered));
                                    },
                                );
                        } else {
                            resolve(setData(responseFiltered));
                        }
                    })
                    .catch((error) => {
                        clearData();
                        reject(error);
                    });
            })
            .catch((error) => {
                setData(null);
                reject(error.message || error);
            });

    });
};

/**
 * Get user data by ID
 *
 * @param {boolean} allFields
 * @param {boolean} forceRequest
 *
 * @return {Promise}
 */
const getById = (userId, userToken, query) => {
    return new Promise((resolve, reject) => {
        if (!userId || !userToken) {
            reject("user:invalid-get-params");
            return;
        }

        const api = ingresseService.api();
        const _userId = parseInt(userId, 10);
        const _query = {
            usertoken: userToken,
            fields: USER.FILTERS,
            ...query,
        };

        api.getUserById(_userId, _query)
            .then((response) => {
                messagesFilter.api
                    .success(response)
                    .then((responseFiltered) => {
                        resolve(setData(responseFiltered));
                    })
                    .catch((error) => {
                        clearData();
                        reject(error);
                    });
            })
            .catch((error) => {
                setData(null);
                reject(error.message || error);
            });
    });
};

/* Constant object to represent Service Functions */
export const userService = {
    data,
    getById,
    getData,
    setData,
    clearData,
};
