import { appConstants } from "../_constants";
import { customerConstants } from "../_constants/customer.constants";
import * as env from './env';

function checkApiKeyCustomer() {
    try {
        const params = new URLSearchParams(window.location.search);

        const extractedApiKeyIframe = params.get("hash") || '';
        const extractedcompanyHash = params.get("companyHash") || '';

        if (extractedcompanyHash in customerConstants) {
            return customerConstants[extractedcompanyHash];
        }
        if (extractedApiKeyIframe in customerConstants) {
            return customerConstants[extractedApiKeyIframe];
        }
    } catch (error) {
        console.error('extracting customer apikey:', error)
    }

    return env.apikey();
}

export default checkApiKeyCustomer;
