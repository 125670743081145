import trackers from "@ingresse/trackers";
import { appConstants } from "../_constants";

const { LEGITI } = appConstants.KEYS;
const isDevelopment = process.env.NODE_ENV === "development";
const isHomolog = !!new URLSearchParams(window.location.search).get("env");
const LEGITI_KEY = isHomolog || isDevelopment ? LEGITI.HML : LEGITI.PROD;

trackers({ legiti: LEGITI_KEY });

export const trackersService = trackers;
