/* Packages */
import Sdk from "ingresse-sdk";
import SDK2 from "@ingresse/sdk";

/* Actions */
import { authActions } from "../_actions";
import checkApiKeyCustomer from "../_utils/checkApiKeyCustomer";
import * as env from '../_utils/env';

/**
 * Ingresse SDK values
 */
let ing = null;
let api = null;

/**
 * Initialize API
 */
const init = (SETTINGS = {}) => {
    return (dispatch) => {
        const envName = env.name();

        const COMPANY = SETTINGS.COMPANY;
        const COMPANY_APP = SETTINGS.APP;
        const COMPANY_CK = !COMPANY_APP.COOKIE ? "" : `_${COMPANY_APP.COOKIE}`;
        const COMPANY_ID = COMPANY.ID + COMPANY_CK;
        const customerApiKey = checkApiKeyCustomer();
        const API_KEY = SETTINGS.apikey || customerApiKey;

        ing = new Sdk({
            companyId: COMPANY_ID,

            api: {
                env: envName,
            },
        });

        api = ing.api;
        api.cookie.companyId = COMPANY_ID + (envName ? "_" + envName : "");

        api.setEnv(envName);
        api.auth.setApiKey(API_KEY);

        dispatch(authActions.getCredentials());

        try {
            SDK2({
                apiKey: API_KEY,
                company: COMPANY_ID,
                env: envName,
            });
        } catch (error) {
            console.error("Ingresse SDK2 initialization error:\n", error);
        }
    };
};

/* Constant object to represent Service Functions */
export let ingresseService = {
    init: init,
    api: () => {
        return api;
    },
};
