import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import posthog from "posthog-js";

import { isValid as isValidCPF } from "@fnando/cpf";

import { authActions, passwordActions } from "../../_actions";

import {
    classListService,
    flowService,
    settingsService,
} from "../../_services";

import { phoneFilter } from "../../_filters";

import { toast } from "aphrodite-react";

import AuthPasswordValidate from "./AuthPasswordValidate";

import FormRegisterAndIncomplete from "../../_components/FormRegisterAndIncomplete";

import {
    messagesConstants as MESSAGES,
    routesConstants as ROUTES,
    textsConstants as TEXTS,
} from "../../_constants";

import { validators } from "../../_utils";
import { useTranslation, withTranslation } from "react-i18next";

const AuthFormRegister = (props) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const [shortForm, setShortForm] = useState('');
    const location = useLocation();

    const [focused, setFocused] = useState("");

    const [submitted, setSubmitted] = useState(false);

    const [canSubmit, setCanSubmit] = useState(false);

    const [error, setError] = useState("");

    const [passwordValidating, setPasswordValidating] = useState(false);

    const oauth = useSelector((state) => state.oauth);

    const [formData, setFormData] = useState({
        name: "",
        ddi: "",
        phone: "",
        nationality: "",
        document: "",
        birthdate: "",
        email: "",
        password: "",
        news: false,
    });

    const [dirty, setDirty] = useState({
        name: false,
        phone: false,
        nationality: false,
        document: false,
        birthdate: false,
        email: false,
        password: false,
    });

    const [invalid, setInvalid] = useState({
        name: false,
        phone: false,
        nationality: false,
        document: false,
        birthdate: false,
        email: false,
        password: false,
    });

    const [checkBox, setCheckBox] = useState(false);

    const { isFromIngresseDomain } = useSelector((state) => state.auth);

    const COMPANY = useMemo(() => settingsService.get().COMPANY, []);
    const LANGUAGE = i18n.language.slice(-2)

    const phoneErrors = useMemo(
        () => [
            MESSAGES.API.ERROR_CODES["1145"],
            MESSAGES.API.ERROR_CODES["1146"],
            MESSAGES.API.ERROR_CODES["1109"],
            MESSAGES.API.ERROR_CODES["1192"],
            t("VALIDATIONS.API.ERROR_CODES.1109"),
        ],
        []
    );

    const documentErrors = useMemo(
        () => [
            MESSAGES.API.ERROR_CODES["1032"],
            MESSAGES.API.ERROR_CODES["1060"],
            MESSAGES.API.ERROR_CODES["1061"],
            MESSAGES.API.ERROR_CODES["6020"],
            MESSAGES.API.ERROR_CODES["6086"],
        ],
        []
    );

    const birthdateErrors = useMemo(
        () => [
            MESSAGES.API.ERROR_CODES["1172"],
            MESSAGES.API.ERROR_CODES["1102"],
        ],
        []
    );

    const emailErrors = useMemo(
        () => [
            MESSAGES.API.ERROR_CODES["1001"],
            MESSAGES.API.ERROR_CODES["1006"],
            MESSAGES.API.ERROR_CODES["1007"],
            MESSAGES.API.ERROR_CODES["1142"],
            MESSAGES.API.ERROR_CODES["6063"],
        ],
        []
    );

    const formErrors = useMemo(
        () => phoneErrors.concat(documentErrors, emailErrors, birthdateErrors),
        [phoneErrors, documentErrors, emailErrors, birthdateErrors]
    );

    const termsText = isFromIngresseDomain
        ? t("AUTH.REGISTER.TERMS.DESCRIPTION") ?? ""
        : "Ao clicar em cadastrar, você aceita os nossos termos de serviço e privacidade.";


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const paramShortForm = params.get('simpleRegistration');
        if (paramShortForm) {
            setShortForm(paramShortForm);
        }
    }, [location]);

    useEffect(() => {
        posthog.capture("auth:register_page_view");
        const fromUrl = {
            name: decodeURIComponent(flowService.getParam("name") || ""),
            ddi: decodeURIComponent(flowService.getParam("ddi") || "55"),
            phone: decodeURIComponent(flowService.getParam("phone") || ""),
            nationality: decodeURIComponent(
                flowService.getParam("nationality") || ""
            ),
            document: decodeURIComponent(
                flowService.getParam("document") || ""
            ),
            birthdate: decodeURIComponent(
                flowService.getParam("birthdate") || ""
            ),
            email: decodeURIComponent(flowService.getParam("email") || ""),
        };

        setFormData((state) => ({
            ...state,
            ...fromUrl,
        }));
    }, []);

    useEffect(() => {
        setFormData((state) => ({
            ...state,
            ...oauth.userData,
        }));
    }, [oauth.userData]);

    useEffect(() => {
        setFormData((state) => ({
            ...state,
            email: props.password.email,
        }));
    }, [props.password]);

    useEffect(() => {
        if (passwordValidating) {
            setCanSubmit(false);
            return;
        }

        let disableSubmit = true;

        for (let keyInvalid in invalid) {
            if (
                disableSubmit &&
                !(shortForm && ["nationality", "birthdate", "document"].includes(keyInvalid))
            ) {
                disableSubmit = !invalid[keyInvalid];
            }
        }

        if (disableSubmit) {
            for (let keyData in formData) {
                if (
                    disableSubmit &&
                    keyData !== "news" &&
                    keyData !== "birthDate" &&
                    !(shortForm && ["nationality", "birthdate", "document"].includes(keyData)) &&
                    formData[keyData] === ""
                ) {
                    disableSubmit = false;
                }
            }
        }

        setCanSubmit(disableSubmit);
    }, [formData, invalid, passwordValidating, shortForm]);

    useEffect(() => {
        if (props?.auth?.error) {
            setError(props.auth.error);
            setSubmitted(false);
            classListService.remove();
            return;
        }

        /**
         * User type "incomplete" means:
         *
         * When this fresh-registered email previously received a ticket from another person,
         * will need to confirm the email address based on a link sended to mailbox of this same email.
         **/
        if (props?.user?.data?.type === "incomplete") {
            classListService.remove();
            history.push(ROUTES.AUTH.LOGIN + history.location.search);
            setTimeout(() => {
                toast.info(MESSAGES.INCOMPLETE.CONFIRM_ON_EMAIL, {
                    autoClose: 15000,
                });
            }, 200);
        }
    }, [props.auth, props.user, history]);

    function handleBlur() {
        setFocused("");
    }

    function handleFocus(evt) {
        const { target } = evt || {};
        const { name } = target || {};

        if (!name) {
            setFocused("");
            return;
        }

        setFocused(name);
        setDirty((currentDirty) => ({
            ...currentDirty,
            [name]: true,
        }));
    }

    function setCountryCode(ddi) {
        setFormData((state) => ({ ...state, ddi }));
    }

    function setPassword(isInvalid, password) {
        if (password || password === "") {
            setFormData((state) => ({ ...state, password }));
        }

        setInvalid((stateInvalid) => ({
            ...stateInvalid,
            password: isInvalid,
        }));
    }

    function setPasswordHasRequested(passwordValidating) {
        setPasswordValidating(passwordValidating);
    }

    const checkInputErrorState = useCallback(
        (inputName) => {
            return (
                invalid[inputName] ||
                (!invalid[inputName] &&
                    !formData[inputName] &&
                    dirty[inputName])
            );
        },
        [dirty, formData, invalid]
    );

    const validStateValue = useCallback(
        (key, value) => {
            if (shortForm && ["nationality", "birthdate", "document"].includes(key)) {
                setInvalid((state) => ({
                    ...state,
                    [key]: false,
                }));
                return;
            }

            const _value = value || formData[key] || "";
            let isInvalid = false;

            if (validators[key]) {
                isInvalid =
                    key === "phone" && formData.ddi !== "55"
                        ? _value.length < 5
                        : !validators[key](_value);
            } else if (
                key === "document" &&
                formData.nationality === TEXTS.AUTH.NATIONALITY.BRAZILIAN
            ) {
                isInvalid = !isValidCPF(value);
            }

            const clearDocumentInvalid =
                key === "nationality" ? { document: false } : null;

            setInvalid((state) => ({
                ...state,
                [key]: isInvalid,
                ...clearDocumentInvalid,
            }));
        },
        [formData, shortForm]
    );


    const handleInput = useCallback(
        (evt, key, value) => {
            const target = evt && evt.target ? evt.target : {};
            const _key = key || target.name || target.id;
            const _value =
                _key === "news" || _key === "terms"
                    ? target.checked
                    : value || target.value;

            const clearDocumentField =
                _key === "nationality" ? { document: "" } : null;

            setFormData((state) => ({
                ...state,
                [_key]:
                    _key === "phone" ? phoneFilter.onlyDigits(_value) : _value,
                ...clearDocumentField,
            }));

            validStateValue(_key, _value);

            if (_key === "email" && !_value) {
                props.dispatch(passwordActions.setEmail(""));
            }
        },
        [props, validStateValue]
    );

    const handleSubmit = useCallback(
        (evt) => {
            evt.preventDefault();
            posthog.capture("auth:register_register_click");

            if (submitted || !canSubmit) {
                return;
            }

            setSubmitted(true);
            setError("");

            classListService.add();
            classListService.segmentGrowth(0, true);

            const { device } = props.trusted || {};

            const filteredFormData = { ...formData };
            const additionalFields = {}

            if (shortForm) {
                delete filteredFormData.nationality;
                delete filteredFormData.birthdate;
                delete filteredFormData.document;

                additionalFields.simple_registration = true;
            }

            props.dispatch(
                authActions.register(
                    {
                        ...filteredFormData,
                        inactiveToken: flowService.getParam("token") || "",
                        additionalFields: JSON.stringify(additionalFields)
                    },
                    device,
                    true
                )
            );
        },
        [canSubmit, formData, props, shortForm, submitted]
    );

    return (
        <form className="aph form" noValidate={true} onSubmit={handleSubmit}>
            <div className="aph m-10-bot">
                <label className="aph form__label" htmlFor="name">
                    {t("AUTH.LABELS.NAME")} <span className="text-red">*</span>
                </label>
                <input
                    className={`aph ${!isFromIngresseDomain && "custom_mrt_input"
                        } form__control input-' + (checkInputErrorState('name') ? 'red' : 'dark-grey')`}
                    id="name"
                    name="name"
                    type="text"
                    required
                    value={formData.name}
                    onBlur={handleBlur}
                    onChange={handleInput}
                    onFocus={handleFocus}
                    disabled={submitted}
                />
                <div
                    className={
                        "aph form__helper text-" +
                        (focused !== "name" ? "red" : "dark-grey")
                    }
                >
                    {checkInputErrorState("name") && t("VALIDATIONS.NAME")}
                </div>
            </div>

            <FormRegisterAndIncomplete
                setCountryCode={setCountryCode}
                formData={formData}
                submitted={submitted}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                handleInput={handleInput}
                error={error}
                focused={focused}
                invalid={invalid}
                phoneErrors={phoneErrors}
                documentErrors={documentErrors}
                birthdateErrors={birthdateErrors}
                disabled={submitted}
                checkInputErrorState={checkInputErrorState}
                shortForm={shortForm}
            />

            <div className="aph m-10-ver">
                <label className="aph form__label" htmlFor="email">
                    {t("AUTH.LABELS.EMAIL")}
                    <span className="text-red">*</span>
                </label>
                <input
                    className={`aph ${!isFromIngresseDomain && "custom_mrt_input"
                        } form__control input-' + (checkInputErrorState('email') ? 'red' : 'dark-grey')`}
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={formData.email}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleInput}
                    disabled={submitted}
                />
                <div
                    className={
                        "aph form__helper text-" +
                        (focused !== "email" ? "red" : "dark-grey")
                    }
                >
                    {checkInputErrorState("email") && t("VALIDATIONS.EMAIL")}
                    {emailErrors.includes(error) && (
                        <div className="text-red">{error}</div>
                    )}
                </div>
            </div>

            <div className="aph m-10-ver">
                <AuthPasswordValidate
                    submitted={submitted}
                    handleFocus={handleFocus}
                    handleInput={handleInput}
                    focused={focused}
                    password={formData.password}
                    invalidPassword={invalid.password}
                    handleCallback={setPassword}
                    setPasswordHasRequested={setPasswordHasRequested}
                />
            </div>

            <div className="aph m-20-top">
                <input
                    className="aph form__control"
                    id="terms"
                    name="terms"
                    type="checkbox"
                    value={checkBox}
                    onChange={() => setCheckBox(!checkBox)}
                />
                <label
                    className={`aph form__label ${!isFromIngresseDomain && "custom_mrt_label_terms"
                        }`}
                    htmlFor="terms"
                >
                    {termsText}
                    {isFromIngresseDomain && (
                        <>
                            <a
                                className="aph"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={COMPANY.TERMS[LANGUAGE]}
                            >
                                {t("AUTH.REGISTER.TERMS.SERVICE")}
                            </a>
                            {!COMPANY.PRIVACY[LANGUAGE] ? (
                                "."
                            ) : (
                                <span>
                                    {t("AUTH.REGISTER.TERMS.CONCAT")}
                                    <a
                                        className="aph"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={COMPANY.PRIVACY[LANGUAGE]}
                                    >
                                        {t("AUTH.REGISTER.TERMS.PRIVACY")}
                                    </a>
                                    .
                                </span>
                            )}
                        </>
                    )}
                </label>
            </div>
            {isFromIngresseDomain && (
                <div className="aph m-20-top m-25-bot">
                    <input
                        className="aph form__control"
                        id="news"
                        name="news"
                        type="checkbox"
                        value={formData.news}
                        onChange={handleInput}
                    />
                    <label className="aph form__label" htmlFor="news">
                        {t("AUTH.LABELS.NEWSLETTER")}
                    </label>
                </div>
            )}
            <div className="aph m-10-ver show-xs"></div>
            <div className="aph m-5-top p-10-bot">
                <button
                    className="aph btn btn--block btn--primary"
                    id="btnRegisterSubmit"
                    type="submit"
                    disabled={submitted || !canSubmit || !checkBox}
                >
                    {t("AUTH.REGISTER.SUBMIT")}
                </button>
            </div>
            {error && !formErrors.includes(error) ? (
                <div className="aph form__helper text-center text-red">
                    {error}
                </div>
            ) : (
                ""
            )}
        </form>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default withTranslation()(connect(mapStateToProps)(AuthFormRegister));
