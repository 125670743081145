/**
 * Default
 */
const defaultElementId = "authContainer";
const defaultClassName = "load--active";

let lastWidth = window.innerWidth;
let lastHeight = 0;

/**
 * Add
 *
 * @param {integer} delay
 * @param {string} elementId - optional
 * @param {string} className - optional
 */
const add = (delay, elementId, className) => {
    const container = document.getElementById(elementId || defaultElementId);

    if (!container) {
        return;
    }

    setTimeout(() => {
        container.classList.add(className || defaultClassName);
    }, delay || 0);
};

/**
 * Remove
 *
 * @param {integer} delay
 * @param {string} elementId - optional
 * @param {string} className - optional
 */
const remove = (delay, elementId, className) => {
    const container = document.getElementById(elementId || defaultElementId);

    if (!container) {
        return;
    }

    setTimeout(() => {
        container.classList.remove(className || defaultClassName);
    }, delay || 0);
};

/**
 * Watch for element Height changes
 *
 * @param {object} DOM Element
 * @param {function} callback
 */
const heightChange = (element, callback) => {
    var lastHeight = element.clientHeight,
        newHeight;

    (function run() {
        newHeight = element.clientHeight;

        if (lastHeight !== newHeight) {
            callback();
        }

        lastHeight = newHeight;

        if (element.heightChangeTimer) {
            clearTimeout(element.heightChangeTimer);
        }

        element.heightChangeTimer = setTimeout(run, 200);
    })();
};

/**
 * Listen to Segment Growth
 *
 * @param {integer} delay
 * @param {boolean} hasRegister
 */
const segmentGrowth = (delay, hasRegister) => {
    const segment = document.getElementById(defaultElementId);
    const segmentContent = document.getElementById("authContent");

    if (!segment || !segmentContent) {
        return;
    }

    setTimeout(() => {
        segment.style.height = 80 + segmentContent.offsetHeight + "px";

        /* Set listeners to auto-adapt own height */
        if (!segmentContent.watching) {
            segmentContent.watching = true;

            heightChange(segmentContent, segmentGrowth);
        }

        /* Scroll to top when window width changes */
        if (
            window.innerWidth !== lastWidth ||
            segment.offsetHeight !== lastHeight
        ) {
            lastWidth = window.innerWidth;
            lastHeight = segment.offsetHeight;

            window.scrollTo(0, hasRegister ? 250 : 0);
        }
    }, delay || 0);
};

/* Constant object to represent Service Functions */
export let classListService = {
    add,
    remove,
    segmentGrowth,
};
