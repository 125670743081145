/* Packages */
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

/* Components */
import AuthCompany from "../../_components/AuthCompany.jsx";

/* Actions */
import { authActions } from "../../_actions";

/* Services */
import { flowService, classListService } from "../../_services";

/* Constants */
import {
    textsConstants as TEXTS,
    routesConstants as ROUTES,
} from "../../_constants";

/* Auth Companies Component */
class AuthCompaniesList extends React.Component {
    /* Constructor */
    constructor(props) {
        super(props);

        this.props = props;

        let companies = this.props.auth.companies;
        let companiesList = [];

        if (companies) {
            companies.map((company) => {
                return companiesList.push(company);
            });
        }

        this.state = {
            companies: companiesList,
        };

        this.logout = this.logout.bind(this);
        this.select = this.select.bind(this);
    }

    /* Component Will Mount */
    componentWillMount() {
        if (this.state.companies.length !== 1) {
            return;
        }

        /* Select unique company */
        this.select(this.state.companies[0]);
    }

    /* Logout */
    logout() {
        const { dispatch } = this.props;

        dispatch(authActions.logout());

        return <Redirect to={ROUTES.AUTH.LOGIN + flowService.getSearch()} />;
    }

    /* Select Company */
    select(company) {
        const { dispatch, trusted } = this.props;

        const destiny =
            flowService.getParam("returnUrl") || flowService.getParam("after");
        classListService.add();

        dispatch(authActions.selectCompany(company, this.state.companies));

        const needConfirmMfa = company?.device?.mfa;
        const needRegisterMfa = company?.device?.mfaRequired && !needConfirmMfa;
        const dontShowTrusted = needConfirmMfa || needRegisterMfa;
        const isVerified = trusted?.verified;

        if (destiny) {
            flowService.goTo(destiny);
            return "";
        }

        if (!isVerified) {
            if (needRegisterMfa) {
                return <Redirect to={ROUTES.AUTH.REGISTER_TWOFA + flowService.getSearch()} />
            }

            if (needConfirmMfa) {
                return <Redirect to={ROUTES.AUTH.TWOFA + flowService.getSearch()} />
            }

            if(!dontShowTrusted) {
                return <Redirect to={ROUTES.PRIVATE.TRUSTED + flowService.getSearch()} />;
            }
        }

        return <Redirect to={ROUTES.PRIVATE.DONE + flowService.getSearch()} />;
    }

    render() {
        return (
            <div className="companies">
                <div>
                    <h3 className="aph text-center m-0 m-5-bot p-15-top p-5-top-xs">
                        {TEXTS.AUTH.COMPANIES.TITLE}
                    </h3>
                </div>
                <div className="aph m-20-top m--10-bot m-20-top-xs">
                    <div className="row center-xs top-xs">
                        {this.state.companies.map((companyProps) => (
                            <AuthCompany
                                id={companyProps.company.id}
                                key={companyProps.company.id}
                                item={companyProps}
                                callback={this.select}
                                className="col-xxs-6 col-xs-4"
                            />
                        ))}
                    </div>
                </div>
                <div className="aph m-35-top m-25-top-xs">
                    <div className="row top-xs center-xs">
                        <div className="col-xxs-12 col-xs-6 first-sm last-xs">
                            <div className="aph">
                                <button
                                    className="aph btn btn--link btn--block btn--primary"
                                    type="button"
                                    id="btnCompaniesLogout"
                                    onClick={this.logout}
                                >
                                    {TEXTS.AUTH.COMPANIES.BACK}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/* Map State to Props */
function mapStateToProps(state) {
    return state;
}

/* Export Component */
export default connect(mapStateToProps)(AuthCompaniesList);
