import { oauthConstants as OAUTH } from "../_constants";

import { oauthService, postMessageService, userService } from "../_services";
import { authActions } from "./auth.actions";

/**
 * Login Success
 *
 * @param {object} data - user data
 */
const successLogin = (data) => {
    return {
        type: OAUTH.LOGIN_SUCCESS,
        payload: {
            ...data,
        },
    };
};

const successConsent = (data) => {
    return {
        type: OAUTH.CONSENT_SUCCESS,
        payload: {
            ...data,
        },
    };
};

const successCredentials = (data) => {
    return {
        type: OAUTH.CREDENTIALS_SUCCESS,
        payload: {
            ...data,
        },
    };
};

function setOauthSettings(settings) {
    return {
        type: OAUTH.SET_OAUTH_SETTINGS,
        payload: {
            ...settings,
        },
    };
}

function activateOauth() {
    return {
        type: OAUTH.ACTIVATE_OAUTH,
        payload: true,
    };
}

function login(email, password, flowId = {}) {
    /**
     * Login Failure
     *
     * @param {object} error
     */
    const failure = (error) => {
        return {
            type: OAUTH.LOGIN_FAILURE,
            error: error,
        };
    };

    return (dispatch) => {
        oauthService.login(email, password, flowId).then(
            (response) => {
                dispatch(successLogin(response));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };
}

function consent(flowId = {}) {
    /**
     * Consent Failure
     *
     * @param {object} error
     */
    const failure = (error) => {
        return {
            type: OAUTH.CONSENT_FAILURE,
            error: error,
        };
    };

    return (dispatch) => {
        oauthService.consent(flowId).then(
            (response) => {
                dispatch(successConsent(response));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };
}

function getCredentials(openidToken) {
    /**
     * Credentials Failure
     *
     * @param {object} error
     */
    const failure = (error) => {
        postMessageService.oauthCredentialsFailure()
        return {
            type: OAUTH.CREDENTIALS_FAILURE,
            error: error,
        };
    };

    return (dispatch) => {
        oauthService.getCredentials(openidToken).then(
            (response) => {
                dispatch(authActions.setCredentials(response.data));
                dispatch(successCredentials({ credentials: response.data }));
                userService.getData().then(
                    (userData) => {
                        const resolved = Object.assign(
                            {},
                            response.data,
                            userData
                        );
                        dispatch(authActions.successLogin(resolved));
                    },
                    (error) => {
                        dispatch(failure(error));
                    }
                );
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };
}

function clearCredentials() {
    return {
        type: OAUTH.CLEAR_CREDENTIALS,
        payload: true,
    };
}

function getUserData(flowId) {
    return (dispatch) => {
        oauthService.getUserData(flowId).then((response) => {

            dispatch({
                type: OAUTH.SET_USER_DATA,
                payload: response,
            });
        });
    };
}

export const oauthActions = {
    setOauthSettings,
    activateOauth,
    login,
    successLogin,
    successConsent,
    consent,
    getCredentials,
    clearCredentials,
    getUserData,
};
