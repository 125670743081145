/* Constant */
import { PASSWORD } from "../_constants";

/* Initial State */
const initialState = {
    email: "",
    hash: "",
    error: "",
};

/* Export Reducer */
const password = (state = initialState, action) => {
    switch (action.type) {
        case PASSWORD.SET_EMAIL:
            return {
                ...state,
                email: action.data,
            };

        case PASSWORD.SET_HASH:
            return {
                ...state,
                hash: action.data,
            };

        case PASSWORD.SET_ERROR:
            return {
                ...state,
                error: action.data,
            };

        default:
            return state;
    }
};

/* Export Reducer */
export default password;
