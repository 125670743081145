/* URLs Contants */
export const urlsConstants = {
    FRESHDESK: "https://ingresse.freshdesk.com",
    PASSWORD: {
        RECOVER: "https://www.ingresse.com/password/recover",
        FRESHDESK: "https://ingresse.freshdesk.com/support/tickets/new",
    },
    TERMS: {
        SERVICE: "https://sobre.ingresse.com/termos-de-servico",
        PRIVACY: "https://sobre.ingresse.com/termos-de-privacidade",
    },
};
