/* Password Constants */
import { REDIRECT } from "../_constants";

/* Redirect Actions */
export const redirectActions = {
    /**
     * Set URL
     *
     * @param {string} url
     */
    setUrl: (url) => {
        return (dispatch) => {
            dispatch({
                type: REDIRECT.SET_URL,
                data: url,
            });
        };
    },
};
