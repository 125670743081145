/* Companies Constants */
export const companiesConstants = {
    1: {
        ID: 1,
        SLUG: "ingresse",
        NAME: "Ingresse",
        BRAND: "ingresse",
        LOGO: "/assets/images/application-logo--inverse.svg",
        URL: "https://www.ingresse.com/",
        APPS: {
            DEFAULT: {
                FACEBANK: true,
                FACEBOOK: true,
                DEFAULT: true,
                BRAND: "ingresse",
                INVERSE: "#FFFFFF",
                PRIMARY: "#FCA311",
                SECONDARY: "#00A5DB",
                GRADIENT: {
                    START: "#FEA400",
                    END: "#F8561F",
                },
            },
            BACKSTAGE: {
                DEVICE_VALIDATION: true,
                DEFAULT: true,
                BRAND: "backstage",
                INVERSE: "#FFFFFF",
                PRIMARY: "#00A5DB",
                SECONDARY: "#00A5DB",
                GRADIENT: {
                    START: "#38BBBB",
                    END: "#0CA9D5",
                },
            },
            BACKOFFICE: {
                DEVICE_VALIDATION: true,
                BRAND: "backoffice",
                INVERSE: "#FFFFFF",
                PRIMARY: "#2D2D2D",
                SECONDARY: "#00A5DB",
                GRADIENT: {
                    START: "#7A8085",
                    END: "#2D2D2D",
                },
                FOOTER: "ÁREA ADMINISTRATIVA",
                LOGIN: "company",
                KEYS: {
                    PROD: "636ee5b649b9663ee69c5c314473955f537a80d3",
                    HML: "",
                },
            },
            EMBEDSTORE: {
                DEVICE_VALIDATION: true,
                SIMPLE: true,
                FACEBANK: true,
                FACEBOOK: true,
                BRAND: "embedstore",
                INVERSE: "#FFFFFF",
                SECONDARY: "#00A5DB",
                GRADIENT: {
                    START: "#FFFFFF",
                    END: "#FFFFFF",
                },
            },
            TICKETSWAP: {
                FACEBOOK: true,
                FACEBANK: true,
                DEFAULT: true,
                COOKIE: "ticketswap",
                BRAND: "ingresse",
                INVERSE: "#FFFFFF",
                PRIMARY: "#00B6F0",
                SECONDARY: "#00B6F0",
                GRADIENT: {
                    START: "#00B6F0",
                    END: "#00B2F3",
                },
                KEYS: {
                    PROD: "t1waIvrUQ6Z4fxfF8bJdzrRHLVhA4896",
                    HML: "YtE4O16QoZ9M4uVMAgtwqaxUR1MWIv4I",
                },
                REDIRECT: "https://www.ticketswap.com",
            },
        },
        TERMS: {
            BR: "https://sobre.ingresse.com/termos-de-servico",
            ES: "https://sobre.ingresse.com/terminos-y-condiciones-del-servicio",
            US: "https://sobre.ingresse.com/terms-of-service",
        },
        PRIVACY: {
            BR: "https://sobre.ingresse.com/termos-de-privacidade",
            ES: "https://sobre.ingresse.com/poltica-de-privacidad",
            US: "https://sobre.ingresse.com/privacy-policy",
        },
        FOOTER: [
            {
                TEXT: "Ingresse",
                TITLE: "",
                HREF: "https://www.ingresse.com",
                TARGET: "_blank",
            },
            {
                TEXT: "Ajuda",
                TITLE: "Estaremos felizes em ajudar :)",
                HREF: "https://ingresse.freshdesk.com/",
                TARGET: "_blank",
            },
            {
                TEXT: "Termos",
                TITLE: "Conheça nossos Termos de Serviços",
                HREF: "https://sobre.ingresse.com/termos-de-servico",
                TARGET: "_blank",
            },
            {
                TEXT: "Privacidade",
                TITLE: "Entenda nossos Termos de Privacidade",
                HREF: "https://sobre.ingresse.com/termos-de-privacidade",
                TARGET: "_blank",
            },
        ],
        KEYS: {
            PROD: "172f24fd2a903fc0647b61d7112ee1b9814702be",
            HML: "e9424e72263bcab5d37ecb04e05505cf91d67639",
        },
    },
    2: {
        ID: 2,
        SLUG: "ingressocerto",
        NAME: "Ingresso Certo",
        BRAND: "Ingresso Certo",
        LOGO: "https://s3.amazonaws.com/ingresse-static/ic/Logo_IC.png",
        URL: "https://www.ingressocerto.com",
        APPS: {
            DEFAULT: {
                DEFAULT: true,
                BRAND: "backstage",
                INVERSE: "#FFFFFF",
                PRIMARY: "#095A6F",
                SECONDARY: "#2D2D2D",
                GRADIENT: {
                    START: "#095A6F",
                    END: "#2D2D2D",
                },
            },
        },
        TERMS: "https://www.ingressocerto.com/cancelamento",
        FOOTER: [
            {
                TEXT: "Ingresso Certo",
                TITLE: "Visite nossa página",
                HREF: "https://www.ingressocerto.com",
                TARGET: "_blank",
            },
            {
                TEXT: "Ajuda",
                TITLE: "Há um chat instâneo em nosso site. Estaremos felizes em ajudar :)",
                HREF: "https://www.ingressocerto.com",
                TARGET: "_blank",
            },
            {
                TEXT: "Privacidade",
                TITLE: "Conheça nossa Política de Privacidade",
                HREF: "https://www.ingressocerto.com/politica-de-privacidade",
                TARGET: "_blank",
            },
            {
                TEXT: "Termos",
                TITLE: "Conheça nossos Termos e Regras",
                HREF: "https://www.ingressocerto.com/cancelamento",
                TARGET: "_blank",
            },
        ],
        KEYS: {
            PROD: "57e865785451090b7eafbebc650a3ff47c34ed2e",
            HML: "",
        },
    },
    4: {
        ID: 4,
        SLUG: "foodpass",
        NAME: "Foodpass",
        BRAND: "foodpass",
        LOGO: "https://s3.amazonaws.com/ingresse-static/foodpass/application-logo--inverse--square.png",
        URL: "https://foodpass.com.br",
        APPS: {
            DEFAULT: {
                FACEBOOK: true,
                DEFAULT: true,
                BRAND: "backstage",
                INVERSE: "#FFFFFF",
                PRIMARY: "#FF3C00",
                SECONDARY: "#2D2D2D",
                GRADIENT: {
                    START: "#7D7D7D",
                    END: "#2D2D2D",
                },
            },
        },
        TERMS: "https://foodpass.com.br/termos-e-condicoes",
        FOOTER: [
            {
                TEXT: "Foodpass",
                TITLE: "",
                HREF: "https://foodpass.com.br",
                TARGET: "_blank",
            },
            {
                TEXT: "Ajuda",
                TITLE: "Estaremos felizes em ajudar :)",
                HREF: "https://foodpass.com.br/ajuda",
                TARGET: "_blank",
            },
            {
                TEXT: "Termos",
                TITLE: "Conheça nossos Termos e Condições de Serviços",
                HREF: "https://foodpass.com.br/termos-e-condicoes",
                TARGET: "_blank",
            },
        ],
        KEYS: {
            PROD: "badd43d23b05addcebf779c09e64dae67daba862",
            HML: "",
        },
    },
    8: {
        ID: 8,
        SLUG: "blacktag",
        NAME: "BlackTag",
        BRAND: "blacktag",
        LOGO: "https://s3.amazonaws.com/ingresse-static/blacktag/logo.png",
        URL: "https://blacktag.com.br",
        APPS: {
            DEFAULT: {
                FACEBOOK: true,
                DEFAULT: true,
                BRAND: "backstage",
                INVERSE: "#FFFFFF",
                PRIMARY: "#FAB73B",
                SECONDARY: "#FAB73B",
                GRADIENT: {
                    START: "#2D2D2D",
                    END: "#5A5A5A",
                },
            },
        },
        TERMS: "https://blacktag.com.br/termos-e-condicoes",
        FOOTER: [
            {
                TEXT: "BlackTag",
                TITLE: "",
                HREF: "https://blacktag.com.br",
                TARGET: "_blank",
            },
            {
                TEXT: "Ajuda",
                TITLE: "Estaremos felizes em ajudar :)",
                HREF: "https://blacktag.com.br/contato",
                TARGET: "_blank",
            },
            {
                TEXT: "Privacidade",
                TITLE: "Conheça nossa Política de Privacidade",
                HREF: "https://blacktag.com.br/politica-de-privacidade",
                TARGET: "_blank",
            },
            {
                TEXT: "Termos",
                TITLE: "Conheça nossos Termos e Condições de Uso",
                HREF: "https://blacktag.com.br/termos-de-uso",
                TARGET: "_blank",
            },
        ],
        KEYS: {
            PROD: "Nz0nHq8Y7vkvYjRNGJDCkBgB5TME7fKf",
            HML: "",
        },
    },
    9: {
        ID: 9,
        SLUG: "t4f",
        NAME: "Tickets For Fun",
        BRAND: "t4f",
        LOGO: "https://s3.amazonaws.com/ingresse-static/t4f/T4F_Logo.png",
        URL: "https://www.ticketsforfun.com.br",
        APPS: {
            DEFAULT: {
                DEFAULT: true,
                BRAND: "backstage",
                INVERSE: "#FFFFFF",
                PRIMARY: "#010D1B",
                SECONDARY: "#ECC430",
                GRADIENT: {
                    START: "#021A36",
                    END: "#010D1B",
                },
            },
        },
        TERMS: "https://premier.ticketsforfun.com.br/umbrella/atendimento/nossas-politicas.html",
        FOOTER: [
            {
                TEXT: "Tickets For Fun",
                TITLE: "",
                HREF: "https://www.ticketsforfun.com.br",
                TARGET: "_blank",
            },
            {
                TEXT: "Ajuda",
                TITLE: "Estaremos felizes em ajudar :)",
                HREF: "https://premier.ticketsforfun.com.br/membership/contactus.aspx",
                TARGET: "_blank",
            },
            {
                TEXT: "Termos",
                TITLE: "Conheça nossos Termos e Políticas",
                HREF: "https://premier.ticketsforfun.com.br/umbrella/atendimento/nossas-politicas.html",
                TARGET: "_blank",
            },
        ],
        KEYS: {
            PROD: "q1vm5GlYDB0nWlfSJszRxmCGKQ9oNTmz",
            HML: "",
        },
    },
    13: {
        ID: 13,
        SLUG: "futebolcard",
        NAME: "FutebolCard",
        BRAND: "FutebolCard",
        LOGO: "https://s3.amazonaws.com/ingresse-static/futebolcard/futebolcard.png",
        URL: "https://www.futebolcard.com",
        APPS: {
            DEFAULT: {
                FACEBOOK: true,
                DEFAULT: true,
                BRAND: "backstage",
                INVERSE: "#FFFFFF",
                PRIMARY: "#F27A30",
                SECONDARY: "#262626",
                GRADIENT: {
                    START: "#464646",
                    END: "#262626",
                },
            },
        },
        TERMS: "https://www.futebolcard.com/institutional/terms-of-use",
        FOOTER: [
            {
                TEXT: "FutebolCard",
                TITLE: "",
                HREF: "https://www.futebolcard.com",
                TARGET: "_blank",
            },
            {
                TEXT: "Fale Conosco",
                TITLE: "Estaremos felizes em ajudar :)",
                HREF: "https://www.futebolcard.com/institutional/contact-us",
                TARGET: "_blank",
            },
        ],
        KEYS: {
            PROD: "WqJZDn8U78QRSx8nhg3oXsbadodFP9Hi",
            HML: "WqJZDn8U78QRSx8nhg3oXsbadodFP9Hi",
        },
    },
    15: {
        ID: 15,
        SLUG: "braziljs",
        NAME: "BrazilJS",
        BRAND: "BrazilJS",
        LOGO: "https://s3.amazonaws.com/ingresse-static/braziljs/braziljs-logo.png",
        URL: "https://braziljs.org",
        APPS: {
            DEFAULT: {
                FACEBOOK: true,
                DEFAULT: true,
                BRAND: "backstage",
                INVERSE: "#FFFFFF",
                PRIMARY: "#000000",
                SECONDARY: "#2D2D2D",
                GRADIENT: {
                    START: "#000000",
                    END: "#FFEE1F",
                },
            },
        },
        TERMS: "https://braziljs.org/coc",
        FOOTER: [
            {
                TEXT: "BrazilJS",
                TITLE: "",
                HREF: "https://braziljs.org",
                TARGET: "_blank",
            },
            {
                TEXT: "FAQ",
                TITLE: "Estaremos felizes em ajudar :)",
                HREF: "https://braziljs.org/faq",
                TARGET: "_blank",
            },
            {
                TEXT: "Conduta",
                TITLE: "Conheça nosso Código de Conduta",
                HREF: "https://braziljs.org/coc",
                TARGET: "_blank",
            },
        ],
        KEYS: {
            PROD: "8kle46YRKYaw4iduwSni703KAUd65vn8",
            HML: "auUmcjUgSmQkpfIA4bx1el7tkHCeVDfI",
        },
    },
    16: {
        ID: 16,
        SLUG: "mxp",
        NAME: "Minas Experience",
        BRAND: "MXP",
        LOGO: "https://s3.amazonaws.com/ingresse-static/mxp/mxp-logo.png",
        URL: "",
        APPS: {
            DEFAULT: {
                FACEBOOK: false,
                BRAND: "backstage",
                INVERSE: "#FFFFFF",
                PRIMARY: "#025f36",
                SECONDARY: "#017f8d",
                GRADIENT: {
                    START: "#025f36",
                    END: "#017f8d",
                },
            },
        },
        TERMS: "",
        FOOTER: [
            {
                TEXT: "Minas Experience",
                TITLE: "",
                HREF: "",
                TARGET: "_blank",
            },
            {
                TEXT: "Ajuda",
                TITLE: "Estaremos felizes em ajudar :)",
                HREF: "https://ingresse.freshdesk.com/",
                TARGET: "_blank",
            },
            {
                TEXT: "Termos",
                TITLE: "Conheça nossos Termos de Serviços",
                HREF: "https://sobre.ingresse.com/termos-de-servico",
                TARGET: "_blank",
            },
            {
                TEXT: "Privacidade",
                TITLE: "Entenda nossos Termos de Privacidade",
                HREF: "https://sobre.ingresse.com/termos-de-privacidade",
                TARGET: "_blank",
            },
        ],
        KEYS: {
            PROD: "QGXzjbqpFIVkWCmNEVevchLQX4pRAZuO",
            HML: "QGXzjbqpFIVkWCmNEVevchLQX4pRAZuO",
        },
    },
    21: {
        ID: 21,
        SLUG: "bgs",
        NAME: "Brasil Game Show",
        BRAND: "BGS",
        LOGO: "https://ingresse-static.s3.amazonaws.com/bgs/bgs-logo--145x145--black.png",
        URL: "https://www.brasilgameshow.com.br",
        APPS: {
            DEFAULT: {
                FACEBOOK: false,
                BRAND: "backstage",
                INVERSE: "#FFFFFF",
                PRIMARY: "#FF4B00",
                SECONDARY: "#000000",
                GRADIENT: {
                    START: "#F98D0E",
                    END: "#FF4B00",
                },
            },
        },
        TERMS: "",
        FOOTER: [
            {
                TEXT: "Brasil Game Show",
                TITLE: "Visite nosso site",
                HREF: "https://www.brasilgameshow.com.br",
                TARGET: "_blank",
            },
        ],
        KEYS: {
            PROD: "RDMY975GGdpmcmIwiLYOT4DXBYVN3lVu",
            HML: "RDMY975GGdpmcmIwiLYOT4DXBYVN3lVu",
        },
    },
};
